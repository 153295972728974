import { EmailRounded, MenuBookRounded, Tour } from '@mui/icons-material';
import { Box, IconButton, Link, Typography } from '@mui/material';
import { useTour } from '@reactour/tour';
import Plausible from 'plausible-tracker';
import { useMain } from '../../../../context/MainContext';
import FarcasterIcon from '../../../atoms/Logo/FarcasterIcon';

export default function Support(): JSX.Element {
  const { trackEvent } = Plausible({
    domain: 'app.fluidkey.com, rollup.fluidkey',
    apiHost: 'https://sync.fluidkey.com',
  });
  const { setIsOpen, setCurrentStep } = useTour();
  const { setOpenDrawer } = useMain();

  return (
    <Box maxWidth="sm" display="flex" flexDirection="column" alignItems="center" my={1} px="2vh">
      <Box p={2} px={2} width="100%">
        <Typography
          variant="body2"
          color="text.secondary"
          mb={1}
          textAlign="left"
          maxWidth="sm"
          width="100%"
        >
          Support
        </Typography>
        <Box display="flex" justifyContent="center" width="100%" gap={2}>
          <Link
            href="https://docs.fluidkey.com"
            target="_blank"
            textAlign="center"
            sx={{ textDecoration: 'none' }}
          >
            <IconButton
              children={<MenuBookRounded />}
              onClick={() => {
                trackEvent('Docs');
              }}
            />
            <Typography variant="caption" color="text.primary">
              Docs
            </Typography>
          </Link>
          <Box
            textAlign="center"
            onClick={() => {
              setIsOpen(true);
              setCurrentStep(0);
              setOpenDrawer(false);
              trackEvent('Tour');
            }}
            sx={{ cursor: 'pointer' }}
          >
            <IconButton children={<Tour />} />
            <Typography variant="caption">Tour</Typography>
          </Box>
          <Link
            href="https://warpcast.com/~/channel/fluidkey"
            target="_blank"
            textAlign="center"
            sx={{ textDecoration: 'none' }}
          >
            <IconButton
              children={
                <Box height="24px" width="24px">
                  <FarcasterIcon color="text.primary" />
                </Box>
              }
              onClick={() => {
                trackEvent('Farcaster');
              }}
            />
            <Typography variant="caption" color="text.primary">
              Farcaster
            </Typography>
          </Link>
          <Link
            href="mailto:hey@fluidkey.com"
            target="_blank"
            textAlign="center"
            sx={{ textDecoration: 'none' }}
          >
            <IconButton
              children={<EmailRounded />}
              onClick={() => {
                trackEvent('Email');
              }}
            />
            <Typography variant="caption" color="text.primary">
              Email
            </Typography>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

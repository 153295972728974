/**
 * Label input field for sending funds
 */

import {
  Autocomplete,
  Box,
  Chip,
  TextField,
  Typography,
  createFilterOptions,
  lighten,
} from '@mui/material';
import {
  useGetStealthSafeLabelBalance,
  useGetStealthSafeLabelList,
  useGetUserSmartAccounts,
  type Label,
} from '@sefu/react-sdk';
import { ObjType } from '@sefu/react-sdk/lib/core/graphql/codegen/generatedTS/graphql';
import { useEffect, type ReactElement } from 'react';
import { formatUnits } from 'viem';
import { useTransfer } from '../../../../../../context/TransferContext';
import { formatAmount } from '../../../../../../utils/formatAmount';
import { moreColors } from '../../../../../theme';

export const anyLabel: Label = {
  idLabel: 'any',
  objType: ObjType.StealthSafe,
  idSmartAccount: 'any',
  name: 'any label',
  extraInfo: {
    color: moreColors.light2PaperBackground,
  },
  objCount: 0,
};

export default function LabelInput(): JSX.Element {
  const { smartAccountList } = useGetUserSmartAccounts();
  const { selectedLabels, setSelectedLabels, idToken } = useTransfer();
  const { data } = useGetStealthSafeLabelList({
    idSmartAccount: smartAccountList?.[0]?.idSmartAccount as string,
  });

  const { data: filteredData, fetch } = useGetStealthSafeLabelBalance({
    idSmartAccount: smartAccountList?.[0]?.idSmartAccount as string,
    idToken,
  });

  useEffect(() => {
    if (idToken != null && idToken !== '') {
      void fetch();
    }
  }, [idToken]);

  const colors = [
    '#AF3029',
    '#BC5215',
    '#AD8301',
    '#66800B',
    '#24837B',
    '#205EA6',
    '#5E409D',
    '#A02F6F',
  ];

  const finalData =
    data.length > 0
      ? data
          .filter(
            (label: Label) =>
              filteredData?.some(data => data.idStealthSafeLabelWithBalance === label.idLabel)
          )
          .sort((a, b) => {
            if (b.objCount !== a.objCount) {
              return b.objCount - a.objCount;
            }
            // Secondary sorting criterion: whether the color is in the colors array
            const aColorIndex = colors.indexOf(a.extraInfo.color);
            const bColorIndex = colors.indexOf(b.extraInfo.color);
            if (aColorIndex === -1 && bColorIndex !== -1) {
              return 1;
            }
            if (aColorIndex !== -1 && bColorIndex === -1) {
              return -1;
            }
            return 0;
          })
      : [];

  const labels: Label[] = [anyLabel, ...finalData];

  const labelChip = (
    option: Label,
    props?: {
      key: number;
      className: string;
      disabled: boolean;
      'data-tag-index': number;
      tabIndex: -1;
      onDelete: (event: any) => void;
    }
  ): ReactElement => {
    const additionalData = filteredData?.find(
      data => data.idStealthSafeLabelWithBalance === option.idLabel
    );
    const balance = additionalData?.balance[0].amount;
    const balanceOnHold = additionalData?.balance[0].amountOnHold;
    const balanceAvailable =
      balance != null && balanceOnHold != null
        ? BigInt(balance) - BigInt(balanceOnHold)
        : BigInt(0);
    const decimals = additionalData?.balance[0].token?.decimals;
    const token = additionalData?.balance[0].token?.symbol;
    const balanceFormatted =
      balance != null && decimals != null
        ? formatAmount({
            amountTotalFormatted: formatUnits(balanceAvailable, Number(decimals)),
            roundUp: false,
            decimals: 2,
          })
        : null;
    return (
      <Chip
        {...props}
        size="small"
        label={
          <Box display="flex" alignItems="center" maxWidth="100%">
            <Typography
              variant="body2"
              color="textPrimary"
              lineHeight={1.5}
              mr={1}
              sx={{
                flexShrink: 1,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {option.name}
            </Typography>
            <Typography
              variant="caption"
              lineHeight={1.5}
              color={lighten(option.extraInfo.color, 0.7)}
              sx={{
                flexShrink: 100000,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {balanceFormatted} {token}
            </Typography>
          </Box>
        }
        disabled={option.name === 'any label'}
        sx={{
          backgroundColor: option.extraInfo.color,
          borderRadius: '6px',
          '&:focus': {
            backgroundColor: option.extraInfo.color,
            fontWeight: 'bold',
          },
          maxWidth: '100%',
        }}
      />
    );
  };

  const fixedOption = [anyLabel];
  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: Label) => option.name,
  });

  return data.length < 1 ? (
    <></>
  ) : (
    <Autocomplete
      multiple
      options={labels}
      noOptionsText="No labels"
      value={selectedLabels.length === 0 ? fixedOption : selectedLabels}
      onChange={(event, newValue) => {
        if (newValue.length === 0) {
          setSelectedLabels([]);
        } else if (newValue.some(option => option.name === fixedOption[0].name)) {
          if (newValue.length === 1) {
            setSelectedLabels([]);
          } else {
            setSelectedLabels(newValue.filter(option => option.name !== fixedOption[0].name));
          }
        } else {
          setSelectedLabels(newValue);
        }
      }}
      getOptionLabel={(option: Label) => option.name}
      isOptionEqualToValue={(option: Label, value: Label) => option.name === value.name}
      filterOptions={(options: Label[], params) => {
        const filtered = filterOptions(options, params);
        return filtered.filter(
          (option: Label) =>
            option.name !== 'any label' &&
            !selectedLabels.some((selected: Label) => selected.name === option.name)
        );
      }}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => labelChip(option, getTagProps({ index })))
      }
      renderOption={(props, option, { selected }) => (
        <li {...props} style={{ paddingLeft: '8px', paddingRight: '8px', minHeight: '0px' }}>
          {labelChip(option)}
        </li>
      )}
      renderInput={params => (
        <TextField
          {...params}
          label="using funds from"
          variant="outlined"
          type="text"
          margin="dense"
          size="small"
          color="primary"
          sx={{
            width: '100%',
            marginBottom: 0,
            maxWidth: '100%',
          }}
        />
      )}
    />
  );
}

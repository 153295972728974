import { Box, MenuItem, Select, Typography } from '@mui/material';
import Chain from '../../../../../atoms/Chains/Chain';

interface ChainInputProps {
  value: number;
  onChange: (value: number) => void;
  chains: number[];
}

export default function ChainInput(props: ChainInputProps): JSX.Element {
  const chains = props.chains;
  // sort chains so 11155111 is last
  chains.sort((a, b) => {
    if (a === 11155111) {
      return 1;
    }
    if (b === 11155111) {
      return -1;
    }
    return 0;
  });
  return (
    <Box display="flex" alignItems="center">
      <Select
        value={props.value.toString()}
        variant="outlined"
        onChange={event => {
          props.onChange(parseInt(event.target.value));
        }}
        size="small"
        IconComponent={() => null}
        sx={{
          height: 'fit-content',
          '&.css-k0afcw-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
            paddingRight: '-32px !important',
          },
          '& .MuiSelect-select': {
            paddingX: '10px !important',
          },
        }}
      >
        {chains.map((chainId, index) => (
          <MenuItem key={chainId} value={chainId}>
            <Typography variant="body2" component="span">
              <Chain chainId={chainId} />
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}

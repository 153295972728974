import { Box, Tooltip } from '@mui/material';
import { useEnsName } from 'wagmi';

interface CompressedAddressProps {
  address: string | `${string}.eth`;
  marginBottom?: number;
  characters?: number;
  tooltipContent?: string;
}

export default function CompressedAddress({
  address,
  marginBottom,
  characters = 8,
  tooltipContent,
}: CompressedAddressProps): JSX.Element {
  let formattedAddress = address;

  const { data } = useEnsName({
    address: address as `0x${string}`,
    chainId: 1,
  });

  if (data != null) {
    formattedAddress = data as `${string}.eth`;
  }

  return (
    <Tooltip title={tooltipContent ?? address} placement="top">
      <Box>
        {formattedAddress != null && formattedAddress.length > 16 ? (
          characters < 42 ? (
            !address.startsWith('0x') ? (
              <Box component="span" marginBottom={marginBottom}>
                {formattedAddress.length < characters + 2
                  ? formattedAddress
                  : formattedAddress.slice(0, characters + 2) + '...'}
              </Box>
            ) : (
              <Box component="span" marginBottom={marginBottom}>
                {formattedAddress.slice(0, characters / 2 + 2)}...
                {formattedAddress.slice(-characters / 2)}
              </Box>
            )
          ) : (
            <Box component="span" marginBottom={marginBottom}>
              {formattedAddress}
            </Box>
          )
        ) : (
          <Box component="span" marginBottom={marginBottom}>
            {formattedAddress}
          </Box>
        )}
      </Box>
    </Tooltip>
  );
}

export function CompressedAddressWithoutEns({
  address,
  marginBottom,
  characters = 8,
}: CompressedAddressProps): JSX.Element {
  return characters < 42 && address != null ? (
    <Box component="span" marginBottom={marginBottom}>
      {address.slice(0, characters / 2 + 2)}...
      {address.slice(-characters / 2)}
    </Box>
  ) : (
    <Box component="span" marginBottom={marginBottom}>
      {address}
    </Box>
  );
}

import { Alert, Box, Button, Modal, TextField, Typography } from '@mui/material';
import { ArrowRightAltRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { gradient } from '../../../../theme';
import ChainInput from '../Send/Input/ChainInput';
import { useAddCustomWhitelistToken, useGetUserSmartAccounts } from '@sefu/react-sdk';
import { useMain } from '../../../../../context/MainContext';
import React, { useEffect, useState } from 'react';

export default function AddTokenModal(): JSX.Element {
  const { smartAccountList = [] } = useGetUserSmartAccounts();
  const { data, addCustomToken, error, isLoading } = useAddCustomWhitelistToken();
  const { chainId, setChainId, chainIds, openAddTokenModal, setOpenAddTokenModal } = useMain();
  const [chain, setChain] = useState(chainId);
  const [contractAddress, setContractAddress] = useState('');

  useEffect(() => {
    if (data != null) {
      setChainId(chain);
      setOpenAddTokenModal(false);
      setContractAddress('');
    }
  }, [data]);

  return (
    <Modal open={openAddTokenModal}>
      <Box
        sx={{
          position: 'fixed',
          top: '40%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          minWidth: '300px',
          width: '100%',
          maxWidth: 'sm',
          '&:focus-visible': {
            outline: 'none !important',
          },
        }}
        alignItems="center"
        display="flex"
        justifyContent="center"
      >
        <Box
          minWidth="300px"
          width="100%"
          maxWidth="90vw"
          bgcolor="background.paper"
          sx={{
            borderRadius: '10px',
            paddingY: '32px',
            paddingX: '32px',
          }}
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          <Typography variant="h5" fontWeight="light" color="text.secondary" mb={2}>
            Import a token
          </Typography>
          <TextField
            label="ERC-20 token contract address"
            placeholder="address"
            variant="outlined"
            type="text"
            margin="dense"
            size="small"
            value={contractAddress}
            color="primary"
            autoComplete="off"
            autoCorrect="off"
            inputProps={{ autoCapitalize: 'none' }}
            onChange={e => {
              setContractAddress(e.target.value);
            }}
            sx={{
              marginBottom: 1,
              minWidth: '300px',
              width: '100%',
            }}
          />
          <Box display="flex" alignItems="center" mb={2}>
            <Typography variant="body2" color="text.secondary" ml={1} mr={1}>
              on
            </Typography>
            <ChainInput
              value={chain}
              onChange={value => {
                setChain(value);
              }}
              chains={chainIds}
            />
          </Box>
          <LoadingButton
            size="medium"
            variant="contained"
            endIcon={<ArrowRightAltRounded />}
            loading={isLoading}
            loadingPosition="end"
            sx={{
              backgroundImage: gradient,
              textTransform: 'none',
              fontWeight: 700,
              '&.MuiLoadingButton-loading': {
                color: 'background.default',
                opacity: 0.8,
              },
              '&.Mui-disabled': {
                color: 'background.default',
                opacity: 0.8,
              },
            }}
            onClick={() => {
              void addCustomToken({
                idSmartAccount: smartAccountList[0]?.idSmartAccount,
                address: contractAddress as `0x${string}`,
                chainId: chain,
              });
            }}
          >
            Import
          </LoadingButton>
          <Button
            size="small"
            sx={{ color: 'text.secondary', opacity: 0.7, marginTop: 2 }}
            onClick={() => {
              setOpenAddTokenModal(false);
            }}
          >
            Back
          </Button>
          {error != null ? (
            <Alert severity="error" sx={{ marginTop: 2 }}>
              {error}
            </Alert>
          ) : null}
        </Box>
      </Box>
    </Modal>
  );
}

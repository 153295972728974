/**
 * Auth Parent Component
 *
 * This component handles the entire authentication UI, from connecting the wallet to signing in or up.
 */

import { Alert, Box, CircularProgress, Typography } from '@mui/material';
import { useIsAddressRegistered } from '@sefu/react-sdk';
import { useAccount } from 'wagmi';
import { useAuth } from '../../../context/AuthContext';
import FocusContainer from '../../atoms/Containers/FocusContainer';
import SendFooter from '../../atoms/Footer/SendFooter';
import AccessCode from '../../molecules/Auth/AccessCode';
import Connect from '../../molecules/Auth/Connect';
import GenerateKeys from '../../molecules/Auth/GenerateKeys';
import Pin from '../../molecules/Auth/Pin';
import Register from '../../molecules/Auth/Register';

export default function AuthParent(): JSX.Element {
  const { address } = useAccount();
  const { stage, error } = useAuth();
  const { isAddressRegistered } = useIsAddressRegistered(address);

  // Mapping auth stages to their corresponding components
  const stageComponentMap = {
    connect: <Connect />,
    accessCode: <AccessCode />,
    pin: <Pin />,
    generateKeys: <GenerateKeys />,
    register: <Register />,
    loading: (
      <Box mt={2} mb={1} width="70vw" maxWidth="sm" display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    ),
  };

  return (
    <>
      {error != null ? (
        <Alert
          severity="error"
          sx={{
            position: 'absolute',
            width: '100vw',
            borderRadius: '0px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {error ?? null}
        </Alert>
      ) : null}
      <FocusContainer>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{
            '@media (min-width: 600px)': {
              width: '400px',
            },
          }}
          pb={3}
          pt={2}
          px={1}
        >
          <Typography variant="h5" fontWeight="light" color="text.secondary" mb={2}>
            {!isAddressRegistered && address != null && stage !== 'loading' ? 'Sign up' : 'Sign in'}
          </Typography>
          {stageComponentMap[stage]}
        </Box>
      </FocusContainer>
      <SendFooter />
    </>
  );
}

import { Alert, AlertTitle, Typography } from '@mui/material';

export default function ReceiveAlert({
  displayType,
  address,
}: {
  displayType: string;
  address: string;
}): JSX.Element {
  return (
    <Alert severity="success" sx={{ maxWidth: 'sm', width: '100%' }}>
      <AlertTitle color="text.primary">Address copied</AlertTitle>
      <Typography variant="body2" color="text.primary" mb={1}>
        Use it to receive ETH, USDC, or any other ERC-20 token.
      </Typography>
      {displayType === 'hex' ? (
        <>
          <Typography variant="body2" color="text.primary" mb={1}>
            We recommend using each hex address only once to protect your privacy.
          </Typography>
          <Typography variant="body2" fontWeight={400} color="text.secondary">
            Copied address: {address}
          </Typography>
        </>
      ) : displayType === 'link' ? (
        <Typography variant="body2" color="text.primary">
          It can be used as a browser link or to send payments from compatible wallets.
        </Typography>
      ) : (
        <Typography variant="body2" color="text.primary">
          It can be used to send payments from compatible wallets.
        </Typography>
      )}
    </Alert>
  );
}

/**
 * User input to send funds
 */

import { ArrowDropDown, ArrowDropUp, SwapHorizRounded } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useGetSmartAccount, useGetUserSmartAccounts } from '@sefu/react-sdk';
import { useMain } from '../../../../../../context/MainContext';
import { useTransfer } from '../../../../../../context/TransferContext';
import { chains } from '../../../../../../utils/chains';
import AddressInput from './AddressInput';
import AmountInput from './AmountInput';
import ChainInput from './ChainInput';
import LabelInput from './LabelInput';
import TokenInput from './TokenInput';
import SendButton from './SendButton';
import React from 'react';

function SendInput(): JSX.Element {
  const { toChain, setToChain, sendToInput, setSendToInput, openSwapBridge, setOpenSwapBridge } =
    useTransfer();
  const isDev = process.env.REACT_APP_DEV_ENV === 'true';
  const { chainId, chainIds } = useMain();
  const { smartAccountList = [] } = useGetUserSmartAccounts();
  const { smartAccount } = useGetSmartAccount({
    idSmartAccount: smartAccountList[0]?.idSmartAccount,
  });

  // Get chainIds for non-testnet and order so that mainnet is and the current chain is first
  const chainIdsFiltered = chainIds.filter(id => chains[id].type !== 'TESTNET');

  return (
    <Box maxWidth="100%">
      <Box mt={3} mb={0.5} mx={-0.25}>
        <AmountInput />
      </Box>
      <Box mb={0.5} mx={-0.25}>
        <AddressInput />
      </Box>
      <Box mb={0.5} mx={-0.25}>
        <LabelInput />
      </Box>
      {chainId !== 11155111 ? (
        <Box
          display="flex"
          alignItems="center"
          ml={0.25}
          mt={1.5}
          mb={1}
          width="fit-content"
          onClick={(): void => {
            setOpenSwapBridge(!openSwapBridge);
            if (sendToInput === '' && !openSwapBridge) {
              setSendToInput(`${smartAccount?.username}.fkey${isDev ? 'dev' : ''}.eth`);
            }
          }}
          sx={{
            cursor: 'pointer',
            userSelect: 'none',
            WebkitTapHighlightColor: 'transparent',
          }}
        >
          <SwapHorizRounded fontSize="small" sx={{ color: 'text.secondary' }} />
          <Typography variant="body2" color="text.secondary" ml={0.5}>
            Swap & Bridge
          </Typography>
          {openSwapBridge ? (
            <ArrowDropUp fontSize="small" sx={{ color: 'text.secondary' }} />
          ) : (
            <ArrowDropDown fontSize="small" sx={{ color: 'text.secondary' }} />
          )}
        </Box>
      ) : null}
      {openSwapBridge ? (
        <Box display="flex" mt={0.75} alignItems="center">
          <Typography variant="body2" color="text.secondary" ml={0.5} mr={1}>
            to
          </Typography>
          <TokenInput />
          <Typography variant="body2" color="text.secondary" ml={1} mr={1}>
            on
          </Typography>
          <ChainInput
            value={toChain}
            chains={chainIdsFiltered}
            onChange={value => {
              setToChain(value);
            }}
          />
        </Box>
      ) : null}
      <SendButton />
    </Box>
  );
}

export default React.memo(SendInput);

import {
  useAuthenticate,
  useGetSmartAccount,
  useGetUser,
  useGetUserAddress,
  useGetUserSmartAccounts,
  useResetClient,
  useSetUsername,
} from '@sefu/react-sdk';
import Plausible from 'plausible-tracker';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAccount } from 'wagmi';
import { usePrivy, useWallets } from '@privy-io/react-auth';

interface MainContextProps {
  chainId: number;
  setChainId: React.Dispatch<React.SetStateAction<number>>;
  chainIds: number[];
  setChainIds: React.Dispatch<React.SetStateAction<number[]>>;
  deferredPrompt: any;
  isInstalled: boolean;
  setIsInstalled: React.Dispatch<React.SetStateAction<boolean>>;
  handleInstall: () => void;
  openDrawer: boolean;
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  isEditingUsername: boolean;
  setIsEditingUsername: React.Dispatch<React.SetStateAction<boolean>>;
  isFirstTimeUser: boolean;
  setIsFirstTimeUser: React.Dispatch<React.SetStateAction<boolean>>;
  lockOutgoingTransfers: boolean;
  setLockOutgoingTransfers: React.Dispatch<React.SetStateAction<boolean>>;
  refreshBalance: boolean;
  setRefreshBalance: React.Dispatch<React.SetStateAction<boolean>>;
  searchParams?: URLSearchParams;
  openAddTokenModal: boolean;
  setOpenAddTokenModal: React.Dispatch<React.SetStateAction<boolean>>;
  privyUsername: string | undefined;
}

const MainContext = createContext<MainContextProps | undefined>(undefined);

export const useMain = (): MainContextProps => {
  const context = useContext(MainContext);
  if (context == null) {
    throw new Error('useMain must be used within a MainProvider');
  }
  return context;
};

interface MainProviderProps {
  children?: React.ReactNode;
}

export const MainProvider: React.FC<MainProviderProps> = ({ children }) => {
  const { address, status, isConnected } = useAccount();
  const { reset } = useResetClient();
  const { setAuthToken } = useAuthenticate();
  const { wallets } = useWallets();
  const { user: privyUser } = usePrivy();
  const { user } = useGetUser({});
  const navigate = useNavigate();
  const { trackEvent } = Plausible({
    domain: 'app.fluidkey.com, rollup.fluidkey',
    apiHost: 'https://sync.fluidkey.com',
  });
  const { address: initializedUser, isLoading } = useGetUserAddress();
  const { setUsername } = useSetUsername();
  const { smartAccountList = [] } = useGetUserSmartAccounts();
  const { smartAccount } = useGetSmartAccount({
    idSmartAccount: smartAccountList[0]?.idSmartAccount,
  });
  const chains = process.env.REACT_APP_CHAIN_IDS?.split(',').map(Number) as number[];

  // Main context states
  const [chainId, setChainId] = useState<number>(
    parseInt(process.env.REACT_APP_DEFAULT_CHAIN_ID as string)
  );

  const [chainIds, setChainIds] = useState<number[]>(chains);
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const [isInstalled, setIsInstalled] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [isEditingUsername, setIsEditingUsername] = useState<boolean>(false);
  const [isFirstTimeUser, setIsFirstTimeUser] = useState<boolean>(false);
  const [lockOutgoingTransfers, setLockOutgoingTransfers] = useState<boolean>(false);
  const [refreshBalance, setRefreshBalance] = useState<boolean>(false);
  const [openAddTokenModal, setOpenAddTokenModal] = useState<boolean>(false);
  const [privyUsername, setPrivyUsername] = useState<string | undefined>();

  // Get search params
  const [searchParams, setSearchParams] = useState<URLSearchParams>();
  const location = useLocation();
  useEffect(() => {
    setSearchParams(new URLSearchParams(location.search));
  }, []);

  // Check if the user is a first time user
  useEffect(() => {
    if (
      user?._importStatus != null &&
      (user._importStatus === 'FIRST_IMPORT_IN_PROGRESS' ||
        user._importStatus === 'FRONTEND_CONNECTED')
    ) {
      setIsFirstTimeUser(true);
    }
  }, [user]);

  // if the user is a first time user and has a username in the search param, set the username
  useEffect(() => {
    const username = searchParams?.get('username');
    if (isFirstTimeUser && username != null && smartAccount?.idSmartAccount != null) {
      void setUsername(smartAccount?.idSmartAccount, username);
    }
  }, [isFirstTimeUser, smartAccount?.idSmartAccount]);

  useEffect(() => {
    if (address !== initializedUser && !isLoading) {
      reset();
    }
    const authTokenJSON = localStorage.getItem(`fluidkey.authToken.${address}`);
    const authToken = JSON.parse(authTokenJSON as string);
    if (authToken != null && isConnected && status !== 'reconnecting') {
      try {
        setAuthToken(authToken);
        if (location.pathname !== '/') {
          navigate('/');
        }
      } catch (e) {
        console.error(e);
        reset();
        if (location.pathname !== '/auth') {
          navigate('/auth');
        }
      }
    } else {
      const timeoutId = setTimeout(() => {
        // reset();
        if (location.pathname !== '/auth') {
          navigate('/auth');
        }
      }, 4000);
      // If the status changes within 3 seconds, cancel the timeout
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [status, address]);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e: any) => {
      e.preventDefault();
      setDeferredPrompt(e);
    });
    window.addEventListener('appinstalled', () => {
      setDeferredPrompt(null);
      setIsInstalled(true);
    });
    if (window.matchMedia('(display-mode: standalone)').matches) {
      setIsInstalled(true);
    }
  }, []);

  const handleInstall = (): void => {
    if (deferredPrompt != null) {
      deferredPrompt.prompt();
      trackEvent('App Install');
      deferredPrompt.userChoice.then(() => {
        setDeferredPrompt(null);
      });
    }
  };

  useEffect(() => {
    if (!openDrawer) {
      setIsEditingUsername(false);
    }
  }, [openDrawer]);

  // Cache the chainId in local storage
  useEffect(() => {
    setChainId(
      localStorage.getItem(`fluidkey.chainId.${address}`) != null
        ? parseInt(localStorage.getItem(`fluidkey.chainId.${address}`) as string)
        : parseInt(process.env.REACT_APP_DEFAULT_CHAIN_ID as string)
    );
  }, [address]);

  useEffect(() => {
    if (chainId != null && address != null) {
      localStorage.setItem(`fluidkey.chainId.${address}`, chainId.toString());
    }
  }, [chainId]);

  // Ignore Plausible in dev env
  useEffect(() => {
    if (process.env.REACT_APP_DEV_ENV === 'true') {
      localStorage.setItem('plausible_ignore', 'true');
    } else {
      localStorage.removeItem('plausible.ignore');
    }
  }, []);

  // Set embedded wallet to true if the user is using the embedded wallet
  useEffect(() => {
    if (wallets[0] != null && wallets[0].walletClientType === 'privy') {
      setPrivyUsername(
        privyUser?.farcaster?.username ?? privyUser?.google?.email ?? privyUser?.email?.address
      );
    } else {
      setPrivyUsername(undefined);
    }
  }, [wallets]);

  return (
    <MainContext.Provider
      value={{
        chainId,
        setChainId,
        chainIds,
        setChainIds,
        deferredPrompt,
        isInstalled,
        setIsInstalled,
        handleInstall,
        openDrawer,
        setOpenDrawer,
        isEditingUsername,
        setIsEditingUsername,
        isFirstTimeUser,
        setIsFirstTimeUser,
        lockOutgoingTransfers,
        setLockOutgoingTransfers,
        refreshBalance,
        setRefreshBalance,
        searchParams,
        openAddTokenModal,
        setOpenAddTokenModal,
        privyUsername,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

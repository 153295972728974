import { LaunchRounded, NorthEastRounded } from '@mui/icons-material';
import { Link } from '@mui/material';
import { TourProvider } from '@reactour/tour';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { gradient } from './ui/theme';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const tourSteps = [
  {
    selector: '.receive',
    content: () => (
      <div>
        In the{' '}
        <b>
          <i>Receive</i>
        </b>{' '}
        section you will find privacy-preserving options to receive payments. Transfers sent to any
        of these addresses across all supported chains will land in your Fluidkey account.
        <br />
        <br />
        Let's have a look at each option!
      </div>
    ),
  },
  {
    selector: '.receive',
    content: () => (
      <div>
        The first option is a shareable web{' '}
        <b>
          <i>Link</i>
        </b>{' '}
        that allows anyone to send you privacy-preserving payments.
        <br />
        <br />
        Check it out by pressing <LaunchRounded fontSize="small" sx={{ paddingTop: 0.75 }} />
        <br />
        <br />
        Once you’re done, continue by pressing the{' '}
        <b>
          <i>ENS</i>
        </b>{' '}
        button.
      </div>
    ),
  },
  {
    selector: '.receive',
    content: () => (
      <div>
        The{' '}
        <b>
          <i>ENS</i>
        </b>{' '}
        can be used directly inside popular wallets. Every time it is typed in a wallet, it will
        return a new Fluidkey address you control.
        <br />
        <br />
        Let’s continue by pressing the{' '}
        <b>
          <i>Address</i>
        </b>{' '}
        button.
      </div>
    ),
  },
  {
    selector: '.receive',
    content: () => (
      <div>
        This final option allows you to generate new hex addresses directly from this dashboard.
        <br />
        <br />
        To ensure maximum privacy, we recommend using a specific hex address only for one transfer.
      </div>
    ),
  },
  {
    selector: '.funds',
    content: () => <div>You can manage the transfers received with Fluidkey in this section.</div>,
  },
  {
    selector: '.funds',
    content: () => (
      <div>
        Press <NorthEastRounded fontSize="small" sx={{ paddingTop: 0.75 }} /> to send out payments
        from your Fluidkey addresses. We will automatically compute the most privacy-preserving
        payment route for each transaction.
      </div>
    ),
  },
  {
    selector: '.settings',
    content: () => (
      <div>Finally, you can press this area to access settings and find our docs.</div>
    ),
  },
  {
    selector: '.settings',
    content: () => (
      <div>
        Thank you for using Fluidkey - if you have any questions, check out our{' '}
        <Link href="https://docs.fluidkey.com" target="_blank">
          docs
        </Link>{' '}
        or reach out in the{' '}
        <Link href="https://warpcast.com/~/channel/fluidkey" target="_blank">
          /fluidkey
        </Link>{' '}
        channel on Farcaster!
      </div>
    ),
  },
];
root.render(
  <TourProvider
    steps={tourSteps}
    badgeContent={({ totalSteps, currentStep }) => currentStep + 1 + '/' + totalSteps}
    nextButton={({ Button, currentStep, stepsLength, setIsOpen, setCurrentStep, steps }) => {
      const last = currentStep === stepsLength - 1;
      return (
        <Button
          onClick={() => {
            if (last) {
              setIsOpen(false);
            } else if (steps != null) {
              setCurrentStep(s => (s === steps?.length - 1 ? 0 : s + 1));
            }
          }}
        />
      );
    }}
    styles={{
      popover: (base: any) => ({
        ...base,
        boxShadow: '0 0 3em rgba(0, 0, 0, 0.5)',
        background: gradient,
        color: '#292C34',
        borderRadius: '10px',
        paddingTop: '40px',
        paddingLeft: '40px',
        paddingRight: '40px',
        marginLeft: '10px',
        marginRight: '20px',
        minWidth: '300px',
        fontWeight: 500,
      }),
      badge: (base: any) => ({
        ...base,
        background: '#CDEAF7',
        color: '#292C34',
      }),
      dot: (base: any) => ({
        ...base,
        background: '#737B91',
        border: 'none',
      }),
      arrow: (base: any) => ({
        ...base,
        color: '#737B91',
      }),
      close: (base: any) => ({
        ...base,
        color: '#737B91',
        marginBottom: '20px',
      }),
      maskArea: (base: any) => ({
        ...base,
        rx: 10,
      }),
      highlightedArea: (base: any, { x, y, width, height }: any) => ({
        ...base,
        display: 'block',
        stroke: '#CCFFCC',
        strokeWidth: 6,
        width: width + 14,
        height: height + 14,
        rx: 14,
        x: x - 7,
        y: y - 7,
        pointerEvents: 'none',
      }),
    }}
  >
    <App />{' '}
  </TourProvider>
);

serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

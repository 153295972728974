import { Box, Button, CircularProgress, Typography } from '@mui/material';
import {
  type Token,
  useConfirmBridge,
  useConfirmSwap,
  useConfirmWithdrawal,
  useFluidkeyClient,
  useGetSmartAccountTransfers,
  useGetUserSmartAccounts,
  type FluidkeyClient,
  type Label,
  type SmartAccountTransfer,
} from '@sefu/react-sdk';
import Plausible from 'plausible-tracker';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAccount, useDisconnect } from 'wagmi';
import { useMain } from '../../../context/MainContext';
import ActivityAccordion from '../../molecules/Dashboard/Activity/ActivityAccordion';

interface AdditionalTransferInfo {
  selfSend: boolean;
  outAmount?: string;
  outToken?: Token;
  outDecimals?: number;
  fees?: bigint;
  procedureType?: string;
  serviceTime?: number;
  outLabels?: Label[];
  outFeeToken?: string;
  outFeeTokenDecimals?: number;
}

export interface Transfer {
  smartAccountTransfer: SmartAccountTransfer;
  additionalInfo: AdditionalTransferInfo;
}

export default function Activity(): JSX.Element {
  const { smartAccountList = [] } = useGetUserSmartAccounts();
  const { chainId, setLockOutgoingTransfers, refreshBalance } = useMain();
  const { data, loadMore, moreDataToLoad, isLoading, refetch } = useGetSmartAccountTransfers({
    idSmartAccount: smartAccountList[0]?.idSmartAccount,
    chainId,
    polling: true,
  });
  const { trackEvent } = Plausible({
    domain: 'app.fluidkey.com, rollup.fluidkey',
    apiHost: 'https://sync.fluidkey.com',
  });
  const { areMetaStealthKeysInitialized } = useFluidkeyClient() as FluidkeyClient;
  const { confirmWithdrawal } = useConfirmWithdrawal();
  const { confirmSwap } = useConfirmSwap();
  const { confirmBridge } = useConfirmBridge();
  const { disconnect } = useDisconnect();
  const { address } = useAccount();
  const [retry, setRetry] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    void refetch();
  }, [refreshBalance]);

  // Replay transfers that are awaiting signatures
  useEffect(() => {
    if (data?.length !== 0) {
      const lastAwaitingSignaturesTransfer = [...data]
        .reverse()
        .find(
          transfer => transfer.status === 'AWAITING_SIGNATURES' || transfer.status === 'PENDING'
        );
      if (lastAwaitingSignaturesTransfer != null) {
        setLockOutgoingTransfers(true);
        if (lastAwaitingSignaturesTransfer.status === 'PENDING') {
          return;
        }
        const metaStealthKeysInitialized = areMetaStealthKeysInitialized();
        if (metaStealthKeysInitialized && !retry) {
          setTimeout(() => {
            setRetry(true);
          }, 10000);
        } else if (metaStealthKeysInitialized && retry) {
          if (lastAwaitingSignaturesTransfer?.procedureType === 'WITHDRAWAL') {
            void confirmWithdrawal({
              idProcedure: lastAwaitingSignaturesTransfer?.procedure?.withdrawal
                ?.idProcedure as string,
            });
          } else if (lastAwaitingSignaturesTransfer?.procedureType === 'SWAP') {
            void confirmSwap({
              idProcedure: lastAwaitingSignaturesTransfer?.procedure?.swap?.idProcedure as string,
            });
          } else if (lastAwaitingSignaturesTransfer?.procedureType === 'BRIDGE') {
            void confirmBridge({
              idProcedure: lastAwaitingSignaturesTransfer?.procedure?.bridge?.idProcedure as string,
            });
          }
        } else {
          disconnect();
          localStorage.removeItem(`fluidkey.authToken.${address}`);
          navigate('/auth');
        }
      } else {
        setLockOutgoingTransfers(false);
        setRetry(false);
      }
    }
  }, [data, retry]);

  const groupedTransfers = data.reduce((groups: Record<string, Transfer[]>, transfer) => {
    const date = new Date(transfer.createdAt * 1000);
    const dateString = date.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });

    if (groups[dateString] === undefined) {
      groups[dateString] = [];
    }

    const existingTransfer = groups[dateString].find(
      existingTransfer =>
        existingTransfer.smartAccountTransfer.txHash !== null &&
        existingTransfer.smartAccountTransfer.txHash === transfer.txHash &&
        existingTransfer.smartAccountTransfer.procedureType !== 'BRIDGE' &&
        transfer.procedureType !== 'BRIDGE'
    );

    const procedure =
      transfer?.procedure?.withdrawal ?? transfer?.procedure?.swap ?? transfer?.procedure?.bridge;

    const fees =
      procedure?.relayFee != null && procedure?.serviceFee != null
        ? BigInt(procedure?.relayFee) + BigInt(procedure?.serviceFee)
        : undefined;

    const feeToken = procedure?.tokenForFee?.symbol;
    const feeTokenDecimals = procedure?.tokenForFee?.decimals;

    const serviceTime =
      transfer?.procedureType === 'BRIDGE' && procedure != null && 'serviceTime' in procedure
        ? procedure?.serviceTime
        : undefined;

    if (existingTransfer != null) {
      // Create a new object with all properties of existingTransfer plus the new selfSend property
      const outProcedure = transfer?.procedure?.swap ?? transfer?.procedure?.bridge;
      const updatedTransfer = {
        smartAccountTransfer: {
          ...existingTransfer.smartAccountTransfer,
          procedureType:
            transfer.procedureType ?? existingTransfer.smartAccountTransfer.procedureType,
        },
        additionalInfo: {
          selfSend: true,
          fees,
          outAmount: outProcedure?.amount,
          outToken: outProcedure?.tokenFrom,
          outDecimals: outProcedure?.tokenFrom?.decimals,
          outFeeToken: feeToken,
          outFeeTokenDecimals: feeTokenDecimals,
          outLabels: transfer.labels,
          serviceTime,
        },
      };

      // Find the index of the existingTransfer in the array
      const index = groups[dateString].indexOf(existingTransfer);

      // Replace the existingTransfer with the updatedTransfer in the array
      groups[dateString][index] = updatedTransfer;
    } else {
      const tokenAddress =
        transfer.procedure?.bridge?.tokenFrom?.address ??
        transfer.procedure?.swap?.tokenFrom?.address ??
        transfer.procedure?.withdrawal?.token.address ??
        transfer.stealthSafeTransfer?.token.address;
      // Filter tokens that are not in the tokens array
      if (tokenAddress != null) {
        groups[dateString].push({
          smartAccountTransfer: transfer,
          additionalInfo: { selfSend: false, fees, serviceTime },
        });
      }
    }

    return groups;
  }, {});

  const memoizedGroupedTransfers = React.useMemo(() => groupedTransfers, [data]);

  let firstNonEmptyCategoryIndex = -1;

  return (
    <>
      {data?.length !== 0 ? (
        <>
          <Typography
            variant="body2"
            color="text.secondary"
            mb={0.75}
            textAlign="left"
            maxWidth="sm"
            px="2vh"
            mt={4}
            display="flex"
            component="div"
          >
            Activity{' '}
            {isLoading ? (
              <Box ml={1}>
                <CircularProgress color="inherit" size={10} />
              </Box>
            ) : (
              ''
            )}
          </Typography>
          {Object.keys(memoizedGroupedTransfers).map((category, index) => {
            if (memoizedGroupedTransfers[category].length > 0) {
              firstNonEmptyCategoryIndex = index;
              return (
                <React.Fragment key={category}>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    fontSize="0.7rem !important"
                    pb={0.5}
                    pt={3}
                    textAlign="left"
                    maxWidth="sm"
                    sx={{
                      backgroundColor: 'background.paper',
                      paddingTop: firstNonEmptyCategoryIndex === 0 ? 2.5 : 3,
                      borderTopLeftRadius: firstNonEmptyCategoryIndex === 0 ? '10px' : '0px',
                      borderTopRightRadius: firstNonEmptyCategoryIndex === 0 ? '10px' : '0px',
                      paddingX: 2.1,
                    }}
                  >
                    {category}
                  </Typography>
                  {memoizedGroupedTransfers[category].map(transfer => (
                    <ActivityAccordion
                      key={transfer.smartAccountTransfer.idSmartAccountTransfer}
                      data={transfer}
                    />
                  ))}
                </React.Fragment>
              );
            } else {
              return null;
            }
          })}
          {moreDataToLoad ? (
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              sx={{
                paddingY: '3px',
                paddingX: '12px',
                marginY: 2,
                marginX: '12px',
                textTransform: 'none',
                opacity: 0.4,
              }}
              onClick={() => {
                void loadMore();
                trackEvent('Load More Activity');
              }}
            >
              Load More
            </Button>
          ) : null}
        </>
      ) : null}
    </>
  );
}

/**
 * Auth Access Code Input
 *
 * Input of the access code for new users.
 */

import { Button, Link, Typography } from '@mui/material';
import Plausible from 'plausible-tracker';
import { useEffect } from 'react';
import { useDisconnect } from 'wagmi';
import { useAuth } from '../../../context/AuthContext';
import PinInput from '../../atoms/Input/PinInput';
import { usePrivy } from '@privy-io/react-auth';

export default function AccessCode(): JSX.Element {
  const { disconnect } = useDisconnect();
  const { setStage, accessCode, setAccessCode, setConnectActionCompleted } = useAuth();
  const { logout } = usePrivy();
  const { trackEvent } = Plausible({
    domain: 'app.fluidkey.com, rollup.fluidkey',
    apiHost: 'https://sync.fluidkey.com',
  });

  // Move to PIN stage when access code is entered
  useEffect(() => {
    if (accessCode.every(c => c !== '')) {
      setStage('pin');
    }
  }, [accessCode]);

  // Track auth stage
  useEffect(() => {
    trackEvent('Access Code');
  }, []);

  return (
    <>
      <Typography variant="body1" color="text.primary" mb={2}>
        Enter your access code.
      </Typography>
      <Typography variant="body2" color="text.secondary" mb={2}>
        If you don't have an access code, head to{' '}
        <Link href="https://www.fluidkey.com">fluidkey.com</Link> and join the waitlist.
      </Typography>
      <PinInput setPin={setAccessCode} pin={accessCode} numeric={false} />
      <Button
        size="small"
        sx={{ color: 'text.secondary', opacity: 0.7, marginTop: 2 }}
        onClick={() => {
          disconnect();
          void logout();
          setConnectActionCompleted(false);
          setStage('connect');
        }}
      >
        Back
      </Button>
    </>
  );
}

/**
 * Logic and confirmation to send funds
 */

import { ArrowRightAltRounded, LaunchRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Tooltip, Typography } from '@mui/material';
import {
  useGenerateBridgeQuote,
  useGenerateSwapQuote,
  useGenerateWithdrawalQuote,
  useGetSupportedTokens,
  useGetUserSmartAccounts,
} from '@sefu/react-sdk';
import { useEffect, useState } from 'react';
import { formatUnits, isAddress } from 'viem';
import { useAccount } from 'wagmi';
import { useMain } from '../../../../../../context/MainContext';
import { useTransfer } from '../../../../../../context/TransferContext';
import { chains } from '../../../../../../utils/chains';
import { formatAmount } from '../../../../../../utils/formatAmount';
import { gradient } from '../../../../../theme';
import ModalTransfer from '../Modal/ModalTransfer';

export default function SendButton(): JSX.Element {
  // Hooks
  const {
    modalOpen,
    error,
    setError,
    setModalOpen,
    setQuote,
    balance,
    balanceOnHold,
    idToken,
    sendAmount,
    sendTo,
    token,
    ensIsLoading,
    decimals,
    formattedSendAmount,
    toToken,
    toChain,
    setSwapQuote,
    setBridgeQuote,
    selectedLabels,
    openSwapBridge,
    contact,
  } = useTransfer();
  const {
    data,
    requestQuote,
    isLoading,
    error: quoteError,
    errorInfo,
  } = useGenerateWithdrawalQuote();
  const { chainId, lockOutgoingTransfers } = useMain();
  const { data: tokenData } = useGetSupportedTokens({ chainId });
  const {
    data: swapData,
    requestQuote: requestSwapQuote,
    isLoading: swapIsLoading,
    error: swapQuoteError,
    errorInfo: swapQuoteErrorInfo,
  } = useGenerateSwapQuote();
  const {
    data: bridgeData,
    requestQuote: requestBridgeQuote,
    isLoading: bridgeIsLoading,
    error: bridgeQuoteError,
    errorInfo: bridgeQuoteErrorInfo,
  } = useGenerateBridgeQuote();
  const { smartAccountList = [] } = useGetUserSmartAccounts();
  const { address } = useAccount();

  // Check if user was warned when trying to send to self
  const [sendToSelf, setSendToSelf] = useState<boolean>(false);
  const [feeTokens, setFeeTokens] = useState<string>('');

  // Check the type of transaction and format the data accordingly
  const txType =
    !openSwapBridge || (toChain === chainId && toToken === token)
      ? 'withdrawal'
      : toChain === chainId
      ? 'swap'
      : 'bridge';

  // Get the fee tokens to display in the error message
  useEffect(() => {
    if (tokenData?.feeTokens != null) {
      const feeTokenSymbols = tokenData.feeTokens.map(t => t?.symbol).filter(Boolean);
      const formattedFeeTokenSymbols =
        feeTokenSymbols.length > 1
          ? feeTokenSymbols.slice(0, -1).join(', ') + ', and ' + feeTokenSymbols.slice(-1)[0]
          : feeTokenSymbols[0];
      setFeeTokens(formattedFeeTokenSymbols);
    }
  }, [tokenData]);

  // Request a quote when the final amount or address changes, the null address is used as a placeholder to get quotes while the final address is not yet in the input
  useEffect(() => {
    let intervalId: NodeJS.Timeout = setInterval(() => {}, 0);
    const labels = selectedLabels.map(label => label.idLabel);
    const requestQuoteIfChecksAreTrue = (): void => {
      if (sendAmount > 0n && !modalOpen && !document.hidden) {
        const isMainToken = tokenData.feeTokens.find(t => t?.address === token?.address);
        if (txType === 'withdrawal') {
          void requestQuote({
            idToken,
            amount: sendAmount,
            to:
              sendTo.length === 42 && sendTo.startsWith('0x')
                ? sendTo
                : '0x0000000000000000000000000000000000000000',
            chainId,
            idSmartAccount: smartAccountList[0]?.idSmartAccount,
            epochControlStructure: 0,
            filter: { idLabels: labels },
            idTokenForFee: isMainToken != null ? idToken : undefined,
            contact,
          });
        } else if (txType === 'swap') {
          const fromIdToken = token?.idToken;
          const toIdToken = toToken?.idToken;
          if (fromIdToken == null || toIdToken == null) {
            return;
          }
          void requestSwapQuote({
            fromIdToken,
            toIdToken,
            amount: sendAmount,
            to:
              sendTo.length === 42 && sendTo.startsWith('0x')
                ? sendTo
                : '0x0000000000000000000000000000000000000000',
            chainId,
            idSmartAccount: smartAccountList[0]?.idSmartAccount,
            epochControlStructure: 0,
            filter: { idLabels: labels },
            idTokenForFee: isMainToken != null ? fromIdToken : undefined,
            contact,
          });
        } else if (txType === 'bridge') {
          const fromIdToken = token?.idToken;
          const toIdToken = toToken?.idToken;
          if (fromIdToken == null || toIdToken == null) {
            return;
          }
          void requestBridgeQuote({
            fromIdToken,
            toIdToken,
            amount: sendAmount,
            to:
              sendTo.length === 42 && sendTo.startsWith('0x')
                ? sendTo
                : '0x0000000000000000000000000000000000000000',
            fromChainId: chainId,
            toChainId: toChain,
            idSmartAccount: smartAccountList[0]?.idSmartAccount,
            epochControlStructure: 0,
            filter: { idLabels: labels },
            idTokenForFee: isMainToken != null ? fromIdToken : undefined,
            contact,
          });
        }
      }
    };

    // Call the function immediately
    requestQuoteIfChecksAreTrue();

    // Then set up the interval to call the function every minute
    intervalId = setInterval(requestQuoteIfChecksAreTrue, 60 * 1000);

    // Clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [sendAmount, sendTo, document.hidden, open, toToken, selectedLabels, contact]);

  // Compute the fees and total amount inclusive of fees
  const fees =
    data?.withdrawalProcedure?.relayFee != null
      ? BigInt(data?.withdrawalProcedure?.relayFee) + BigInt(data?.withdrawalProcedure?.serviceFee)
      : errorInfo?._code === 'ERR_000'
      ? BigInt(errorInfo?.totalFee)
      : 0n;

  const swapFees =
    swapData?.swapProcedure[0]?.relayFee != null
      ? BigInt(swapData?.swapProcedure[0]?.relayFee) +
        BigInt(swapData?.swapProcedure[0]?.serviceFee)
      : swapQuoteErrorInfo?._code === 'ERR_000'
      ? BigInt(swapQuoteErrorInfo?.totalFee)
      : 0n;

  const bridgeFees =
    bridgeData?.bridgeProcedure[0]?.relayFee != null
      ? BigInt(bridgeData?.bridgeProcedure[0]?.relayFee) +
        BigInt(bridgeData?.bridgeProcedure[0]?.serviceFee)
      : bridgeQuoteErrorInfo?._code === 'ERR_000'
      ? BigInt(bridgeQuoteErrorInfo?.totalFee)
      : 0n;

  const swapFromTotalAmount =
    swapData?.swapProcedure[0]?.amount != null
      ? BigInt(swapData?.swapProcedure[0]?.amount)
      : swapQuoteErrorInfo?._code === 'ERR_000'
      ? BigInt(swapQuoteErrorInfo?.totalFee) + sendAmount
      : 0n;

  const swapToTotalAmount =
    swapData?.swapProcedure[0]?.minAmountReceived != null
      ? BigInt(swapData?.swapProcedure[0]?.minAmountReceived)
      : 0n;

  const bridgeFromTotalAmount =
    bridgeData?.bridgeProcedure[0]?.amount != null
      ? BigInt(bridgeData?.bridgeProcedure[0]?.amount)
      : bridgeQuoteErrorInfo?._code === 'ERR_000'
      ? BigInt(bridgeQuoteErrorInfo?.totalFee) + sendAmount
      : 0n;

  const bridgeToTotalAmount =
    bridgeData?.bridgeProcedure[0]?.minAmountReceived != null
      ? BigInt(bridgeData?.bridgeProcedure[0]?.minAmountReceived)
      : 0n;

  const fullToken =
    txType === 'withdrawal' && fees > 0n && formattedSendAmount !== '' && !isLoading
      ? tokenData?.whitelistTokens.find(
          t => t.address === data?.withdrawalProcedure?.tokenForFee?.address
        )
      : txType === 'swap' && swapFees > 0n && formattedSendAmount !== '' && !swapIsLoading
      ? tokenData?.whitelistTokens.find(
          t => t.address === swapData?.swapProcedure[0]?.tokenForFee?.address
        )
      : txType === 'bridge' && bridgeFees > 0n && formattedSendAmount !== '' && !bridgeIsLoading
      ? tokenData?.whitelistTokens.find(
          t => t.address === bridgeData?.bridgeProcedure[0]?.tokenForFee?.address
        )
      : tokenData?.whitelistTokens.find(t => t.address === token?.address);

  const formattedFees =
    txType === 'withdrawal' && fees > 0n && formattedSendAmount !== '' && !isLoading
      ? formatAmount({
          amountTotalFormatted: formatUnits(
            fees,
            data?.withdrawalProcedure?.tokenForFee?.decimals ?? errorInfo?.tokenDecimals
          ),
          roundUp: true,
          decimals: 4,
        }) +
        ' ' +
        (data?.withdrawalProcedure?.tokenForFee?.symbol ?? errorInfo?.tokenSymbol) +
        ' ' +
        (fullToken?.usdPrice != null
          ? '~$' +
            formatAmount({
              amountTotalFormatted: (
                Number(
                  formatUnits(
                    fees,
                    data?.withdrawalProcedure?.tokenForFee?.decimals ?? errorInfo?.tokenDecimals
                  )
                ) * fullToken?.usdPrice
              ).toString(),
            })
          : '')
      : txType === 'swap' && swapFees > 0n && formattedSendAmount !== '' && !swapIsLoading
      ? formatAmount({
          amountTotalFormatted: formatUnits(
            swapFees,
            swapData?.swapProcedure[0]?.tokenForFee?.decimals ?? swapQuoteErrorInfo?.tokenDecimals
          ),
          roundUp: true,
          decimals: 4,
        }) +
        ' ' +
        (swapData?.swapProcedure[0]?.tokenForFee?.symbol ?? swapQuoteErrorInfo?.tokenSymbol) +
        ' ' +
        (fullToken?.usdPrice != null
          ? '~$' +
            formatAmount({
              amountTotalFormatted: (
                Number(
                  formatUnits(
                    swapFees,
                    swapData?.swapProcedure[0]?.tokenForFee?.decimals ??
                      swapQuoteErrorInfo?.tokenDecimals
                  )
                ) * fullToken?.usdPrice
              ).toString(),
            })
          : '')
      : txType === 'bridge' && bridgeFees > 0n && formattedSendAmount !== '' && !bridgeIsLoading
      ? formatAmount({
          amountTotalFormatted: formatUnits(
            bridgeFees,
            bridgeData?.bridgeProcedure[0]?.tokenForFee?.decimals ??
              bridgeQuoteErrorInfo?.tokenDecimals
          ),
          roundUp: true,
          decimals: 4,
        }) +
        ' ' +
        (bridgeData?.bridgeProcedure[0]?.tokenForFee?.symbol ?? bridgeQuoteErrorInfo?.tokenSymbol) +
        ' ' +
        (fullToken?.usdPrice != null
          ? '~$' +
            formatAmount({
              amountTotalFormatted: (
                Number(
                  formatUnits(
                    bridgeFees,
                    bridgeData?.bridgeProcedure[0]?.tokenForFee?.decimals ??
                      bridgeQuoteErrorInfo?.tokenDecimals
                  )
                ) * fullToken?.usdPrice
              ).toString(),
            })
          : '')
      : '';

  const formattedOutTotalAmount =
    txType === 'swap' && swapFromTotalAmount > 0n && formattedSendAmount !== ''
      ? formatAmount({
          amountTotalFormatted: formatUnits(swapFromTotalAmount, decimals),
          roundUp: true,
          decimals: 4,
        })
      : txType === 'bridge' && bridgeFromTotalAmount > 0n && formattedSendAmount !== ''
      ? formatAmount({
          amountTotalFormatted: formatUnits(bridgeFromTotalAmount, decimals),
          roundUp: true,
          decimals: 4,
        })
      : '';

  const formattedInTotalAmount =
    txType === 'swap' && swapToTotalAmount > 0n && formattedSendAmount !== ''
      ? formatAmount({
          amountTotalFormatted: formatUnits(
            swapToTotalAmount,
            swapData?.swapProcedure[0]?.tokenTo?.decimals as number
          ),
          roundUp: true,
          decimals: 4,
        })
      : txType === 'bridge' && bridgeToTotalAmount > 0n && formattedSendAmount !== ''
      ? formatAmount({
          amountTotalFormatted: formatUnits(
            bridgeToTotalAmount,
            bridgeData?.bridgeProcedure[0]?.tokenTo?.decimals as number
          ),
          roundUp: true,
          decimals: 4,
        })
      : '';

  const estimatedTime =
    txType === 'bridge' && bridgeData?.bridgeProcedure[0]?.serviceTime != null
      ? '· Time ~' + (bridgeData?.bridgeProcedure[0]?.serviceTime / 60).toString() + ' min'
      : '';

  // Input submission handler, checks for errors, sets the quote, and opens the modal
  const handleSubmit = (): void => {
    setError(undefined);
    if (!isAddress(sendTo)) {
      setError('Invalid address.');
      return;
    }
    if (sendAmount === 0n) {
      setError('The amount is 0, please enter a valid amount.');
      return;
    }
    if (sendAmount > balance - balanceOnHold) {
      setError('The amount is higher than your available balance.');
      return;
    }
    if (txType === 'withdrawal' && quoteError != null) {
      if (errorInfo?._code === 'ERR_019') {
        setError(
          `Transfer fees can be paid in ${feeTokens}. Make sure to have some in your account to transfer other tokens.`
        );
        return;
      }
      setError(quoteError);
      return;
    }
    if (txType === 'swap' && swapQuoteError != null) {
      if (swapQuoteErrorInfo?._code === 'ERR_019') {
        setError(
          `Transfer fees can be paid in ${feeTokens}. Make sure to have some in your account to transfer other tokens.`
        );
        return;
      }
      setError(swapQuoteError);
      return;
    }
    if (txType === 'bridge' && bridgeQuoteError != null) {
      if (bridgeQuoteErrorInfo?._code === 'ERR_019') {
        setError(
          `Transfer fees can be paid in ${feeTokens}. Make sure to have some in your account to transfer other tokens.`
        );
        return;
      }
      setError(bridgeQuoteError);
      return;
    }
    if (sendTo === address && !sendToSelf) {
      setError(
        'Sending funds to your public address reduces your privacy. Press the Send button again to proceed anyways.'
      );
      setSendToSelf(true);
      return;
    }
    const toAddress =
      txType === 'withdrawal'
        ? data?.withdrawalProcedure?.toAddress
        : txType === 'swap'
        ? swapData?.swapProcedure[0]?.toAddress
        : bridgeData?.bridgeProcedure[0]?.toAddress;
    const amount =
      txType === 'withdrawal'
        ? data?.withdrawalProcedure?.amount
        : txType === 'swap'
        ? swapData?.swapProcedure[0]?.amount
        : bridgeData?.bridgeProcedure[0]?.amount;
    if (
      sendTo.toLowerCase() !== toAddress ||
      (amount != null && sendAmount !== BigInt(amount)) ||
      sendAmount === 0n
    ) {
      setError('Sorry, an error occurred. Please try again or contact us if the issue persists.');
      return;
    }
    setModalOpen(true);
    if (txType === 'withdrawal') {
      setQuote(data);
      setSwapQuote(undefined);
      setBridgeQuote(undefined);
    }
    if (txType === 'swap') {
      setSwapQuote(swapData);
      setQuote(undefined);
      setBridgeQuote(undefined);
    }
    if (txType === 'bridge') {
      setBridgeQuote(bridgeData);
      setQuote(undefined);
      setSwapQuote(undefined);
    }
  };

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        width="100%"
        alignItems="flex-start"
        mt={3}
        ml={0.25}
        mb={2}
        rowGap={2}
        flexWrap="wrap"
      >
        <Box sx={{ whiteSpace: 'nowrap' }}>
          <Typography
            variant="body2"
            color={
              txType === 'withdrawal' && errorInfo?._code === 'ERR_000'
                ? 'error.main'
                : 'text.secondary'
            }
            mr={0.25}
            display="flex"
            alignItems="center"
            flexWrap="wrap"
          >
            <Box component="span">Fees {formattedFees}</Box>{' '}
            <Box component="span">{estimatedTime}</Box>
          </Typography>
          {txType !== 'withdrawal' && toToken != null ? (
            <Typography
              variant="body1"
              color={
                (txType === 'swap' && swapQuoteErrorInfo?._code === 'ERR_000') ||
                (txType === 'bridge' && bridgeQuoteErrorInfo?._code === 'ERR_000')
                  ? 'error.main'
                  : 'text.primary'
              }
              sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
              columnGap={0.5}
            >
              <Box component="span" display="flex" alignItems="center">
                {formattedOutTotalAmount} {token?.aliasSymbol ?? token?.symbol}
              </Box>
              <ArrowRightAltRounded fontSize="small" />
              <Box component="span" display="flex" alignItems="center">
                {formattedInTotalAmount} {toToken?.aliasSymbol ?? toToken?.symbol}
                <LaunchRounded
                  sx={{
                    width: '14px',
                    height: '14px',
                    marginLeft: '4px',
                    color: 'text.secondary',
                    ':hover': { cursor: 'pointer' },
                  }}
                  onClick={() => {
                    window.open(chains[toChain].explorer + 'token/' + toToken?.address);
                  }}
                />{' '}
              </Box>
            </Typography>
          ) : null}
        </Box>
        <Tooltip title={lockOutgoingTransfers ? 'Transactions pending...' : ''}>
          <Box display="flex" justifyContent="flex-end">
            <LoadingButton
              size="medium"
              variant="contained"
              endIcon={<ArrowRightAltRounded />}
              loading={isLoading || ensIsLoading || swapIsLoading || bridgeIsLoading}
              disabled={lockOutgoingTransfers}
              loadingPosition="end"
              sx={{
                backgroundImage: gradient,
                textTransform: 'none',
                fontWeight: 700,
                '&.MuiLoadingButton-loading': {
                  color: 'background.default',
                  opacity: 0.8,
                },
                '&.Mui-disabled': {
                  color: 'background.default',
                  opacity: 0.8,
                },
              }}
              onClick={() => {
                handleSubmit();
              }}
            >
              Send
            </LoadingButton>
          </Box>
        </Tooltip>
      </Box>
      {error != null ? (
        <Box mt={2}>
          <Alert severity="error">{error}</Alert>
        </Box>
      ) : null}
      {(txType === 'bridge' && bridgeData?.bridgeProcedure?.length === 0) ||
      (txType === 'swap' && swapData?.swapProcedure?.length === 0) ? (
        <Box mt={2}>
          <Alert severity="warning">This {txType} route is not available.</Alert>
        </Box>
      ) : null}
      {modalOpen ? <ModalTransfer /> : null}
    </Box>
  );
}

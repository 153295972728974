import { DownloadForOfflineRounded } from '@mui/icons-material';
import { Box, Button, Link, Typography } from '@mui/material';
import Plausible from 'plausible-tracker';
import { useMain } from '../../../../context/MainContext';

export default function Install(): JSX.Element {
  const { deferredPrompt, isInstalled, handleInstall } = useMain();
  const { trackEvent } = Plausible({
    domain: 'app.fluidkey.com, rollup.fluidkey',
    apiHost: 'https://sync.fluidkey.com',
  });

  return (
    <>
      <Box maxWidth="sm" display="flex" flexDirection="column" alignItems="center" my={1}>
        <Box p={2} width="100%">
          <Typography
            variant="body2"
            color="text.secondary"
            mb={0.5}
            textAlign="left"
            maxWidth="sm"
            px="2vh"
            width="100%"
          >
            Install App
          </Typography>
          <Box mt={1} display="flex" alignItems="center" justifyContent="space-between" px="2vh">
            <Typography
              variant="body2"
              component="div"
              color="text.primary"
              display="flex"
              alignItems="baseline"
              mr={2}
            >
              {isInstalled
                ? 'Fluidkey is already installed on your device.'
                : 'Add Fluidkey to your device.'}
            </Typography>
            {isInstalled ? null : (
              <Typography variant="body2" component="div" color="text.secondary" textAlign="right">
                {deferredPrompt != null ? (
                  <Button
                    variant="text"
                    size="small"
                    sx={{ color: 'inherit', textTransform: 'none' }}
                    onClick={handleInstall}
                  >
                    <DownloadForOfflineRounded
                      sx={{ width: '12px', height: '12px', marginRight: 0.5 }}
                    />
                    Install
                  </Button>
                ) : (
                  <Link
                    href="https://support.google.com/chrome/answer/9658361?hl=en&co=GENIE.Platform%3DiOS&oco=2"
                    target="_blank"
                    color="inherit"
                    underline="none"
                  >
                    <Button
                      variant="text"
                      size="small"
                      sx={{ color: 'inherit', textTransform: 'none', whiteSpace: 'nowrap' }}
                      onClick={(): void => {
                        trackEvent('Read More Install');
                      }}
                    >
                      <DownloadForOfflineRounded
                        sx={{ width: '12px', height: '12px', marginRight: 0.5 }}
                      />
                      Read more
                    </Button>
                  </Link>
                )}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}

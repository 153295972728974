/**
 * Address input field for sending funds
 */

import { Autocomplete, Box, TextField, Typography, Popper, Modal, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDebounce } from 'usehooks-ts';
import { normalize } from 'viem/ens';
import { useEnsAddress, useEnsName } from 'wagmi';
import { useTransfer } from '../../../../../../context/TransferContext';
import { useMain } from '../../../../../../context/MainContext';
import {
  ArrowRightAltRounded,
  CheckCircleOutlineRounded,
  CloseRounded,
  PersonAdd,
} from '@mui/icons-material';
import {
  useAddContact,
  useGetContacts,
  useDeleteContact,
  useGetUserSmartAccounts,
  type Contact,
  useGetSmartAccountTransfers,
} from '@sefu/react-sdk';
import CompressedAddress, {
  CompressedAddressWithoutEns,
} from '../../../../../atoms/Address/CompressedAddress';
import { gradient } from '../../../../../theme';

export default function AddressInput(): JSX.Element {
  const { sendTo, setSendTo, setEnsIsLoading, setError, sendToInput, setSendToInput, setContact } =
    useTransfer();
  const { chainId } = useMain();
  const { smartAccountList = [] } = useGetUserSmartAccounts();
  const { addContact, isLoading: addContactLoading } = useAddContact({
    idSmartAccount: smartAccountList[0]?.idSmartAccount,
  });
  const { data: contactData } = useGetContacts({
    idSmartAccount: smartAccountList[0]?.idSmartAccount,
  });
  const { deleteContact } = useDeleteContact({
    idSmartAccount: smartAccountList[0]?.idSmartAccount,
  });
  const { data: transferData } = useGetSmartAccountTransfers({
    idSmartAccount: smartAccountList[0]?.idSmartAccount,
    chainId,
  });
  const [sendToENS, setSendToENS] = useState<string>('');
  const [openContactName, setOpenContactName] = useState<boolean>(false);
  const [contactType, setContactType] = useState<string | undefined>();
  const [contactName, setContactName] = useState<string>('');
  const [contactToDelete, setContactToDelete] = useState<Contact | undefined>();
  const [refreshComponent, setRefreshComponent] = useState<string>('');
  const [recentAddresses, setRecentAddresses] = useState<Contact[]>([]);
  const [allContacts, setAllContacts] = useState<Contact[]>([]);
  const debouncedSendToENS = useDebounce(sendToENS, 500);
  let normalizedENS = '';
  try {
    normalizedENS = normalize(debouncedSendToENS);
  } catch (error) {
    console.error(error);
  }
  const {
    data: currentChainEnsData,
    isLoading: currentChainEnsIsLoading,
    isSuccess: currentChainEnsIsSuccess,
  } = useEnsAddress({
    name: normalizedENS,
    chainId,
  });
  const {
    data: mainnetEnsData,
    isLoading: mainnetIsLoading,
    isSuccess: mainnetIsSuccess,
  } = useEnsAddress({
    name: normalizedENS,
    chainId: 1,
  });
  const { data: ensName } = useEnsName({
    address: sendTo as `0x${string}`,
    chainId: 1,
  });

  useEffect(() => {
    setSendTo(currentChainEnsData ?? mainnetEnsData ?? '');
    if (currentChainEnsData != null || mainnetEnsData != null) {
      setContactType('ens');
    } else {
      setOpenContactName(false);
      setContactType(undefined);
    }
  }, [currentChainEnsData, mainnetEnsData, currentChainEnsIsSuccess, mainnetIsSuccess]);

  useEffect(() => {
    setEnsIsLoading(currentChainEnsIsLoading || mainnetIsLoading);
  }, [currentChainEnsIsLoading, mainnetIsLoading]);

  useEffect(() => {
    setError(undefined);
    if (sendToInput.startsWith('0x') && sendToInput.length === 42) {
      setSendTo(sendToInput);
      setOpenContactName(false);
      setContactType('hex');
    } else if (allContacts.some(contact => contact?.fullName === sendToInput)) {
      const contact = allContacts.find(contact => contact?.fullName === sendToInput);
      if (contact?.details?.EVMAddr[0]?.value != null) {
        setSendTo(contact.details.EVMAddr[0].value);
      } else if (contact?.details?.ens[0]?.value != null) {
        setSendToENS(contact.details.ens[0].value);
      }
    } else {
      setSendToENS(sendToInput);
    }
  }, [sendToInput]);

  useEffect(() => {
    if (
      ensName != null &&
      recentAddresses.some(contact => contact?.details?.EVMAddr[0]?.value === sendToInput)
    ) {
      setSendToENS(ensName);
      setSendToInput(ensName);
      setContactType('ens');
    }
  }, [ensName, sendToInput]);

  // set contact if it is in the sendToInput
  useEffect(() => {
    if (contactData.some(contact => contact?.fullName === sendToInput)) {
      const contact = contactData.find(contact => contact?.fullName === sendToInput);
      setContact(contact);
    } else if (
      contactData.some(contact => contact?.details?.EVMAddr[0]?.value === sendToInput.toLowerCase())
    ) {
      const contact = contactData.find(
        contact => contact?.details?.EVMAddr[0]?.value === sendToInput.toLowerCase()
      );
      setContact(contact);
    } else {
      setContact(undefined);
    }
  }, [sendToInput, contactData]);

  useEffect(() => {
    if (transferData != null) {
      const recentAddresses = transferData
        .filter((transfer, index, array) => {
          const isOutgoing = transfer.direction === 'OUTGOING';
          const isNotABridge = transfer.procedureType !== 'BRIDGE';
          const hasNoContact = transfer.contact == null;
          const isUniqueTxHash = index === 0 || transfer.txHash !== array[index - 1].txHash;
          const containsToAddress =
            transfer.procedure?.withdrawal?.toAddress != null ||
            transfer.procedure?.swap?.toAddress != null;
          return isOutgoing && hasNoContact && isUniqueTxHash && containsToAddress && isNotABridge;
        })
        .map(transfer => {
          const toAddress = (transfer.procedure?.withdrawal?.toAddress ??
            transfer.procedure?.swap?.toAddress) as string;
          return {
            idContact: toAddress,
            fullName: toAddress,
            details: {
              ens: [],
              EVMAddr: [{ id: toAddress, value: toAddress }],
            },
          };
        });
      const uniqueRecentAddresses = recentAddresses.filter(
        (contact, index, array) => array.findIndex(c => c.fullName === contact.fullName) === index
      );
      setRecentAddresses(uniqueRecentAddresses.slice(0, 3));
    }
  }, [transferData]);

  // Create a list of all contacts
  useEffect(() => {
    let reversedContactData: Contact[] = [];
    if (contactData != null) {
      reversedContactData = [...contactData].reverse();
    }
    const allContacts = [...recentAddresses, ...reversedContactData];
    setAllContacts(allContacts);
  }, [contactData, recentAddresses]);

  async function handleEnsContactName(): Promise<void> {
    const response = await addContact({
      fullName: normalizedENS,
      details: {
        ens: normalizedENS,
      },
    });
    if (response) {
      setOpenContactName(true);
    }
  }

  async function handleHexContactName(): Promise<void> {
    const normalizedContactName = contactName.trim();
    const response = await addContact({
      fullName: normalizedContactName,
      details: {
        evmAddress: sendTo,
      },
    });
    if (response) {
      setOpenContactName(false);
      setSendToInput(normalizedContactName);
      setRefreshComponent(normalizedContactName);
      setContactName('');
    }
  }

  function handleAddContactClick(): void {
    if (contactType === 'ens') {
      void handleEnsContactName();
    } else {
      setContactName('');
      setOpenContactName(true);
    }
  }

  return (
    <Box display="flex" gap={0.5}>
      <Autocomplete
        key={refreshComponent}
        options={allContacts}
        clearOnBlur={false}
        selectOnFocus={false}
        noOptionsText={null}
        getOptionLabel={option => option?.fullName}
        onInputChange={(event, newInputValue) => {
          setSendToInput(newInputValue);
        }}
        value={
          allContacts.find(contact => contact?.fullName === sendToInput) ??
          allContacts.find(
            contact => contact?.details?.EVMAddr[0]?.value === sendToInput.toLowerCase()
          ) ?? {
            idContact: 'empty',
            fullName: sendToInput,
            details: { ens: [], EVMAddr: [] },
          }
        }
        renderOption={(props, option, { selected }) =>
          option != null ? (
            <li key={option.idContact} {...props}>
              <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                <Box display="flex" alignItems="baseline" width="80%">
                  <Typography
                    variant="body1"
                    sx={{
                      flexWrap: 'nowrap',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      flexGrow: 0,
                      flexShrink: 0,
                    }}
                  >
                    {option.idContact.startsWith('0x') ? (
                      <CompressedAddress address={option?.details?.EVMAddr[0]?.value} />
                    ) : (
                      option.fullName
                    )}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      marginLeft: '8px',
                      flexWrap: 'nowrap',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      flexGrow: 0,
                      flexShrink: 1,
                    }}
                    component="span"
                  >
                    {option.idContact.startsWith('0x') ? (
                      'recent'
                    ) : (
                      <CompressedAddressWithoutEns address={option?.details?.EVMAddr[0]?.value} />
                    )}
                  </Typography>
                </Box>
                {!option.idContact.startsWith('0x') ? (
                  <Box
                    display="flex"
                    onClick={event => {
                      event.stopPropagation();
                      setContactToDelete(option);
                      setRefreshComponent(sendToInput);
                    }}
                    alignItems="baseline"
                  >
                    <CloseRounded fontSize="small" />
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            </li>
          ) : (
            <></>
          )
        }
        renderInput={params => (
          <TextField
            key={refreshComponent}
            {...params}
            label="to"
            placeholder="address or ENS"
            variant="outlined"
            type="text"
            margin="dense"
            size="small"
            value={sendToInput}
            color="primary"
            autoComplete="off"
            autoCorrect="off"
            inputProps={{
              ...params.inputProps,
              autoCapitalize: 'none',
              spellCheck: 'false',
            }}
            InputProps={{
              ...params.InputProps,
              fullWidth: true,
              endAdornment: (
                <Box
                  height="100%"
                  display="flex"
                  alignItems="center"
                  sx={{
                    cursor:
                      contactData != null &&
                      (openContactName ||
                        contactType == null ||
                        contactData.some(
                          contact => contact?.details?.ens[0]?.value === normalizedENS
                        ) ||
                        contactData.some(
                          contact =>
                            contact?.details?.EVMAddr[0]?.value === sendToInput.toLowerCase()
                        ))
                        ? null
                        : 'pointer',
                    '-webkit-tap-highlight-color': 'transparent',
                  }}
                  ml={1}
                  onClick={() => {
                    if (
                      contactData != null &&
                      (openContactName ||
                        contactType == null ||
                        contactData.some(
                          contact => contact?.details?.ens[0]?.value === normalizedENS
                        ) ||
                        contactData.some(
                          contact =>
                            contact?.details?.EVMAddr[0]?.value === sendToInput.toLowerCase()
                        ))
                    ) {
                      return;
                    }
                    handleAddContactClick();
                  }}
                >
                  {contactData != null &&
                  (openContactName ||
                    contactType == null ||
                    contactData.some(
                      contact => contact?.details?.ens[0]?.value === normalizedENS
                    ) ||
                    contactData.some(
                      contact => contact?.details?.EVMAddr[0]?.value === sendToInput.toLowerCase()
                    ) ||
                    contactData.some(contact => contact?.fullName === sendToInput)) ? null : (
                    <PersonAdd fontSize="small" />
                  )}
                  {(contactData != null &&
                    (openContactName ||
                      contactData.some(
                        contact => contact?.details?.ens[0]?.value === normalizedENS
                      )) &&
                    contactType === 'ens') ||
                  contactData.some(
                    contact => contact?.details?.EVMAddr[0]?.value === sendToInput.toLowerCase()
                  ) ||
                  contactData.some(contact => contact?.fullName === sendToInput) ? (
                    <CheckCircleOutlineRounded fontSize="small" color="success" />
                  ) : null}
                </Box>
              ),
            }}
          />
        )}
        sx={{
          marginBottom: 0,
          width: '100% !important',
          paddingRight: '0 !important',
          '.MuiOutlinedInput-root': {
            paddingRight: '13px !important',
          },
          '.MuiAutocomplete-option': {
            minHeight: '0px !important',
          },
        }}
        PopperComponent={({ children, ...props }) => {
          const anchorEl = document.querySelector('.MuiAutocomplete-root');
          return (
            <Popper
              {...props}
              anchorEl={props.anchorEl}
              style={{
                display: allContacts.length === 0 ? 'none' : undefined,
                width: anchorEl?.clientWidth,
              }}
              sx={{
                '.MuiAutocomplete-noOptions': {
                  display: 'none',
                },
              }}
            >
              {children}
            </Popper>
          );
        }}
      />
      {openContactName && contactType === 'hex' ? (
        <TextField
          label="add contact"
          placeholder="name"
          variant="outlined"
          type="text"
          margin="dense"
          size="small"
          color={
            contactData.some(contact => contact?.fullName === contactName) && !addContactLoading
              ? 'error'
              : 'primary'
          }
          helperText={
            contactData.some(contact => contact?.fullName === contactName) && !addContactLoading
              ? 'already in use'
              : 'press → to save'
          }
          FormHelperTextProps={{
            sx: {
              marginTop: 0,
              color:
                contactData.some(contact => contact?.fullName === contactName) && !addContactLoading
                  ? 'error.main'
                  : 'text.secondary',
              fontSize: '10px',
            },
          }}
          autoComplete="off"
          autoCorrect="off"
          autoFocus
          value={contactName}
          onChange={e => {
            setContactName(e.target.value);
          }}
          inputProps={{
            autoCapitalize: 'none',
            spellCheck: 'false',
          }}
          InputProps={{
            fullWidth: true,
            endAdornment: (
              <Box
                height="100%"
                display="flex"
                alignItems="center"
                sx={{
                  cursor: contactData.some(contact => contact?.fullName === contactName)
                    ? null
                    : 'pointer',
                  '-webkit-tap-highlight-color': 'transparent',
                }}
                ml={1}
                onClick={() => {
                  if (contactData.some(contact => contact?.fullName === contactName)) {
                    return;
                  }
                  void handleHexContactName();
                }}
              >
                <ArrowRightAltRounded
                  fontSize="small"
                  color={
                    contactData.some(contact => contact?.fullName === contactName)
                      ? 'disabled'
                      : 'primary'
                  }
                />
              </Box>
            ),
          }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              if (contactData.some(contact => contact?.fullName === contactName)) {
                return;
              }
              void handleHexContactName();
            }
          }}
          sx={{
            marginBottom: 0,
            width: '100%',
          }}
        />
      ) : null}
      <Modal open={contactToDelete != null}>
        <Box
          sx={{
            position: 'fixed',
            top: '40%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '90vw',
            '&:focus-visible': {
              outline: 'none !important',
            },
          }}
        >
          <Box
            width="100%"
            minWidth="300px"
            maxWidth="sm"
            bgcolor="background.paper"
            sx={{
              borderRadius: '10px',
              paddingY: '30px',
              paddingX: '30px',
            }}
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Typography variant="body1" width="100%" mb={2}>
              You are about to delete {contactToDelete?.fullName} from your contacts.
            </Typography>
            <Button
              size="medium"
              variant="contained"
              sx={{
                backgroundImage: gradient,
                textTransform: 'none',
                fontWeight: 700,
              }}
              onClick={() => {
                void deleteContact({
                  idContact: contactToDelete?.idContact as string,
                });
                setContactToDelete(undefined);
                setSendToInput('');
              }}
            >
              Proceed?
            </Button>
            <Button
              size="small"
              sx={{ color: 'text.secondary', opacity: 0.7, marginTop: 2 }}
              onClick={() => {
                setContactToDelete(undefined);
              }}
            >
              Back
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

import { Typography, Skeleton } from '@mui/material';
import ChainSelector from './ChainSelector';
import { useGetSmartAccountUsdBalanceCrossChain, useGetUserSmartAccounts } from '@sefu/react-sdk';
import { useEffect, useState } from 'react';
import { formatAmount } from '../../../../../utils/formatAmount';

export default function Overview(): JSX.Element {
  const { smartAccountList = [] } = useGetUserSmartAccounts();
  const { data: usdBalance } = useGetSmartAccountUsdBalanceCrossChain({
    idSmartAccount: smartAccountList[0]?.idSmartAccount,
    polling: true,
  });
  const [totalUsdBalance, setTotalUsdBalance] = useState<string | undefined>();

  // Compute total USD balance based on per chain balance
  useEffect(() => {
    if (usdBalance?.chains != null) {
      const total = usdBalance.chains.reduce((acc, item) => {
        if (item.chainId !== 11155111) {
          return acc + Number(item.totalUsd);
        }
        return acc;
      }, 0);
      const totalFormatted = formatAmount({
        amountTotalFormatted: total.toString(),
        decimals: total < 100 ? 2 : 0,
      });
      setTotalUsdBalance(totalFormatted);
    }
  }, [usdBalance]);

  return (
    <>
      <Typography
        variant="h5"
        fontSize={26}
        fontWeight={700}
        color="text.primary"
        mt={-1}
        mb={0.5}
        textAlign="left"
        maxWidth="sm"
        px="2vh"
      >
        {totalUsdBalance != null ? (
          `$${totalUsdBalance}`
        ) : (
          <Skeleton variant="text" width="70px" height="35px" />
        )}
      </Typography>
      <ChainSelector usdBalance={usdBalance} />
    </>
  );
}

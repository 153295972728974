/**
 * Overview of user funds and sub-menu to send funds out of each balance.
 *
 * The token balances are wrapped in a TransferProvider, the context that handles the outgoing transfers.
 */

import { Box, Skeleton, Typography } from '@mui/material';
import {
  useGetSmartAccountBalance,
  useGetUserSmartAccounts,
  useGetSupportedTokens,
} from '@sefu/react-sdk';
import { useEffect, useState } from 'react';
import { useMain } from '../../../context/MainContext';
import { TransferProvider } from '../../../context/TransferContext';
import TokenBalance from '../../molecules/Dashboard/Funds/TokenBalance';
import Overview from '../../molecules/Dashboard/Funds/Overview/Overview';
import AddTokenModal from '../../molecules/Dashboard/Funds/AddToken/AddTokenModal';

export default function Funds(): JSX.Element {
  const { smartAccountList = [], isLoading: isListLoading } = useGetUserSmartAccounts();
  const { chainId, refreshBalance, setOpenAddTokenModal } = useMain();
  const { data, isLoading, refetch } = useGetSmartAccountBalance({
    idSmartAccount: smartAccountList[0]?.idSmartAccount,
    chainId,
  });
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const { data: tokenData } = useGetSupportedTokens({ chainId });
  const filteredTokens = tokenData?.feeTokens.filter(
    option =>
      (option?.address === '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee' ||
        option?.symbol === 'USDC' ||
        option?.symbol === 'USDT' ||
        option?.symbol === 'DAI' ||
        option?.symbol === 'MATIC') &&
      option?.aliasSymbol == null
  );

  useEffect(() => {
    if (isFirstLoad && !isLoading) {
      const timer = setTimeout(() => {
        setIsFirstLoad(false);
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [isLoading]);

  useEffect(() => {
    void refetch();
  }, [refreshBalance]);

  return (
    <Box width="100%" className="funds" maxWidth="sm">
      <Typography
        variant="body2"
        color="text.secondary"
        textAlign="left"
        maxWidth="sm"
        mb={0.75}
        px="2vh"
        mt={2}
      >
        Funds
      </Typography>
      <Overview />
      <TransferProvider>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <>
            {[...data] // Create a shallow copy of the data array
              ?.sort((a, b) => b.amountUsd - a.amountUsd)
              .map(item => {
                return (
                  <Box
                    key={item.token.aliasSymbol ?? item.token.symbol}
                    sx={{
                      flexBasis: { xs: '100%', sm: '49.3%' },
                      maxWidth: { xs: '100%', sm: '49.3%' },
                    }}
                  >
                    <TokenBalance
                      name={item.token.aliasSymbol ?? item.token.symbol}
                      amount={item?.amount ?? '0'}
                      amountOnHold={item?.amountOnHold ?? '0'}
                      decimals={item?.token.decimals ?? 18}
                      isLoading={isFirstLoad || isListLoading}
                      idToken={item?.token.idToken}
                      amountUsd={item?.amountUsd ?? 0}
                      tokenData={item?.token}
                    />
                  </Box>
                );
              })}
          </>
          {data?.length === 0 ? (
            !isLoading && !isFirstLoad ? (
              <>
                {[...filteredTokens].map(token => {
                  return (
                    <Box
                      key={token.address}
                      sx={{
                        flexBasis: { xs: '100%', sm: '49.3%' },
                        maxWidth: { xs: '100%', sm: '49.3%' },
                      }}
                    >
                      <TokenBalance
                        name={token.symbol}
                        amount={'0'}
                        amountOnHold={'0'}
                        decimals={18}
                        isLoading={isFirstLoad || isListLoading}
                        idToken={token.idToken}
                        amountUsd={0}
                        tokenData={token}
                      />
                    </Box>
                  );
                })}
                <Box>
                  <Box
                    sx={{
                      whiteSpace: 'nowrap',
                      cursor: 'pointer',
                      userSelect: 'none !important',
                      outline: 'none',
                      height: 'auto',
                      '&:focus': {
                        outline: 'none',
                        backgroundColor: 'transparent !important',
                      },
                      '&:active': {
                        backgroundColor: 'transparent !important',
                      },
                    }}
                    onClick={event => {
                      setOpenAddTokenModal(true);
                      event.currentTarget.style.backgroundColor = 'transparent';
                    }}
                  >
                    <Typography variant="caption" color="text.secondary" px="2vh">
                      Not seeing a token? Import it here.
                    </Typography>
                  </Box>
                </Box>
              </>
            ) : (
              <Box>
                <Skeleton
                  variant="text"
                  width="250px"
                  height="50px"
                  sx={{ marginRight: 1, marginLeft: '2vh', marginTop: -1 }}
                />
                <Skeleton
                  variant="text"
                  width="250px"
                  height="50px"
                  sx={{ marginRight: 1, marginLeft: '2vh', marginTop: -1 }}
                />
              </Box>
            )
          ) : (
            <Box>
              <Box
                sx={{
                  whiteSpace: 'nowrap',
                  cursor: 'pointer',
                  userSelect: 'none !important',
                  outline: 'none',
                  height: 'auto',
                  '&:focus': {
                    outline: 'none',
                    backgroundColor: 'transparent !important',
                  },
                  '&:active': {
                    backgroundColor: 'transparent !important',
                  },
                }}
                onClick={event => {
                  setOpenAddTokenModal(true);
                  event.currentTarget.style.backgroundColor = 'transparent';
                }}
              >
                <Typography variant="caption" color="text.secondary" px="2vh">
                  Not seeing a token? Import it here.
                </Typography>
              </Box>
            </Box>
          )}
          <AddTokenModal />
        </Box>
      </TransferProvider>
    </Box>
  );
}

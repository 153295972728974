/**
 * Auth Page
 *
 * Setting the AuthProvider context and rendering AuthParent, which handles the entire authentication flow and UI.
 */

import React from 'react';
import { AuthProvider } from '../../../context/AuthContext';
import AuthParent from '../../organisms/Auth/AuthParent';

export default function Auth(): JSX.Element {
  return (
    <AuthProvider>
      <AuthParent />
    </AuthProvider>
  );
}

/**
 * Auth Wallet Connection
 *
 * First stage in the auth flow, where the user connects their wallet.
 */

import {
  ArrowRightAltRounded,
  EmailRounded,
  Google,
  ExpandMoreRounded,
  ExpandLessRounded,
} from '@mui/icons-material';
import { Box, Button, Link, Typography } from '@mui/material';
import { useConnectWallet, useLogin, useLogout, usePrivy, useWallets } from '@privy-io/react-auth';
import { useSetActiveWallet } from '@privy-io/wagmi';
import Plausible from 'plausible-tracker';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../context/AuthContext';
import FarcasterIcon from '../../atoms/Logo/FarcasterIcon';
import { gradient } from '../../theme';

export default function Connect(): JSX.Element {
  const { setError, setConnectActionCompleted } = useAuth();
  const { login } = useLogin({
    onComplete: () => {
      void setActiveWallet(wallets[0]);
      setConnectActionCompleted(true);
    },
    onError: error => {
      console.error('Error logging in:', error);
    },
  });
  const { logout } = useLogout();
  const { authenticated } = usePrivy();
  const { connectWallet } = useConnectWallet({
    onSuccess: () => {
      setConnectActionCompleted(true);
    },
  });
  const { setActiveWallet } = useSetActiveWallet();
  const { wallets } = useWallets();
  const { trackEvent } = Plausible({
    domain: 'app.fluidkey.com, rollup.fluidkey',
    apiHost: 'https://sync.fluidkey.com',
  });
  const [learnMore, setLearnMore] = useState(false);

  // Open the WalletConnect modal and reset error state
  function handleConnectWallet(): void {
    connectWallet();
    setError(undefined);
  }

  // Login with web2
  function handleLogin(): void {
    if (authenticated) {
      void logout();
    } else {
      login();
      setError(undefined);
    }
  }

  // Track auth stage
  useEffect(() => {
    trackEvent('Connect');
  }, []);

  return (
    <Box display="flex" flexDirection="column" alignItems="top" justifyContent="top" flexGrow={1}>
      <Typography variant="body1" color="text.primary" mb={3} textAlign="center">
        Start by connecting your wallet.
      </Typography>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Button
          variant="contained"
          size="medium"
          onClick={handleConnectWallet}
          endIcon={<ArrowRightAltRounded />}
          sx={{
            background: gradient,
            textTransform: 'none',
            fontWeight: 700,
          }}
        >
          Connect
        </Button>
        <Typography
          variant="body2"
          color="text.secondary"
          my={2}
          textAlign="center"
          maxWidth="300px"
        >
          or
        </Typography>
        <Button
          variant="outlined"
          color="inherit"
          size="medium"
          onClick={handleLogin}
          sx={{
            paddingY: '3px',
            paddingX: '12px',
            marginX: '12px',
            textTransform: 'none',
            color: 'text.primary',
          }}
        >
          Sign in with{' '}
          <Typography fontSize="1.2em" pl={1} display="flex" alignItems="center" gap={0.75}>
            <FarcasterIcon color="text.secondary" /> <Google fontSize="inherit" />{' '}
            <EmailRounded fontSize="inherit" />
          </Typography>
        </Button>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        color="text.secondary"
        onClick={() => {
          setLearnMore(!learnMore);
        }}
        mt={4}
        sx={{ cursor: 'pointer', WebkitTapHighlightColor: 'transparent' }}
      >
        <Typography variant="body2" color="text.secondary">
          Learn more
        </Typography>
        {learnMore ? (
          <ExpandLessRounded fontSize="inherit" color="inherit" />
        ) : (
          <ExpandMoreRounded fontSize="inherit" color="inherit" />
        )}
      </Box>
      {learnMore ? (
        <>
          <Typography variant="body2" color="text.secondary" mt={2} mb={1} width="100%">
            Fluidkey protects your privacy. It allows you to receive and manage funds without
            publicly linking them to you.
          </Typography>
          <Typography variant="body2" color="text.secondary" mb={1} width="100%">
            Every transfer received is sent to a new self-custodial address that isn't tied to your
            other addresses.
          </Typography>
          <Typography variant="body2" color="text.secondary" width="100%">
            Only you and Fluidkey can see all of your transactions. Visit{' '}
            <Link href="https://fluidkey.com">fluidkey.com</Link> for more information.
          </Typography>
        </>
      ) : null}{' '}
    </Box>
  );
}

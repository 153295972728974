/**
 * Outgoing transfer modal
 *
 * Opens after the user clicks the Send button in the SendInput component.
 * Asks for the user's PIN and signature to regenerate the stealth keys if they are not in the client.
 * Then asks for final confirmation before sending the transaction.
 */

import { Box, Button, Modal } from '@mui/material';
import { useTransfer } from '../../../../../../context/TransferContext';
import ConfirmTransfer from './ConfirmTransfer';
import KeysTransfer from './KeysTransfer';
import PinTransfer from './PinTransfer';

export default function ModalTransfer(): JSX.Element {
  const { modalOpen, setModalOpen, stage, setStage, setPin } = useTransfer();

  const stageComponentMap = {
    pin: <PinTransfer />,
    generateKeys: <KeysTransfer />,
    confirm: <ConfirmTransfer />,
  };

  return (
    <Modal open={modalOpen}>
      <Box
        sx={{
          position: 'fixed',
          top: '40%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '90vw',
          '&:focus-visible': {
            outline: 'none !important',
          },
        }}
      >
        <Box
          width="100%"
          maxWidth="sm"
          bgcolor="background.paper"
          sx={{
            borderRadius: '10px',
            paddingY: '48px',
            paddingX: '48px',
          }}
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          {stageComponentMap[stage]}
          <Button
            size="small"
            sx={{ color: 'text.secondary', opacity: 0.7, marginTop: 2 }}
            onClick={() => {
              setModalOpen(false);
              setStage('pin');
              setPin(['', '', '', '']);
            }}
          >
            Back
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

import {
  CloseRounded,
  DownloadForOfflineRounded,
  EditRounded,
  ExploreRounded,
} from '@mui/icons-material';
import { Box, Button, IconButton, Link, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTour } from '@reactour/tour';
import { useGetSmartAccount, useGetUserSmartAccounts } from '@sefu/react-sdk';
import Plausible from 'plausible-tracker';
import { useState } from 'react';
import { useMain } from '../../../context/MainContext';
import theme, { gradient } from '../../theme';

export default function Welcome(): JSX.Element {
  const {
    setOpenDrawer,
    setIsEditingUsername,
    handleInstall,
    deferredPrompt,
    isInstalled,
    isFirstTimeUser,
    setIsFirstTimeUser,
    searchParams,
  } = useMain();
  const { trackEvent } = Plausible({
    domain: 'app.fluidkey.com, rollup.fluidkey',
    apiHost: 'https://sync.fluidkey.com',
  });
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { setIsOpen, setCurrentStep } = useTour();
  const { smartAccountList = [] } = useGetUserSmartAccounts();
  const { smartAccount } = useGetSmartAccount({
    idSmartAccount: smartAccountList[0]?.idSmartAccount,
  });
  const [clickedUsername, setClickedUsername] = useState(false);

  return isFirstTimeUser ? (
    <Box display="flex" maxWidth="sm" width="100%">
      <Box
        maxWidth="sm"
        width="100%"
        sx={{
          position: 'relative',
          background: gradient,
        }}
        py="4vh"
        px="4vh"
        mb={4}
        borderRadius="10px"
        boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            color: 'background.paper',
          }}
          onClick={() => {
            trackEvent('Close Welcome');
            setIsFirstTimeUser(false);
          }}
        >
          <CloseRounded />
        </IconButton>
        <Typography variant="h4" color="background.paper" pl={1}>
          Welcome
        </Typography>
        <Typography variant="body1" color="background.paper" pl={1} pt={2} pb={1}>
          <b>Fluidkey protects your privacy.</b> It allows you to receive and handle funds without
          publicly linking them to you.
        </Typography>
        <Typography variant="body1" color="background.paper" pl={1} pt={0.25} pb={1}>
          Every transfer received is sent to a new self-custodial address that isn't tied to your
          other addresses.
        </Typography>
        <Typography variant="body1" color="background.paper" pl={1} pt={0.25} pb={1}>
          Only you and Fluidkey can see all of your transactions.
        </Typography>
        <Box
          display="flex"
          flexDirection={isSmallScreen ? 'column' : 'row'}
          alignItems="flex-start"
          justifyContent="center"
          mt={1}
          gap={isSmallScreen ? 0.5 : 1}
        >
          <Button
            variant="outlined"
            sx={{
              color: 'background.paper',
              textTransform: 'none',
              fontWeight: 700,
              marginTop: '1vh',
              borderColor: 'background.paper',
              backgroundColor: 'rgba(0, 0, 0, 0.05)',
              '&:hover': {
                borderColor: 'background.paper',
                backgroundColor: 'rgba(0, 0, 0, 0.12)',
              },
              whiteSpace: 'nowrap',
              width: '100%',
            }}
            onClick={() => {
              setCurrentStep(0);
              trackEvent('Welcome Docs');
              setIsOpen(true);
            }}
          >
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
              <>Quick tour</>
              <ExploreRounded fontSize="inherit" />
            </Box>
          </Button>
          {!clickedUsername && searchParams?.get('username') !== smartAccount?.username ? (
            <Button
              variant="outlined"
              sx={{
                color: 'background.paper',
                textTransform: 'none',
                fontWeight: 700,
                marginTop: '1vh',
                borderColor: 'background.paper',
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
                '&:hover': {
                  borderColor: 'background.paper',
                  backgroundColor: 'rgba(0, 0, 0, 0.12)',
                },
                whiteSpace: 'nowrap',
                width: '100%',
              }}
              onClick={() => {
                trackEvent('Welcome Username');
                setOpenDrawer(true);
                setIsEditingUsername(true);
                setClickedUsername(true);
              }}
            >
              <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                <>Pick a username</>
                <EditRounded fontSize="inherit" />
              </Box>
            </Button>
          ) : null}
          {isInstalled ? null : (
            <Button
              variant="outlined"
              sx={{
                color: 'background.paper',
                textTransform: 'none',
                fontWeight: 700,
                marginTop: '1vh',
                borderColor: 'background.paper',
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
                '&:hover': {
                  borderColor: 'background.paper',
                  backgroundColor: 'rgba(0, 0, 0, 0.12)',
                },
                whiteSpace: 'nowrap',
                width: '100%',
              }}
              onClick={() => {
                trackEvent('Welcome Install');
                if (deferredPrompt !== null) {
                  handleInstall();
                } else {
                  window.open(
                    'https://support.google.com/chrome/answer/9658361?hl=en&co=GENIE.Platform%3DiOS&oco=2',
                    '_blank'
                  );
                }
              }}
            >
              <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                <>Install the app</>
                <DownloadForOfflineRounded fontSize="inherit" />
              </Box>
            </Button>
          )}
        </Box>
        <Typography variant="body2" color="background.paper" pl={1} pt={4}>
          Please share feedback, questions, and report any issues in{' '}
          <Link href="https://warpcast.com/~/channel/fluidkey" target="_blank" color="#198DC2">
            /fluidkey
          </Link>{' '}
          or via{' '}
          <Link href="mailto:hey@fluidkey.com" target="_blank" color="#198DC2">
            email
          </Link>
          .
        </Typography>
      </Box>
    </Box>
  ) : (
    <></>
  );
}

import { CheckCircleOutlineRounded, ContentCopyRounded } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { moreColors } from '../../../theme';

export default function Invites({ inviteCodeList }: { inviteCodeList: string[] }): JSX.Element {
  const [copied, setCopied] = useState<string | null>(null);
  const copyToClipboard = (text: string): void => {
    const url = 'app.fluidkey.com/auth?code=' + text;
    void navigator.clipboard.writeText(url);
    setCopied(text);
  };
  return (
    <>
      <Box maxWidth="sm" display="flex" flexDirection="column" alignItems="center" my={1}>
        <Box p={2} width="100%">
          <Typography
            variant="body2"
            color="text.secondary"
            mb={1.5}
            textAlign="left"
            maxWidth="sm"
            px="2vh"
            width="100%"
          >
            Invite Codes
          </Typography>
          {inviteCodeList.map((code, index) => (
            <Button
              key={index}
              variant="contained"
              sx={{
                mb: 1,
                textTransform: 'none',
                fontSize: 'body2.fontSize',
                backgroundColor: moreColors.lightPaperBackground,
                justifyContent: 'space-between',
                color: 'text.primary',
                '&:hover': {
                  backgroundColor: moreColors.light2PaperBackground,
                },
                '&:disabled': {
                  color: 'text.primary',
                },
              }}
              fullWidth={true}
              onClick={() => {
                copyToClipboard(code);
              }}
            >
              <Box display="flex" alignItems="left">
                <Typography component="div" variant="body2" color="inherit" fontWeight={600}>
                  <Box display="flex">
                    <Typography variant="body2">{code}</Typography>
                  </Box>
                </Typography>
              </Box>
              <Typography
                variant="body2"
                color={copied === code ? 'success.main' : 'inherit'}
                fontWeight={600}
                pt={0.2}
              >
                {copied === code ? (
                  <CheckCircleOutlineRounded fontSize="inherit" />
                ) : (
                  <ContentCopyRounded fontSize="inherit" />
                )}
              </Typography>
            </Button>
          ))}
        </Box>
      </Box>
    </>
  );
}

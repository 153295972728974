import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, from } from '@apollo/client';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { PrivyProvider } from '@privy-io/react-auth';
import { WagmiProvider } from '@privy-io/wagmi';
import { FluidkeyClient, FluidkeyProvider } from '@sefu/react-sdk';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Plausible from 'plausible-tracker';
import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { createConfig, http } from 'wagmi';
import { arbitrum, base, mainnet, optimism, polygon, sepolia } from 'wagmi/chains';
import { MainProvider } from './context/MainContext';
import Auth from './ui/pages/Auth/Auth';
import Dashboard from './ui/pages/Dashboard/Dashboard';
import theme, { moreColors } from './ui/theme';
import { chains } from './utils/chains';
import { InMemoryCacheOptions } from './utils/graphql/apolloUtils/InMemoryCacheOptions';

const { trackPageview } = Plausible({
  domain: 'app.fluidkey.com, rollup.fluidkey',
  apiHost: 'https://sync.fluidkey.com',
});
trackPageview();

const isDev = process.env.REACT_APP_DEV_ENV === 'true';

const rpcUrl = isDev ? '-public.rpcdev.fluidkey.com' : '-public.rpc.fluidkey.com ';

const config = createConfig({
  chains: [mainnet, optimism, arbitrum, polygon, base, sepolia],
  transports: {
    [mainnet.id]: http('https://' + chains[mainnet.id].shortname + rpcUrl),
    [optimism.id]: http('https://' + chains[optimism.id].shortname + rpcUrl),
    [arbitrum.id]: http('https://' + chains[arbitrum.id].shortname + rpcUrl),
    [polygon.id]: http('https://' + chains[polygon.id].shortname + rpcUrl),
    [base.id]: http('https://' + chains[base.id].shortname + rpcUrl),
    [sepolia.id]: http('https://' + chains[sepolia.id].shortname + rpcUrl),
  },
});

const queryClient = new QueryClient();

const fluidkeyClient = new FluidkeyClient({
  clientId: isDev
    ? (process.env.REACT_APP_FLUIDKEY_ID_DEV as string)
    : (process.env.REACT_APP_FLUIDKEY_ID_PROD as string),
  clientKey: isDev
    ? (process.env.REACT_APP_FLUIDKEY_KEY_DEV as string)
    : (process.env.REACT_APP_FLUIDKEY_KEY_PROD as string),
  isExperimental: isDev,
});

// ----------------
// APOLLO CLIENT
// ----------------
const httpLink = new HttpLink({
  uri: fluidkeyClient.getGraphQlEndpointUrl(),
});
const link = from([httpLink]);
const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: InMemoryCacheOptions,
  }),
  link,
});

function App(): JSX.Element {
  const isDev = process.env.REACT_APP_DEV_ENV === 'true';
  useEffect(() => {
    document.title = isDev ? 'DEV Fluidkey' : 'Fluidkey';
  }, []);
  return (
    <HelmetProvider>
      <Helmet>
        <link rel="manifest" href={isDev ? `/devmanifest.json` : 'manifest.json'} />
      </Helmet>
      <BrowserRouter>
        <ApolloProvider client={apolloClient}>
          <PrivyProvider
            appId={process.env.REACT_APP_PRIVY_APP_ID as string}
            config={{
              embeddedWallets: {
                createOnLogin: 'users-without-wallets',
                noPromptOnSignature: true,
              },
              loginMethods: ['farcaster', 'google', 'email'],
              appearance: {
                showWalletLoginFirst: false,
                theme: moreColors.light2PaperBackground as `#${string}`,
                accentColor: '#CDEAF7',
              },
              legal: {
                termsAndConditionsUrl: 'https://fluidkey.com/terms',
                privacyPolicyUrl: 'https://fluidkey.com/privacy',
              },
              walletConnectCloudProjectId: process.env.REACT_APP_WEB3MODAL_ID as string,
              externalWallets: {
                coinbaseWallet: {
                  connectionOptions: 'eoaOnly',
                },
              },
            }}
          >
            <QueryClientProvider client={queryClient}>
              <WagmiProvider config={config}>
                <FluidkeyProvider client={fluidkeyClient}>
                  <ThemeProvider theme={theme}>
                    <MainProvider>
                      <CssBaseline />
                      <Routes>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/auth" element={<Auth />} />
                      </Routes>
                    </MainProvider>
                  </ThemeProvider>
                </FluidkeyProvider>
              </WagmiProvider>
            </QueryClientProvider>
          </PrivyProvider>
        </ApolloProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;

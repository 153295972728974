/**
 * Final transfer confirmation
 *
 * Asks the user to proceed before confirming the transaction and then shows a spinner while the transaction is being sent.
 */

import { ArrowRightAltRounded } from '@mui/icons-material';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useConfirmBridge, useConfirmSwap, useConfirmWithdrawal } from '@sefu/react-sdk';
import Plausible from 'plausible-tracker';
import { useEffect, useState } from 'react';
import { formatUnits } from 'viem';
import { useMain } from '../../../../../../context/MainContext';
import { useTransfer } from '../../../../../../context/TransferContext';
import { formatAmount } from '../../../../../../utils/formatAmount';
import CompressedAddress from '../../../../../atoms/Address/CompressedAddress';
import { Zorb } from '../../../../../atoms/Address/Zorb';
import Chain from '../../../../../atoms/Chains/Chain';
import Token from '../../../../../atoms/Token/Token';
import { gradient, moreColors } from '../../../../../theme';
import { type Token as SdkTokenType } from '@sefu/react-sdk';

export default function ConfirmTransfer(): JSX.Element {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const { chainId } = useMain();
  const { quote, swapQuote, bridgeQuote, setModalOpen, setToken, setError, sendToInput } =
    useTransfer();
  const { confirmWithdrawal, isLoading } = useConfirmWithdrawal();
  const { confirmSwap, isLoading: isSwapLoading } = useConfirmSwap();
  const { confirmBridge, isLoading: isBridgeLoading } = useConfirmBridge();
  const { trackEvent } = Plausible({
    domain: 'app.fluidkey.com, rollup.fluidkey',
    apiHost: 'https://sync.fluidkey.com',
  });

  const handleConfirm = (): void => {
    if (bridgeQuote?.bridgeProcedure[0]?.idProcedure != null) {
      void confirmBridge({
        idProcedure: bridgeQuote?.bridgeProcedure[0]?.idProcedure,
      });
      setIsConfirmed(true);
      trackEvent('Confirm Bridge');
    } else if (
      quote?.withdrawalProcedure?.idProcedure != null &&
      quote?.withdrawalProcedure?.quoteExpiresAt * 1000 > Date.now()
    ) {
      void confirmWithdrawal({
        idProcedure: quote.withdrawalProcedure?.idProcedure,
      });
      setIsConfirmed(true);
      trackEvent('Confirm Withdrawal');
    } else if (
      swapQuote?.swapProcedure[0]?.idProcedure != null &&
      swapQuote?.swapProcedure[0]?.quoteExpiresAt * 1000 > Date.now()
    ) {
      void confirmSwap({
        idProcedure: swapQuote?.swapProcedure[0]?.idProcedure,
      });
      setIsConfirmed(true);
      trackEvent('Confirm Swap');
    } else {
      setError('Transaction expired, please resubmit it.');
    }
  };

  useEffect(() => {
    if (isConfirmed && !isLoading && !isSwapLoading && !isBridgeLoading) {
      setModalOpen(false);
      setToken(undefined);
    }
  }, [isLoading, isSwapLoading, isBridgeLoading]);

  const amountFormatted =
    quote != null ? (
      <>
        {formatUnits(
          BigInt(quote?.withdrawalProcedure?.amount as string) ?? 0n,
          (quote?.withdrawalProcedure?.token?.decimals as number) ?? 18
        )}
        <Box component="span" display="inline-block" marginLeft={0.5} marginRight={0.5}>
          <Token token={quote?.withdrawalProcedure?.token as SdkTokenType} />
        </Box>
      </>
    ) : null;

  const amountFromFormatted =
    swapQuote != null ? (
      <>
        {formatAmount({
          amountTotalFormatted: formatUnits(
            BigInt(swapQuote?.swapProcedure[0]?.amount),
            swapQuote?.swapProcedure[0]?.tokenFrom?.decimals
          ),
          roundUp: true,
          decimals: 4,
        })}
        <Box component="span" display="inline-block" marginLeft={0.5} marginRight={0.5}>
          <Token token={swapQuote?.swapProcedure[0]?.tokenFrom} />
        </Box>{' '}
      </>
    ) : bridgeQuote != null ? (
      <>
        {formatAmount({
          amountTotalFormatted: formatUnits(
            BigInt(bridgeQuote?.bridgeProcedure[0]?.amount),
            bridgeQuote?.bridgeProcedure[0]?.tokenFrom?.decimals
          ),
          roundUp: true,
          decimals: 4,
        })}
        <Box component="span" display="inline-block" marginLeft={0.5} marginRight={0.5}>
          <Token token={bridgeQuote?.bridgeProcedure[0]?.tokenFrom} />
        </Box>{' '}
      </>
    ) : null;

  const amountToFormatted =
    swapQuote != null ? (
      <>
        {formatAmount({
          amountTotalFormatted: formatUnits(
            BigInt(swapQuote?.swapProcedure[0]?.minAmountReceived),
            swapQuote?.swapProcedure[0]?.tokenTo?.decimals
          ),
          roundUp: true,
          decimals: 4,
        })}
        <Box component="span" display="inline-block" marginLeft={0.5} marginRight={0.5}>
          <Token token={swapQuote?.swapProcedure[0]?.tokenTo} />
        </Box>{' '}
      </>
    ) : bridgeQuote != null ? (
      <>
        {formatAmount({
          amountTotalFormatted: formatUnits(
            BigInt(bridgeQuote?.bridgeProcedure[0]?.minAmountReceived),
            bridgeQuote?.bridgeProcedure[0]?.tokenTo?.decimals
          ),
          roundUp: true,
          decimals: 4,
        })}
        <Box component="span" display="inline-block" marginLeft={0.5} marginRight={0.5}>
          <Token token={bridgeQuote?.bridgeProcedure[0]?.tokenTo} />
        </Box>{' '}
      </>
    ) : null;

  const toAddress =
    quote?.withdrawalProcedure?.toAddress ??
    swapQuote?.swapProcedure[0]?.toAddress ??
    bridgeQuote?.bridgeProcedure[0]?.toAddress;

  const fees =
    quote != null
      ? formatAmount({
          amountTotalFormatted: formatUnits(
            BigInt(quote?.withdrawalProcedure?.relayFee as string) +
              BigInt(quote?.withdrawalProcedure?.serviceFee as string),
            quote?.withdrawalProcedure?.tokenForFee?.decimals as number
          ),
          roundUp: true,
          decimals: 4,
        })
      : swapQuote != null
      ? formatAmount({
          amountTotalFormatted: formatUnits(
            BigInt(swapQuote?.swapProcedure[0]?.relayFee) +
              BigInt(swapQuote?.swapProcedure[0]?.serviceFee),
            swapQuote?.swapProcedure[0]?.tokenForFee?.decimals
          ),
          roundUp: true,
          decimals: 4,
        })
      : bridgeQuote != null
      ? formatAmount({
          amountTotalFormatted: formatUnits(
            BigInt(bridgeQuote?.bridgeProcedure[0]?.relayFee) +
              BigInt(bridgeQuote?.bridgeProcedure[0]?.serviceFee),
            bridgeQuote?.bridgeProcedure[0]?.tokenForFee?.decimals
          ),
          roundUp: true,
          decimals: 4,
        })
      : null;

  const feeToken =
    quote?.withdrawalProcedure?.tokenForFee?.symbol ??
    swapQuote?.swapProcedure[0]?.tokenForFee?.symbol ??
    bridgeQuote?.bridgeProcedure[0]?.tokenForFee?.symbol;

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        width="100%"
        minWidth="300px"
        maxWidth="480px"
        mb={4}
        py={1}
        sx={{
          borderStyle: 'solid',
          borderColor: moreColors.lightPaperBackground,
          borderWidth: '1px',
          borderRadius: '10px',
        }}
      >
        <Typography
          variant="h6"
          component="div"
          width="100%"
          minWidth="300px"
          maxWidth="480px"
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <Box
            ml={0.75}
            sx={{
              fontWeight: 'normal',
            }}
          >
            Sending
          </Box>
          <Box component="span">
            <Box component="span" display="inline-block" marginLeft={1} marginRight={0.5}>
              {quote != null ? (
                amountFormatted
              ) : (
                <Box component="span" display="flex" alignItems="center">
                  {amountFromFormatted}
                  <Box component="span" display="flex" alignItems="center" mr={0.5}>
                    <ArrowRightAltRounded fontSize="small" />
                  </Box>
                  {amountToFormatted}
                </Box>
              )}
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" marginLeft={0.5} marginRight={0.75}>
            <Box
              component="span"
              mr={1}
              sx={{
                fontWeight: 'normal',
              }}
            >
              to
            </Box>
            <Box height="0.65em" width="0.65em" mr="4px">
              <Zorb address={toAddress as `0x${string}`} />
            </Box>
            {sendToInput.length < 40 ? (
              <Typography
                component="span"
                variant="inherit"
                display="flex"
                alignItems="center"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  maxWidth: '100%',
                }}
              >
                {sendToInput}
              </Typography>
            ) : (
              <CompressedAddress address={toAddress as `0x${string}`} characters={12} />
            )}
          </Box>
          <Box display="flex" flexDirection="row" marginLeft={0.25} marginRight={0.25}>
            <Box
              display="inline-flex"
              alignItems="center"
              sx={{
                fontWeight: 'normal',
              }}
            >
              {bridgeQuote != null ? 'from' : 'on'}
              <Typography
                component="div"
                variant="inherit"
                ml={1}
                mr={1}
                display="flex"
                alignItems="center"
              >
                <Chain chainId={chainId} />
                {bridgeQuote != null ? (
                  <Box component="span" display="flex" marginLeft={0.5} marginRight={0.5}>
                    <Typography
                      component="span"
                      variant="inherit"
                      ml={0.25}
                      mr={1}
                      display="flex"
                      alignItems="center"
                    >
                      to
                    </Typography>
                    <Chain chainId={bridgeQuote?.bridgeProcedure[0]?.chainIdTo} />
                  </Box>
                ) : null}
              </Typography>
            </Box>
          </Box>
        </Typography>
        <Typography component="div" variant="body2" color="text.secondary" lineHeight={1} mb={1}>
          <br />
          Fees: {fees} {feeToken}
        </Typography>
      </Box>
      {isLoading || isSwapLoading || isBridgeLoading ? (
        <CircularProgress size="2rem" />
      ) : (
        <Button
          size="medium"
          variant="contained"
          sx={{
            backgroundImage: gradient,
            textTransform: 'none',
            fontWeight: 700,
          }}
          onClick={handleConfirm}
        >
          Proceed?
        </Button>
      )}
    </>
  );
}

/**
 * Auth PIN Input
 *
 * Set up (new) or collection (existing) of the user PIN.
 */

import { ArrowRightAltRounded } from '@mui/icons-material';
import { Alert, Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useIsAddressRegistered } from '@sefu/react-sdk';
import Plausible from 'plausible-tracker';
import { useEffect } from 'react';
import { useAccount, useDisconnect } from 'wagmi';
import { useAuth } from '../../../context/AuthContext';
import PinInput from '../../atoms/Input/PinInput';
import { gradient } from '../../theme';
import { usePrivy } from '@privy-io/react-auth';

export default function Pin(): JSX.Element {
  const { address } = useAccount();
  const { disconnect } = useDisconnect();
  const { isAddressRegistered } = useIsAddressRegistered(address);
  const {
    setStage,
    pin,
    setPin,
    backupPin,
    setBackupPin,
    setConnectActionCompleted,
    showPinIssue,
  } = useAuth();
  const { logout } = usePrivy();
  const { trackEvent } = Plausible({
    domain: 'app.fluidkey.com, rollup.fluidkey',
    apiHost: 'https://sync.fluidkey.com',
  });

  // Move to key generation stage when PIN is set
  useEffect(() => {
    if (pin.every(c => c !== '') && isAddressRegistered) {
      setStage('generateKeys');
    }
  }, [pin]);

  const handleSetPin = (): void => {
    if (pin.every(c => c !== '')) {
      setStage('generateKeys');
    }
  };

  // Track auth stage
  useEffect(() => {
    trackEvent('PIN');
  }, []);

  return (
    <>
      <Typography variant="body1" color="text.primary" mb={2}>
        {isAddressRegistered ? 'Enter your PIN.' : 'Set your PIN.'}
      </Typography>
      <Typography variant="body2" color="text.secondary" mb={2} textAlign="center">
        A PIN gives you extra protection against phishing attacks.
      </Typography>
      <PinInput setPin={setPin} pin={pin} />
      {!isAddressRegistered ? (
        <Box display="flex" flexDirection="column" alignItems="center" mt={1}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                size="small"
                checked={backupPin}
                onChange={e => {
                  setBackupPin(e.target.checked);
                }}
              />
            }
            label={
              <Typography variant="body2" color="text.secondary" textAlign="center">
                Backup the PIN with Fluidkey
              </Typography>
            }
            sx={{ marginBottom: 2, marginRight: 0 }}
            labelPlacement="end"
            classes={{
              label: 'MuiTypography-body2 !important',
            }}
          />
          <Button
            variant="contained"
            size="medium"
            onClick={handleSetPin}
            endIcon={<ArrowRightAltRounded />}
            sx={{
              background: gradient,
              textTransform: 'none',
              fontWeight: 700,
            }}
          >
            Set PIN
          </Button>
        </Box>
      ) : null}
      {showPinIssue ? <Alert severity="warning">Wrong PIN, please try again.</Alert> : null}
      <Button
        size="small"
        sx={{ color: 'text.secondary', opacity: 0.7, marginTop: 2 }}
        onClick={() => {
          disconnect();
          void logout();
          setConnectActionCompleted(false);
          setStage('connect');
        }}
      >
        Back
      </Button>
    </>
  );
}

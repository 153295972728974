/**
 * Auth Context
 *
 * This context handles the authentication flow.
 */

import { useIsAddressRegistered, useResetClient } from '@sefu/react-sdk';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAccount, useDisconnect } from 'wagmi';

// Auth stages
type stageType = 'connect' | 'pin' | 'accessCode' | 'generateKeys' | 'register' | 'loading';

interface AuthContextProps {
  stage: stageType;
  setStage: React.Dispatch<React.SetStateAction<stageType>>;
  pin: string[];
  setPin: React.Dispatch<React.SetStateAction<string[]>>;
  accessCode: string[];
  setAccessCode: React.Dispatch<React.SetStateAction<string[]>>;
  error: string | undefined;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
  backupPin: boolean;
  setBackupPin: React.Dispatch<React.SetStateAction<boolean>>;
  connectActionCompleted: boolean;
  setConnectActionCompleted: React.Dispatch<React.SetStateAction<boolean>>;
  showPinIssue: boolean;
  setShowPinIssue: React.Dispatch<React.SetStateAction<boolean>>;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);
  if (context == null) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

interface AuthProviderProps {
  children?: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  // Wagmi hooks
  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  // Fluidkey hooks
  const { isAddressRegistered, isLoading } = useIsAddressRegistered(address);
  const { reset } = useResetClient();
  // Auth context states
  const [stage, setStage] = useState<stageType>('connect');
  const [pin, setPin] = useState<string[]>(['', '', '', '']);
  const [accessCode, setAccessCode] = useState<string[]>(['A', 'C', 'C', 'E', 'S', 'S']);
  const [error, setError] = useState<string | undefined>(undefined);
  const [backupPin, setBackupPin] = useState<boolean>(false);
  const [connectActionCompleted, setConnectActionCompleted] = useState<boolean>(false);
  const [showPinIssue, setShowPinIssue] = useState<boolean>(false);

  // Handle wallet connection, disconnection and according resets
  useEffect(() => {
    if (address == null || !isConnected || !connectActionCompleted) {
      console.log('disconnecting');
      setStage('connect');
      reset();
    } else {
      setPin(['', '', '', '']);
      setError(undefined);
      setShowPinIssue(false);
      if (isLoading) {
        setStage('loading');
      } else {
        setStage('pin');
      }
    }
  }, [isAddressRegistered, address, isConnected, isLoading, connectActionCompleted]);

  // Reset to connect stage on error
  useEffect(() => {
    if (error != null) {
      setPin(['', '', '', '']);
      setStage('connect');
      disconnect();
    }
  }, [error]);

  return (
    <AuthContext.Provider
      value={{
        stage,
        setStage,
        pin,
        setPin,
        accessCode,
        setAccessCode,
        error,
        setError,
        backupPin,
        setBackupPin,
        connectActionCompleted,
        setConnectActionCompleted,
        showPinIssue,
        setShowPinIssue,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

import { Typography, Alert } from '@mui/material';
import { useFluidkeyClient, useIsSecretValid, type FluidkeyClient } from '@sefu/react-sdk';
import { useEffect } from 'react';
import { useTransfer } from '../../../../../../context/TransferContext';
import PinInput from '../../../../../atoms/Input/PinInput';

export default function PinTransfer(): JSX.Element {
  const { setStage, pin, setPin, setError, showPinIssue } = useTransfer();
  const { areMetaStealthKeysInitialized } = useFluidkeyClient() as FluidkeyClient;
  const { check } = useIsSecretValid();

  // Move to key generation stage when PIN is set
  useEffect(() => {
    if (pin.every(c => c !== '')) {
      const keysInitialized = areMetaStealthKeysInitialized();
      const isSecretValid = check(pin.join(''));
      if (keysInitialized && isSecretValid === true) {
        setStage('confirm');
      } else if (!keysInitialized) {
        setStage('generateKeys');
      } else {
        setError('Invalid PIN');
      }
    }
  }, [pin, check]);

  return (
    <>
      <Typography variant="h6" color="text.primary" mb={2}>
        Enter your PIN
      </Typography>
      <PinInput setPin={setPin} pin={pin} />
      {showPinIssue ? <Alert severity="warning">Wrong PIN, please try again.</Alert> : null}
    </>
  );
}

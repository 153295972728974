import { Box, TextField } from '@mui/material';
import { useIsAddressRegistered } from '@sefu/react-sdk';
import React, { useState } from 'react';
import { useAccount } from 'wagmi';
interface PinInputProps {
  pin: string[];
  setPin: React.Dispatch<React.SetStateAction<string[]>>;
  numeric?: boolean;
}

export default function PinInput({ pin, setPin, numeric = true }: PinInputProps): JSX.Element {
  const { address } = useAccount();
  const { isAddressRegistered } = useIsAddressRegistered(address);
  const [displayPin, setDisplayPin] = useState<string[]>(new Array(pin.length).fill(''));

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ): void => {
    let value = e.target.value;

    if ((numeric && /[^0-9]/.test(value)) || value === ' ') {
      return;
    }

    value = value.toUpperCase();

    pin[index] = value;
    displayPin[index] = '*';

    if (index < pin.length - 1 && value !== '') {
      const nextInput = document.getElementById(`pin-input-${index + 1}`);
      if (nextInput !== null) {
        nextInput.focus();
      }
    }

    setPin([...pin]);
    setDisplayPin([...displayPin]);
  };

  const handleKeyDown = (e: { key: string }, index: number): void => {
    if (e.key === 'Backspace') {
      if (pin[index] === '' && index > 0) {
        const prevInput = document.getElementById(`pin-input-${index - 1}`);
        if (prevInput !== null) {
          pin[index] = '';
          displayPin[index] = '';
          pin[index - 1] = '';
          displayPin[index - 1] = '';
          setPin([...pin]);
          setDisplayPin([...displayPin]);
          prevInput.focus();
        }
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>, startIndex: number): void => {
    e.preventDefault();

    const pasteData = e.clipboardData.getData('text');

    const upperPasteData = numeric
      ? pasteData.replace(/[^0-9]/g, '').toUpperCase()
      : pasteData.toUpperCase();

    for (let i = 0; i < upperPasteData.length && i + startIndex < pin.length; i++) {
      pin[i + startIndex] = upperPasteData[i];
    }

    const nextInput = document.getElementById(
      `pin-input-${Math.min(startIndex + upperPasteData.length, pin.length - 1)}`
    );
    if (nextInput !== null) {
      nextInput.focus();
    }

    setPin([...pin]);
  };

  return (
    <Box display="flex" justifyContent="center">
      {pin.map((_, index) => (
        <TextField
          id={`pin-input-${index}`}
          key={index}
          variant="outlined"
          type={numeric ? 'tel' : 'text'}
          inputMode={numeric ? 'numeric' : 'text'}
          inputProps={{ maxLength: 1 }}
          value={isAddressRegistered ? displayPin[index] : pin[index] ?? ''}
          autoComplete="off"
          onChange={e => {
            handleInputChange(e, index);
          }}
          onKeyDown={e => {
            handleKeyDown(e, index);
          }}
          onPaste={e => {
            handlePaste(e, index);
          }}
          autoFocus={index === 0}
          sx={{
            width: pin.length > 4 ? '2.5em' : '3em',
            margin: pin.length > 4 ? 0.5 : 1,
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            '& input': {
              textAlign: 'center',
              height: '1.1em',
            },
            '.MuiInputBase-input': {
              paddingX: pin.length > 4 ? 0.5 : 1,
            },
          }}
        />
      ))}
    </Box>
  );
}

import React, { useMemo } from 'react';
import { zorbImageDataURI } from '@zoralabs/zorb';

export const Zorb = ({ address }: { address: string }): JSX.Element => {
  if (address === undefined) {
    return <></>;
  }
  // if address doesn't start by 0x add it
  if (!address.startsWith('0x')) {
    address = `0x${address}`;
  }
  // if address contains non hex characters, replace them with their hex equivalent
  if (!/^(0x)?[0-9a-fA-F]+$/.test(address)) {
    address = `0x${Buffer.from(address).toString('hex')}`;
  }

  // if address is too short, repeat it until it's 42 characters long
  if (address.length < 42) {
    address = address.padEnd(42, address.slice(-1));
  }

  const zorbImage = useMemo(() => zorbImageDataURI(address), [address]);
  return <img src={zorbImage} />;
};

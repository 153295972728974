/**
 * Displays user ENS and hex stealth addresses to receive funds.
 *
 * TO-DO: adjust the fkey username based on the chain
 */

import { ContentCopyRounded, LoopRounded, OpenInNewRounded } from '@mui/icons-material';
import { Box, Button, Link, Skeleton, Typography } from '@mui/material';
import {
  useGenerateStealthAddress,
  useGetSmartAccount,
  useGetUserSmartAccounts,
  type SmartAccount,
} from '@sefu/react-sdk';
import Plausible from 'plausible-tracker';
import { useEffect, useRef, useState } from 'react';
import User from '../../atoms/Address/Address';
import ReceiveAlert from '../../atoms/TooltipsAlerts/ReceiveAlert';
import ReceiveTooltip from '../../atoms/TooltipsAlerts/ReceiveTooltip';

export default function Receive(): JSX.Element {
  const isDev = process.env.REACT_APP_DEV_ENV === 'true';
  const { smartAccountList = [] } = useGetUserSmartAccounts();
  const { smartAccount } = useGetSmartAccount({
    idSmartAccount: smartAccountList[0]?.idSmartAccount,
  });
  const { stealthAddressCreated, generateNewStealthAddress } = useGenerateStealthAddress({
    chainId: 0,
    idSmartAccount: (smartAccount as SmartAccount)?.idSmartAccount,
  });
  const { trackEvent } = Plausible({
    domain: 'app.fluidkey.com, rollup.fluidkey',
    apiHost: 'https://sync.fluidkey.com',
  });
  const [stealthAddressQueue, setStealthAddressQueue] = useState<Array<`0x${string}`>>([]);
  const [userImported, setUserImported] = useState(false);
  const [copied, setCopied] = useState<string>('');
  const [displayType, setDisplayType] = useState<'link' | 'ens' | 'hex'>('link');
  const [newHex, setNewHex] = useState<boolean>(false);
  const lastCallTimeRef = useRef<number>(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

  // Prepare the .id link and the .eth ENS
  const link = `${smartAccount?.username}.fkey${isDev ? 'dev' : ''}.id`;
  const ens = `${smartAccount?.username}.fkey${isDev ? 'dev' : ''}.eth`;

  // Every time the user toggles back to the Address view, generate a new stealth address and make sure the queue is full
  useEffect(() => {
    const now = Date.now();
    const timeSinceLastCall = now - lastCallTimeRef.current;

    if (timeSinceLastCall >= 3000 || lastCallTimeRef.current === 0) {
      if (userImported) {
        if (stealthAddressQueue.length < 4 && stealthAddressQueue.length > 1) {
          void generateNewStealthAddress();
        }
      }
      if (stealthAddressQueue.length > 1) {
        setStealthAddressQueue(stealthAddressQueue.slice(1));
        trackEvent('Generate New Hex Address');
        setIsButtonDisabled(true);
        setTimeout(() => {
          setIsButtonDisabled(false);
        }, 3000);
      }
      lastCallTimeRef.current = now;
    }
  }, [newHex]);

  // Add the new stealth address to the queue (the queue acts as a buffer for instant rendering of hex stealth addresses)
  useEffect(() => {
    if (stealthAddressCreated?.address !== undefined) {
      setStealthAddressQueue(prev => {
        const newQueue = [...prev, stealthAddressCreated.address];
        return newQueue;
      });
    }
  }, [stealthAddressCreated]);

  // Generate two new stealth addresses when the user's smart account is activated
  useEffect(() => {
    if ((smartAccount as SmartAccount)?._activationStatus === 'ACTIVATED') {
      setStealthAddressQueue([]);
      void generateNewStealthAddress();
      void generateNewStealthAddress();
      setUserImported(true);
    }
  }, [smartAccount]);

  // Copy the ENS or hex stealth address to the clipboard
  const handleCopy = (): void => {
    if (displayType === 'ens') {
      void navigator.clipboard.writeText(ens).then(() => {
        setCopied('ens');
        trackEvent('Copy ENS');
      });
    } else if (displayType === 'hex') {
      void navigator.clipboard.writeText(stealthAddressQueue[0] as string).then(() => {
        setCopied('hex');
        trackEvent('Copy Address');
      });
    } else if (displayType === 'link') {
      void navigator.clipboard.writeText(link).then(() => {
        setCopied('link');
        trackEvent('Copy Link');
      });
    }
  };

  // Remove the copied alert after 10 seconds
  useEffect(() => {
    if (copied !== '') {
      setTimeout(() => {
        setCopied('');
      }, 10000);
    }
  }, [copied]);

  return (
    <Box className="receive" width="100%" maxWidth="sm" mb={2}>
      <Typography
        variant="body2"
        color="text.secondary"
        mb={0.75}
        textAlign="left"
        maxWidth="sm"
        width="100%"
        px="2vh"
      >
        Receive
      </Typography>
      <Box display="flex" maxWidth="sm" width="100%">
        <Box
          maxWidth="sm"
          width="100%"
          bgcolor="background.paper"
          py="2vh"
          px="2vh !important"
          mb="1vh"
          borderRadius="10px"
          boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
          justifyContent="space-between"
          display="flex"
          alignItems="center"
        >
          <Box>
            <Box display="flex" alignItems="center">
              <ReceiveTooltip displayType={displayType} setDisplayType={setDisplayType} />
            </Box>
            <Box display="flex" alignItems="center" height="25px" ml={0.25}>
              {userImported && smartAccountList?.length !== 0 && smartAccount?.username != null ? (
                displayType === 'ens' ? (
                  <Typography
                    variant="h6"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    maxWidth="60vw"
                  >
                    {ens}
                  </Typography>
                ) : displayType === 'hex' ? (
                  <Typography
                    component="div"
                    variant="h6"
                    display="flex"
                    alignItems="center"
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {stealthAddressQueue[0] !== undefined ? (
                      <Box display="flex">
                        <User address={stealthAddressQueue[0]} />
                      </Box>
                    ) : null}
                  </Typography>
                ) : (
                  <Box>
                    <Box display="flex" alignItems="flex-end">
                      <Box>
                        <Typography
                          variant="h6"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          whiteSpace="nowrap"
                          maxWidth="60vw"
                        >
                          {link}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" justifyContent="center" mb={0.05}>
                        <Link
                          href={`https://${link}`}
                          target="_blank"
                          ml={1}
                          variant="body1"
                          sx={{ color: 'text.secondary' }}
                        >
                          <OpenInNewRounded fontSize="inherit" />
                        </Link>
                      </Box>
                    </Box>
                  </Box>
                )
              ) : (
                <Skeleton
                  variant="text"
                  animation="wave"
                  width="120px"
                  sx={{ fontsize: '1.5rem' }}
                />
              )}
            </Box>
          </Box>
          <Box justifyContent="center" display="flex" alignItems="center">
            {displayType === 'hex' ? (
              <Button
                variant="text"
                size="small"
                sx={{
                  color: 'text.primary',
                  minWidth: 'unset',
                  width: '30px',
                  height: '30px',
                  marginRight: 1,
                }}
                disabled={isButtonDisabled}
                onClick={() => {
                  setNewHex(!newHex);
                  setCopied('');
                }}
              >
                <LoopRounded sx={{ width: '25px', height: '25px' }} />
              </Button>
            ) : null}
            <Button
              variant="text"
              size="small"
              sx={{
                color: 'text.primary',
                minWidth: 'unset',
                width: '30px',
                height: '30px',
              }}
              disabled={
                !userImported || (displayType === 'hex' && stealthAddressQueue.length === 0)
              }
              onClick={handleCopy}
            >
              <ContentCopyRounded sx={{ width: '25px', height: '25px' }} />
            </Button>
          </Box>
        </Box>
      </Box>
      {copied !== '' ? (
        <ReceiveAlert displayType={displayType} address={stealthAddressQueue[0]} />
      ) : null}
    </Box>
  );
}

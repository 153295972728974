/**
 * Balance of a specific token in the dashboard
 *
 * Displays the balance of a token in the dashboard and formats it in a human readable way
 */

import { CloseRounded, NorthEastRounded } from '@mui/icons-material';
import { Box, Button, Skeleton, Tooltip, Typography } from '@mui/material';
import { useGetSmartAccountBalanceByFilter, useGetUserSmartAccounts } from '@sefu/react-sdk';
import Plausible from 'plausible-tracker';
import React, { useEffect } from 'react';
import { formatUnits } from 'viem';
import { useMain } from '../../../../context/MainContext';
import { useTransfer } from '../../../../context/TransferContext';
import { formatAmount } from '../../../../utils/formatAmount';
import { type TokenType } from '../../../../utils/tokenType';
import Token from '../../../atoms/Token/Token';
import SendParent from './Send/Input/SendParent';
import { type Token as SdkTokenType } from '@sefu/react-sdk';

export interface TokenBalanceProps {
  name: TokenType;
  amount: string;
  amountOnHold: string;
  decimals: number;
  isLoading: boolean;
  idToken: string;
  amountUsd: number;
  tokenData: SdkTokenType;
}

function TokenBalance({
  name,
  amount,
  amountOnHold,
  decimals,
  isLoading,
  idToken,
  amountUsd,
  tokenData,
}: TokenBalanceProps): JSX.Element {
  const { trackEvent } = Plausible({
    domain: 'app.fluidkey.com, rollup.fluidkey',
    apiHost: 'https://sync.fluidkey.com',
  });
  const {
    token,
    setToken,
    setIdToken,
    setDecimals,
    setBalance,
    setBalanceOnHold,
    setToChain,
    selectedLabels,
  } = useTransfer();
  const { chainId } = useMain();
  const amountTotal = BigInt(amount) - BigInt(amountOnHold);
  const amountTotalFormatted = formatUnits(amountTotal, decimals);
  const { smartAccountList } = useGetUserSmartAccounts();
  const { data, fetch } = useGetSmartAccountBalanceByFilter({
    idSmartAccount: smartAccountList?.[0]?.idSmartAccount as string,
    chainId,
  });
  // Filter the balance when labels are selected
  useEffect(() => {
    if (selectedLabels.length > 0) {
      void fetch({
        idLabels: selectedLabels.map(label => label.idLabel),
      });
    }
  }, [selectedLabels]);
  const filteredData = data?.find(item => item.token.idToken === idToken);
  const filteredAmount = filteredData?.amount;
  const filteredUsdAmount = filteredData?.amountUsd;
  const filteredAmountOnHold = data?.find(item => item.token.idToken === idToken)?.amountOnHold;
  const filteredAmountTotal =
    filteredAmount != null && filteredAmountOnHold != null
      ? BigInt(filteredAmount) - BigInt(filteredAmountOnHold)
      : undefined;
  const filteredAmountTotalFormatted =
    filteredAmountTotal != null ? formatUnits(filteredAmountTotal, decimals) : undefined;

  // Format the amount to display it in a human readable way
  const amountFormattedRounded = formatAmount({ amountTotalFormatted });
  const filteredAmountFormattedRounded =
    filteredAmountTotal != null
      ? formatAmount({ amountTotalFormatted: filteredAmountTotalFormatted ?? '0' })
      : undefined;

  // When this token's input is open, pass token and balance data to the context
  useEffect(() => {
    if (token === tokenData) {
      setIdToken(idToken);
      setDecimals(decimals);
      setBalance(BigInt(amount));
      setBalanceOnHold(BigInt(amountOnHold));
      setToChain(chainId);
    }
  }, [token, amount, amountOnHold]);

  return (
    <Box
      maxWidth="sm"
      bgcolor="background.paper"
      py="1vh"
      px="2vh"
      mb="1vh"
      borderRadius="10px"
      boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
      key={idToken}
    >
      <Box
        justifyContent="space-between"
        display="flex"
        alignItems="center"
        ml={0.25}
        maxWidth="100%"
      >
        <Box>
          <Typography
            variant={token === tokenData ? 'h5' : 'h6'}
            fontWeight={600}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
            mb={-0.75}
          >
            {isLoading ? (
              <Skeleton variant="text" width="50px" height="25px" sx={{ marginRight: 1 }} />
            ) : (
              <Tooltip title={filteredAmountTotalFormatted ?? amountTotalFormatted} placement="top">
                <Box mr={1}>
                  {selectedLabels.length === 0 || token !== tokenData
                    ? amountFormattedRounded
                    : filteredAmountFormattedRounded ?? amountFormattedRounded}
                </Box>
              </Tooltip>
            )}
            <Token token={tokenData} />
          </Typography>
          {isLoading ? (
            <Skeleton variant="text" width="40px" height="18px" />
          ) : (
            <Typography variant="body2" color="text.secondary">
              $
              {amountUsd !== 0
                ? formatAmount({
                    amountTotalFormatted: filteredUsdAmount?.toString() ?? amountUsd.toString(),
                    decimals: 2,
                  })
                : '-'}
            </Typography>
          )}
        </Box>
        <Box>
          <Button
            variant="text"
            size="small"
            sx={{
              color: 'text.primary',
              minWidth: 'unset',
              width: '25px',
              height: '25px',
            }}
            onClick={() => {
              setToken(token !== tokenData ? tokenData : undefined);
              if (token !== tokenData) {
                trackEvent('Open Send');
              }
            }}
          >
            {token === tokenData ? (
              <CloseRounded sx={{ color: 'text.primary' }} />
            ) : (
              <NorthEastRounded
                sx={{
                  color: 'text.primary',
                }}
              />
            )}
          </Button>
        </Box>
      </Box>
      {token === tokenData ? <SendParent /> : null}
    </Box>
  );
}

export default React.memo(TokenBalance);

import { TypePolicies } from '@apollo/client';

/**
 * Since we use custom IDs, for each object added, we define the custom ID here for that type of object, to avoid
 * issues with the cache. See https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-cache-ids
 */
export const InMemoryCacheOptions: TypePolicies | undefined = {
  Owner: {
    keyFields: ['idOwner'],
  },
};

import { Box, CircularProgress, Typography } from '@mui/material';
import { useAuthenticate, useGenerateKeys } from '@sefu/react-sdk';
import { useEffect, useState } from 'react';
import { useWalletClient } from 'wagmi';
import { useTransfer } from '../../../../../../context/TransferContext';
import { useMain } from '../../../../../../context/MainContext';

export default function KeysTransfer(): JSX.Element {
  const { generateKeys, keys, error, errorInfo } = useGenerateKeys();
  const {
    authenticate,
    error: authError,
    isLoading: authIsLoading,
    errorInfo: authErrorInfo,
  } = useAuthenticate();
  const { data: walletClient, status } = useWalletClient();
  const { setPin, pin, setStage, setError, setShowPinIssue } = useTransfer();
  const { privyUsername } = useMain();
  const [keyGenerationCalled, setKeyGenerationCalled] = useState<boolean>(false);
  const [authenticationCalled, setAuthenticationCalled] = useState<boolean>(false);

  // Generate keys
  useEffect(() => {
    if (status === 'success') {
      void generateKeys(walletClient, pin.join('')).then(() => {
        setKeyGenerationCalled(true);
      });
    }
  }, [status]);

  // Authenticate the user
  useEffect(() => {
    if (keys != null && keyGenerationCalled) {
      void authenticate().then(() => {
        setAuthenticationCalled(true);
      });
    }
  }, [keys]);

  // Move to confirm stage
  useEffect(() => {
    if (authenticationCalled && !authIsLoading) {
      setStage('confirm');
    }
  }, [authenticationCalled, authIsLoading]);

  // Key generation error handling
  useEffect(() => {
    if (errorInfo?._code === 'GENERIC_ERROR') {
      setError('Sorry, an error occurred. Please try again or contact us if the issue persists.');
    } else {
      setError(error);
    }
  }, [error]);

  // Authentication error handling
  useEffect(() => {
    if (authError === 'Signer public key not recognized') {
      location.reload();
    }
    if (authError != null) {
      setStage('pin');
      setPin(['', '', '', '']);
      if (authErrorInfo?._code === 'ERR_006') {
        setShowPinIssue(true);
        return;
      }
      if (authErrorInfo?._code === 'GENERIC_ERROR') {
        setError('Sorry, an error occurred. Please try again or contact us if the issue persists.');
      } else {
        setError(authError);
      }
    }
  }, [authError]);

  return (
    <Box
      minWidth="300px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box mt={2} mb={1} maxWidth={1}>
        <CircularProgress />
      </Box>
      {privyUsername != null ? (
        <Typography variant="body1" color="text.primary" mb={2} textAlign="center" minWidth="300px">
          Preparing your transfer...
        </Typography>
      ) : (
        <Typography variant="body1" color="text.primary" mb={2} textAlign="center" minWidth="300px">
          Sign the private payment key generation message with your wallet.
        </Typography>
      )}
    </Box>
  );
}

/**
 * Token input field for sending funds
 */

import {
  Autocomplete,
  Box,
  TextField,
  createFilterOptions,
  Popper,
  Typography,
  Paper,
} from '@mui/material';
import Token from '../../../../../atoms/Token/Token';
import { type SupportedToken, useGetSupportedTokens } from '@sefu/react-sdk';
import { useTransfer } from '../../../../../../context/TransferContext';
import React, { useState } from 'react';

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option: SupportedToken) =>
    (option?.aliasSymbol ?? option?.symbol) +
    ' ' +
    (option?.aliasName ?? option?.name) +
    ' ' +
    option?.address,
});

function TokenInput(): JSX.Element {
  const { toToken, setToToken, toChain } = useTransfer();
  const { data: toTokenData } = useGetSupportedTokens({ chainId: toChain });
  const [input, setInput] = useState('');

  return (
    <Box display="flex" alignItems="center" height="auto" minHeight="1.4375em" key={toChain}>
      <Autocomplete
        multiple
        options={toTokenData.whitelistTokens}
        noOptionsText="No tokens"
        getOptionLabel={option =>
          (option?.aliasSymbol ?? option?.symbol) + ' ' + (option?.aliasName ?? option?.name)
        }
        onChange={(event, newValue, reason) => {
          if (reason === 'clear') {
            setToToken(undefined);
          }
          setToToken(newValue[newValue.length - 1]);
        }}
        inputValue={input}
        onInputChange={(event, newInputValue) => {
          setInput(newInputValue);
        }}
        renderOption={(props, option, { selected }) =>
          option != null ? (
            <li
              key={option.address}
              {...props}
              style={{ paddingLeft: '8px', paddingRight: '8px', minHeight: '0px' }}
            >
              <Token token={option} />
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  marginLeft: '4px',
                  flexWrap: 'nowrap',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                component="span"
              >
                {option.aliasName ?? option.name}
              </Typography>
            </li>
          ) : (
            <></>
          )
        }
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) =>
            option != null ? (
              <Box
                ml={0.25}
                display="flex"
                flexWrap="nowrap"
                alignItems="center"
                key={option.address}
              >
                <Typography variant="body2" component="span">
                  <Token token={option} {...getTagProps({ index })} />
                </Typography>
              </Box>
            ) : (
              <></>
            )
          )
        }
        filterOptions={(options, params) => {
          if (options.length === 0) {
            return [];
          }
          if (params.inputValue === '') {
            const filtered = options.filter(
              option =>
                (option?.symbol === 'ETH' ||
                  option?.symbol === 'USDC' ||
                  option?.symbol === 'USDT' ||
                  option?.symbol === 'DAI' ||
                  option?.symbol === 'WETH' ||
                  (option?.symbol === 'MATIC' && toChain === 137) ||
                  (option?.symbol === 'xDAI' && toChain === 100)) &&
                option?.aliasSymbol == null
            );
            // sort filtered by option.marketcap
            filtered.sort((a, b) => {
              if (a.marketCap === b.marketCap) {
                return 0;
              }
              return a.marketCap > b.marketCap ? -1 : 1;
            });
            return filtered;
          }
          const filtered = filterOptions(options, params);
          filtered.sort((a, b) => {
            if (a.marketCap === b.marketCap) {
              return 0;
            }
            return a.marketCap > b.marketCap ? -1 : 1;
          });
          return filtered.slice(0, 5);
        }}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={toToken != null ? '' : 'token'}
            variant="outlined"
            type="text"
            margin="dense"
            size="small"
            color="primary"
            sx={{
              height: 'fit-content',
            }}
          />
        )}
        forcePopupIcon={false}
        PopperComponent={({ children, ...props }) => (
          <Popper {...props} style={{ width: '200px' }} placement="bottom-start">
            {toToken != null ? null : children}
          </Popper>
        )}
        PaperComponent={({ children, ...props }) => (
          <Paper {...props}>
            {children}
            <Typography
              variant="body2"
              color="text.secondary"
              width="100%"
              textAlign="center"
              fontStyle="italic"
              mt={-0.75}
              mb={1}
            >
              Type to find more
            </Typography>
          </Paper>
        )}
        sx={{
          '.MuiAutocomplete-root': {
            paddingRight: '20px',
          },
          '.MuiFormControl-root': {
            height: '100%',
            margin: '0px',
          },
          '.MuiInputBase-root': {},
          '.MuiOutlinedInput-root': {
            paddingRight: '35px !important',
            paddingLeft: '8.5px !important',
            paddingY: '8.5px !important',
          },
          '.MuiAutocomplete-inputRoot': {
            flexWrap: 'nowrap',
            height: '100%',
          },
          '.MuiAutocomplete-input:focus':
            toToken != null
              ? {
                  display: 'inline-block',
                  minWidth: '0px',
                  width: '0px',
                  color: 'transparent',
                  padding: '0px !important',
                }
              : {
                  display: 'inline-block',
                  minWidth: '0px',
                  width: '0px',
                  padding: '0px !important',
                },
          '.MuiAutocomplete-input:not(:focus)': {
            display: 'inline-block',
            minWidth: '0px',
            width: '0px',
            padding: '0px !important',
          },
          width: '100%',
          minWidth: '90px',
          minHeight: '1.4375em',
          height: '100%',
        }}
        disableClearable={false}
      />
    </Box>
  );
}

export default React.memo(TokenInput);

/**
 * Auth Verification
 *
 * Authenticating the user by verifying their signature before registering their payment keys.
 */

import { ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material';
import { Box, CircularProgress, Typography } from '@mui/material';
import {
  useFluidkeyClient,
  useIsAddressRegistered,
  useRegisterUser,
  useStoreUserSecret,
} from '@sefu/react-sdk';
import Plausible from 'plausible-tracker';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAccount, useWalletClient } from 'wagmi';
import { useAuth } from '../../../context/AuthContext';
import { useMain } from '../../../context/MainContext';

export default function Register(): JSX.Element {
  const { address } = useAccount();
  const { data: walletClient } = useWalletClient();
  const { registerUser, error, errorInfo } = useRegisterUser();
  const { refetch } = useIsAddressRegistered(address);
  const fluidkeyClient = useFluidkeyClient();
  const { accessCode, setError, stage, pin, backupPin } = useAuth();
  const { privyUsername } = useMain();
  const { storeSecret, error: storeError, errorInfo: storeErrorInfo } = useStoreUserSecret();
  const navigate = useNavigate();
  const { trackEvent } = Plausible({
    domain: 'app.fluidkey.com, rollup.fluidkey',
    apiHost: 'https://sync.fluidkey.com',
  });
  const [learnMore, setLearnMore] = useState(false);

  // Register address, back up PIN, and go to dashboard on success
  useEffect(() => {
    if (walletClient != null && stage === 'register') {
      // add a 1 sec timeout
      setTimeout(() => {
        void registerUser({
          walletClient,
          whitelistCode: accessCode.join(''),
        }).then(user => {
          if (user != null) {
            const authToken = fluidkeyClient?.getAuthToken();
            const authTokenJSON = JSON.stringify(authToken);
            if (backupPin) {
              void storeSecret(pin.join('')).then(() => {
                localStorage.setItem(`fluidkey.authToken.${address}`, authTokenJSON);
                void refetch();
                navigate('/');
              });
            } else {
              localStorage.setItem(`fluidkey.authToken.${address}`, authTokenJSON);
              void refetch();
              navigate('/');
            }
          }
        });
      }, 1000);
    }
  }, []);

  // Registration error handling
  useEffect(() => {
    if (error != null) {
      if (errorInfo?._code === 'GENERIC_ERROR') {
        setError('Sorry, an error occurred. Please try again or contact us if the issue persists.');
      } else {
        setError(error);
      }
    }
  }, [error]);

  // PIN back up error handling
  useEffect(() => {
    if (storeError != null) {
      if (storeErrorInfo?._code === 'GENERIC_ERROR') {
        setError('Sorry, an error occurred. Please try again or contact us if the issue persists.');
      } else {
        setError(storeError);
      }
    }
  }, [storeError]);

  // Track auth stage
  useEffect(() => {
    trackEvent('Register');
  }, []);

  return (
    <>
      <Box mt={2} mb={1} width="70vw" maxWidth="sm" display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
      {privyUsername != null ? (
        <Typography variant="body2" color="text.secondary" mb={1}>
          Getting your account ready...
        </Typography>
      ) : (
        <>
          <Typography variant="body2" color="text.secondary" mb={1}>
            Signature 2/2
          </Typography>
          <Typography variant="body1" color="text.primary" mb={2} textAlign="center">
            Authenticate your wallet by signing the connection message.
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            color="text.secondary"
            onClick={() => {
              setLearnMore(!learnMore);
            }}
            mb={2}
            sx={{ cursor: 'pointer' }}
          >
            <Typography variant="body2" color="text.secondary">
              Learn more
            </Typography>
            {learnMore ? (
              <ExpandLessRounded fontSize="inherit" color="inherit" />
            ) : (
              <ExpandMoreRounded fontSize="inherit" color="inherit" />
            )}
          </Box>
          {learnMore ? (
            <Typography variant="body2" color="text.secondary" width="100%" textAlign="center">
              To register, we ask you to sign a second message ensuring nobody is trying to
              impersonate you.
            </Typography>
          ) : null}
        </>
      )}
    </>
  );
}

import { Box, ButtonBase, Typography } from '@mui/material';
import { moreColors } from '../../../../theme';
import { useMain } from '../../../../../context/MainContext';
import { chains } from '../../../../../utils/chains';
import Chain from '../../../../atoms/Chains/Chain';
import { type GetSmartAccountUsdBalanceCrossChainResponse } from '@sefu/react-sdk/lib/core/graphql/codegen/generatedTS/graphql';
import { formatAmount } from '../../../../../utils/formatAmount';

export default function ChainSelector({
  usdBalance,
}: {
  usdBalance: GetSmartAccountUsdBalanceCrossChainResponse | undefined;
}): JSX.Element {
  const { setChainId, chainId, chainIds } = useMain();

  // Calculate filtered elements
  const filteredElements = Object.keys(chains)
    .map(key => {
      const filteredChainId = parseInt(key);
      const chain = usdBalance?.chains?.find(chain => chain.chainId === filteredChainId);
      return {
        key,
        totalUsd: chain?.totalUsd ?? 0,
      };
    })
    .filter(({ key, totalUsd }) => {
      const filteredChainId = parseInt(key);
      return (
        totalUsd > 0 &&
        chains[filteredChainId].type === 'MAINNET' &&
        chainIds?.includes(filteredChainId)
      );
    })
    .sort((a, b) => b.totalUsd - a.totalUsd);

  // Conditionally render the Box or return null if no elements
  if (filteredElements.length === 0) {
    return <></>;
  }

  return (
    <Box display="flex" px="2vh" gap="4px" flexWrap="wrap" mb={1}>
      {filteredElements.map(({ key }) => (
        <Typography
          variant="body2"
          component="div"
          color="text.secondary"
          sx={{ whiteSpace: 'nowrap' }}
          display="flex"
          alignItems="center"
          key={key}
        >
          <ButtonBase
            onClick={() => {
              setChainId(parseInt(key));
            }}
            sx={{
              backgroundColor:
                key === chainId.toString()
                  ? moreColors.light2PaperBackground
                  : moreColors.lightPaperBackground,
              borderRadius: '5px',
              marginRight: '4px',
              marginBottom: '4px',
              border: '1px solid',
              borderColor: key === chainId.toString() ? 'text.secondary' : '#5D6374',
            }}
          >
            <Typography
              variant="caption"
              fontWeight={key === chainId.toString() ? 500 : 400}
              display="flex"
              alignItems="center"
              mx={0.5}
              py={0.25}
            >
              <Chain chainId={parseInt(key)} iconOnly={window.innerWidth < 600} />
              <Typography variant="inherit" component="span" ml={0.5}>
                $
                {formatAmount({
                  amountTotalFormatted:
                    usdBalance?.chains
                      ?.find(chain => chain.chainId === parseInt(key))
                      ?.totalUsd?.toFixed(2)
                      .toString() ?? '',
                  decimals: 2,
                  shortVersion: true,
                })}
              </Typography>
            </Typography>
          </ButtonBase>
        </Typography>
      ))}
    </Box>
  );
}

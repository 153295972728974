/**
 * Transfer Context
 *
 * This context handles the outgoing transfer flow.
 */

import {
  type Token,
  type GenerateBridgeQuoteResponse,
  type GenerateSwapQuoteResponse,
  type GenerateWithdrawalQuoteResponse,
  type Label,
  type Contact,
} from '@sefu/react-sdk';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useMain } from './MainContext';

// Transfer stages
type stageType = 'pin' | 'generateKeys' | 'confirm';

interface TransferContextProps {
  stage: stageType;
  setStage: React.Dispatch<React.SetStateAction<stageType>>;
  pin: string[];
  setPin: React.Dispatch<React.SetStateAction<string[]>>;
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  error: string | undefined;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
  quote: GenerateWithdrawalQuoteResponse | undefined;
  setQuote: React.Dispatch<React.SetStateAction<GenerateWithdrawalQuoteResponse | undefined>>;
  swapQuote: GenerateSwapQuoteResponse | undefined;
  setSwapQuote: React.Dispatch<React.SetStateAction<GenerateSwapQuoteResponse | undefined>>;
  bridgeQuote: GenerateBridgeQuoteResponse | undefined;
  setBridgeQuote: React.Dispatch<React.SetStateAction<GenerateBridgeQuoteResponse | undefined>>;
  sendAmount: bigint;
  setSendAmount: React.Dispatch<React.SetStateAction<bigint>>;
  formattedSendAmount: string;
  setFormattedSendAmount: React.Dispatch<React.SetStateAction<string>>;
  sendTo: string;
  setSendTo: React.Dispatch<React.SetStateAction<string>>;
  sendToInput: string;
  setSendToInput: React.Dispatch<React.SetStateAction<string>>;
  token: Token | undefined;
  setToken: React.Dispatch<React.SetStateAction<Token | undefined>>;
  toToken: Token | undefined;
  setToToken: React.Dispatch<React.SetStateAction<Token | undefined>>;
  toChain: number;
  setToChain: React.Dispatch<React.SetStateAction<number>>;
  idToken: string;
  setIdToken: React.Dispatch<React.SetStateAction<string>>;
  decimals: number;
  setDecimals: React.Dispatch<React.SetStateAction<number>>;
  balance: bigint;
  setBalance: React.Dispatch<React.SetStateAction<bigint>>;
  balanceOnHold: bigint;
  setBalanceOnHold: React.Dispatch<React.SetStateAction<bigint>>;
  ensIsLoading: boolean;
  setEnsIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  selectedLabels: Label[];
  setSelectedLabels: React.Dispatch<React.SetStateAction<Label[]>>;
  openSwapBridge: boolean;
  setOpenSwapBridge: React.Dispatch<React.SetStateAction<boolean>>;
  showPinIssue: boolean;
  setShowPinIssue: React.Dispatch<React.SetStateAction<boolean>>;
  contact: Contact | undefined;
  setContact: React.Dispatch<React.SetStateAction<Contact | undefined>>;
}

const TransferContext = createContext<TransferContextProps | undefined>(undefined);

export const useTransfer = (): TransferContextProps => {
  const context = useContext(TransferContext);
  if (context == null) {
    throw new Error('useTransfer must be used within an TransferProvider');
  }
  return context;
};

interface TransferProviderProps {
  children?: React.ReactNode;
}

export const TransferProvider: React.FC<TransferProviderProps> = ({ children }) => {
  const { chainId } = useMain();

  // Transfer context states
  const [stage, setStage] = useState<stageType>('pin');
  const [pin, setPin] = useState<string[]>(['', '', '', '']);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [quote, setQuote] = useState<GenerateWithdrawalQuoteResponse | undefined>(undefined);
  const [swapQuote, setSwapQuote] = useState<GenerateSwapQuoteResponse | undefined>(undefined);
  const [bridgeQuote, setBridgeQuote] = useState<GenerateBridgeQuoteResponse | undefined>(
    undefined
  );
  const [sendAmount, setSendAmount] = useState<bigint>(0n);
  const [formattedSendAmount, setFormattedSendAmount] = useState<string>('0');
  const [sendTo, setSendTo] = useState<string>('');
  const [sendToInput, setSendToInput] = useState<string>('');
  const [token, setToken] = useState<Token | undefined>();
  const [toToken, setToToken] = useState<Token | undefined>();
  const [toChain, setToChain] = useState<number>(chainId);
  const [idToken, setIdToken] = useState<string>('');
  const [decimals, setDecimals] = useState<number>(0);
  const [balance, setBalance] = useState<bigint>(0n);
  const [balanceOnHold, setBalanceOnHold] = useState<bigint>(0n);
  const [ensIsLoading, setEnsIsLoading] = useState<boolean>(false);
  const [selectedLabels, setSelectedLabels] = useState<Label[]>([]);
  const [openSwapBridge, setOpenSwapBridge] = useState<boolean>(false);
  const [showPinIssue, setShowPinIssue] = useState<boolean>(false);
  const [contact, setContact] = useState<Contact | undefined>();

  // If the modal closes, reset all states. If the user has generated keys, go straight to confirm.
  useEffect(() => {
    if (!modalOpen) {
      setStage('pin');
      setPin(['', '', '', '']);
      setQuote(undefined);
      setSwapQuote(undefined);
      setBridgeQuote(undefined);
      setSendAmount(0n);
      setFormattedSendAmount('0');
      setSendTo('');
      setSendToInput('');
      setSelectedLabels([]);
      setEnsIsLoading(false);
      setError(undefined);
      setToToken(undefined);
      setOpenSwapBridge(false);
      setShowPinIssue(false);
    }
  }, [token]);

  // If the chain changes, remove the token from the context
  useEffect(() => {
    setToken(undefined);
  }, [chainId]);

  // If the toChain changes, reset the toToken
  useEffect(() => {
    setToToken(undefined);
  }, [toChain]);

  // If there is an error, close the modal
  useEffect(() => {
    if (error != null) {
      setModalOpen(false);
      setPin(['', '', '', '']);
      setStage('pin');
    }
  }, [error]);

  return (
    <TransferContext.Provider
      value={{
        stage,
        setStage,
        pin,
        setPin,
        modalOpen,
        setModalOpen,
        error,
        setError,
        quote,
        setQuote,
        swapQuote,
        setSwapQuote,
        bridgeQuote,
        setBridgeQuote,
        sendAmount,
        setSendAmount,
        formattedSendAmount,
        setFormattedSendAmount,
        sendTo,
        setSendTo,
        sendToInput,
        setSendToInput,
        token,
        setToken,
        toToken,
        setToToken,
        toChain,
        setToChain,
        idToken,
        setIdToken,
        decimals,
        setDecimals,
        balance,
        setBalance,
        balanceOnHold,
        setBalanceOnHold,
        ensIsLoading,
        setEnsIsLoading,
        selectedLabels,
        setSelectedLabels,
        openSwapBridge,
        setOpenSwapBridge,
        showPinIssue,
        setShowPinIssue,
        contact,
        setContact,
      }}
    >
      {children}
    </TransferContext.Provider>
  );
};

/**
 * Auth Key Generation
 *
 * Generating the user's private payment keys by signing a message with their wallet.
 */

import { ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material';
import { Box, CircularProgress, Typography } from '@mui/material';
import {
  useAuthenticate,
  useFluidkeyClient,
  useGenerateKeys,
  useIsAddressRegistered,
} from '@sefu/react-sdk';
import Plausible from 'plausible-tracker';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAccount, useWalletClient } from 'wagmi';
import { useAuth } from '../../../context/AuthContext';
import { useMain } from '../../../context/MainContext';

export default function GenerateKeys(): JSX.Element {
  const { address } = useAccount();
  const { data: walletClient } = useWalletClient();
  const { isAddressRegistered } = useIsAddressRegistered(address);
  const { generateKeys, error, keys, errorInfo } = useGenerateKeys();
  const {
    authenticate,
    error: authError,
    isAuthenticated,
    errorInfo: authErrorInfo,
  } = useAuthenticate();
  const { privyUsername } = useMain();
  const fluidkeyClient = useFluidkeyClient();
  const { pin, setPin, setError, setStage, setShowPinIssue } = useAuth();
  const [newKeys, setNewKeys] = useState(false);
  const [learnMore, setLearnMore] = useState(false);
  const navigate = useNavigate();
  const { trackEvent } = Plausible({
    domain: 'app.fluidkey.com, rollup.fluidkey',
    apiHost: 'https://sync.fluidkey.com',
  });

  // Generate keys
  useEffect(() => {
    if (walletClient != null) {
      const pinString = pin.join('');
      void generateKeys(walletClient, pinString);
      setNewKeys(true);
    }
  }, [walletClient]);

  // Move to registration stage or authenticate
  useEffect(() => {
    if (keys != null && !isAddressRegistered && newKeys) {
      setStage('register');
    } else if (keys != null && newKeys) {
      void authenticate();
    }
  }, [keys]);

  // Navigate to dashboard on authentication
  useEffect(() => {
    if (isAuthenticated) {
      const authToken = fluidkeyClient?.getAuthToken();
      const authTokenJSON = JSON.stringify(authToken);
      localStorage.setItem(`fluidkey.authToken.${address}`, authTokenJSON);
      navigate('/');
    }
  }, [isAuthenticated]);

  // Key generation error handling
  useEffect(() => {
    if (error != null) {
      if (errorInfo?._code === 'GENERIC_ERROR') {
        setError('Sorry, an error occurred. Please try again or contact us if the issue persists.');
      } else {
        setError(error);
      }
    }
  }, [error]);

  // Authentication error handling
  useEffect(() => {
    if (authError != null) {
      if (authErrorInfo?._code === 'ERR_006') {
        setShowPinIssue(true);
        setPin(['', '', '', '']);
        setStage('pin');
      } else if (authErrorInfo?._code === 'GENERIC_ERROR') {
        setError('Sorry, an error occurred. Please try again or contact us if the issue persists.');
      } else if (authError === 'Signer public key not recognized') {
        setError('Your PIN is incorrect.');
      } else {
        setError(authError);
      }
    }
  }, [authError]);

  // Track auth stage
  useEffect(() => {
    trackEvent('Key Generation');
  }, []);

  return (
    <>
      <Box mt={2} mb={1} width="70vw" maxWidth="sm" display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
      {privyUsername != null ? (
        <Typography variant="body2" color="text.secondary" mb={1}>
          Generating your keys...
        </Typography>
      ) : (
        <>
          {isAddressRegistered ? null : (
            <Typography variant="body2" color="text.secondary" mb={2}>
              Signature 1/2
            </Typography>
          )}
          <Typography variant="body1" color="text.primary" mb={2} textAlign="center">
            Sign the private payment key generation message with your wallet.
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            color="text.secondary"
            onClick={() => {
              setLearnMore(!learnMore);
            }}
            mb={2}
            sx={{ cursor: 'pointer' }}
          >
            <Typography variant="body2" color="text.secondary">
              Learn more
            </Typography>
            {learnMore ? (
              <ExpandLessRounded fontSize="inherit" color="inherit" />
            ) : (
              <ExpandMoreRounded fontSize="inherit" color="inherit" />
            )}
          </Box>
          {learnMore ? (
            <>
              <Typography variant="body2" color="text.secondary" mb={1} width="100%">
                This signature generates the keys controlling your Fluidkey funds. These keys never
                leave your device and are fully self-custodial.
              </Typography>
              <Typography variant="body2" color="text.secondary" mb={1} width="100%">
                Only sign this message on app.fluidkey.com.{' '}
              </Typography>
              <Typography variant="body2" color="text.secondary" width="100%">
                Make sure to use a secure set up. Losing access to the connected wallet means losing
                access to your Fluidkey account.
              </Typography>
            </>
          ) : null}{' '}
        </>
      )}
    </>
  );
}

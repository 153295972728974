import { ArrowDownwardRounded } from '@mui/icons-material';
import { Alert, Box, CircularProgress, Container } from '@mui/material';
import { useAuthenticate, useGetUserSmartAccounts } from '@sefu/react-sdk';
import Plausible from 'plausible-tracker';
import { useEffect } from 'react';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { useAccount } from 'wagmi';
import { useMain } from '../../../context/MainContext';
import Activity from '../../organisms/Dashboard/Activity';
import Funds from '../../organisms/Dashboard/Funds';
import Receive from '../../organisms/Dashboard/Receive';
import Welcome from '../../organisms/Dashboard/Welcome';
import Header from '../../organisms/Header/Header';

export default function DashboardParent(): JSX.Element {
  const { isAuthenticated } = useAuthenticate();
  const { address } = useAccount();
  const { trackEvent } = Plausible({
    domain: 'app.fluidkey.com, rollup.fluidkey',
    apiHost: 'https://sync.fluidkey.com',
  });
  const { setRefreshBalance, refreshBalance, chainId } = useMain();
  const { smartAccountList = [] } = useGetUserSmartAccounts();

  // Track auth stage
  useEffect(() => {
    if (isAuthenticated) {
      trackEvent('Authenticated');
    }
  }, [isAuthenticated]);

  return (
    <>
      {isAuthenticated ? (
        <>
          <Header address={address} />
          <Container
            disableGutters
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '80vh',
              alignItems: 'center',
              marginTop: '90px',
              maxWidth: '90vw',
            }}
          >
            <PullToRefresh
              isPullable={window.innerWidth < 600}
              onRefresh={async (): Promise<any> => {
                setRefreshBalance(!refreshBalance);
              }}
              refreshingContent={<ArrowDownwardRounded color="primary" />}
              pullingContent={
                <Box display="flex" justifyContent="center">
                  <ArrowDownwardRounded color="primary" />
                </Box>
              }
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  minHeight: '80vh',
                  alignItems: 'center',
                }}
              >
                <Box width="100%" maxWidth="sm" mb={chainId === 1 ? 4 : 0}>
                  {chainId === 1 ? (
                    <Alert severity="warning">
                      Ethereum mainnet fees are high on Fluidkey. We recommend using other chains
                      for payments under $1,000.{' '}
                    </Alert>
                  ) : null}
                </Box>
                <Welcome />
                <Receive />
                <Box
                  className="receive"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: 'sm',
                    width: '100%',
                  }}
                  mb={2}
                >
                  <Funds key={`${chainId}-${address}`} />
                  {smartAccountList.length > 0 ? <Activity /> : null}
                </Box>
              </Box>
            </PullToRefresh>
          </Container>
        </>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="90vh"
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}

import { EastRounded, EditRounded, WestRounded } from '@mui/icons-material';
import { Box, Button, FormHelperText, TextField, Typography } from '@mui/material';
import {
  useGetSmartAccount,
  useGetUserSmartAccounts,
  useIsUsernameAvailable,
  useSetUsername,
} from '@sefu/react-sdk';
import Plausible from 'plausible-tracker';
import React, { useEffect, useState } from 'react';
import { useMain } from '../../../../context/MainContext';
import { gradient, moreColors } from '../../../theme';

export default function ChangeUsername(): JSX.Element {
  const { smartAccountList = [] } = useGetUserSmartAccounts();
  const { smartAccount } = useGetSmartAccount({
    idSmartAccount: smartAccountList[0]?.idSmartAccount,
  });
  const { setUsername } = useSetUsername();
  const [newUsername, setNewUsername] = useState('');
  const { isAvailable } = useIsUsernameAvailable({
    username: newUsername,
  });
  const { trackEvent } = Plausible({
    domain: 'app.fluidkey.com, rollup.fluidkey',
    apiHost: 'https://sync.fluidkey.com',
  });
  const { isEditingUsername, setIsEditingUsername, openDrawer, setOpenDrawer } = useMain();
  const [error, setError] = useState('');

  useEffect(() => {
    if (
      smartAccount?.username !== null &&
      smartAccount?.username !== undefined &&
      !isEditingUsername
    ) {
      setNewUsername(smartAccount?.username);
    }
  }, [smartAccount, openDrawer]);

  useEffect(() => {
    if (!(isAvailable ?? true) && newUsername !== smartAccount?.username) {
      setError('The ENS is not available.');
    } else {
      setError('');
    }
  }, [isAvailable]);

  function handleSubmit(e: React.FormEvent): void {
    e.preventDefault();
    const regex = /^[a-z0-9-]+$/;
    if (!regex.test(newUsername)) {
      setError('The ENS can only contain lowercase letters, numbers, and hyphens.');
      return;
    }
    if (!(isAvailable ?? true) || smartAccount?._importStatus !== 'SUCCESS') {
      return;
    }
    void setUsername(smartAccount?.idSmartAccount, newUsername);
    setIsEditingUsername(false);
    setOpenDrawer(false);
  }

  function handleCancel(): void {
    if (smartAccount?.username !== null && smartAccount?.username !== undefined) {
      setNewUsername(smartAccount?.username);
    }
    setError('');
    setIsEditingUsername(false);
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box maxWidth="sm" display="flex" flexDirection="column" alignItems="center" my={1}>
        <Box p={2} width="100%">
          <Typography
            variant="body2"
            color="text.secondary"
            mb={0.5}
            textAlign="left"
            maxWidth="sm"
            px="2vh"
            width="100%"
          >
            Username
          </Typography>
          <Box mt={1} display="flex" alignItems="center" justifyContent="space-between" px="2vh">
            <Typography
              variant="body2"
              component="div"
              color="text.primary"
              display="flex"
              alignItems="baseline"
              mr={2}
            >
              {isEditingUsername ? (
                <>
                  <TextField
                    value={newUsername}
                    onChange={e => {
                      setNewUsername(e.target.value.toLowerCase());
                    }}
                    autoFocus
                    fullWidth
                    type="text"
                    variant="standard"
                    sx={{ fontSize: '1rem' }}
                    inputProps={{ style: { textAlign: 'center' }, autoCapitalize: 'none' }}
                    autoComplete="off"
                    autoCorrect="off"
                    error={!(error.length === 0)}
                  />
                </>
              ) : (
                smartAccount?.username
              )}
            </Typography>
            <Typography variant="body2" component="div" color="text.secondary" textAlign="right">
              {isEditingUsername ? (
                <Box display="flex">
                  <Button
                    variant="contained"
                    size="small"
                    color="inherit"
                    sx={{
                      textTransform: 'none',
                      minWidth: 0,
                      marginRight: 0.5,
                      '& .MuiButton-startIcon': { marginLeft: 0, marginRight: 0 },
                      backgroundColor: moreColors.light2PaperBackground,
                      color: 'text.primary',
                      backgroundImage: 'none !important',
                    }}
                    onClick={handleCancel}
                    startIcon={<WestRounded />}
                  />
                  <Button
                    variant="contained"
                    size="small"
                    color="success"
                    sx={{
                      textTransform: 'none',
                      backgroundImage: gradient,
                    }}
                    onClick={handleSubmit}
                    endIcon={<EastRounded />}
                  >
                    Confirm
                  </Button>
                </Box>
              ) : (
                <Button
                  variant="text"
                  size="small"
                  sx={{ color: 'inherit', textTransform: 'none' }}
                  onClick={() => {
                    setIsEditingUsername(true);
                    trackEvent('Edit ENS');
                  }}
                >
                  <EditRounded sx={{ width: '12px', height: '12px', marginRight: 0.5 }} />
                  Edit
                </Button>
              )}
            </Typography>
          </Box>
          <FormHelperText sx={{ paddingX: '2vh' }} error>
            {error}
          </FormHelperText>
        </Box>
      </Box>
    </form>
  );
}

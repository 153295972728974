interface IFormatAmountParams {
  amountTotalFormatted: string;
  roundUp?: boolean;
  decimals?: number;
  shortVersion?: boolean;
}

/**
 * Formats the given amount in a human-readable way.
 *
 * @param params - The parameters for formatting the amount.
 * @param params.amountTotalFormatted - The amount to format, as a string.
 * @param params.roundUp - Optional. Whether to round up the amount. Defaults to false.
 * @param params.decimals - Optional. The number of decimal places to use. Defaults to 2.
 * @returns The formatted amount, as a string.
 */

export function formatAmount({
  amountTotalFormatted,
  roundUp = false,
  decimals = 2,
  shortVersion = false,
}: IFormatAmountParams): string {
  let amountFormattedRounded;
  if (shortVersion) {
    const amount = roundUp
      ? Math.ceil(Number(amountTotalFormatted) * 100) / 100
      : Math.floor(Number(amountTotalFormatted) * 100) / 100;
    const formatter = new Intl.NumberFormat('en-US', {
      notation: 'compact',
      compactDisplay: 'short',
      minimumFractionDigits: 0,
      maximumFractionDigits: decimals,
    });
    amountFormattedRounded = formatter.format(amount);
    return amountFormattedRounded;
  }
  if (parseFloat(amountTotalFormatted) < 1 && parseFloat(amountTotalFormatted) > 0) {
    const decimalPart = amountTotalFormatted.split('.')[1];
    const firstNonZero = decimalPart.search(/[1-9]/);
    const nextDigit = decimalPart[firstNonZero + decimals - 1];
    const decimalScale = nextDigit === '0' ? firstNonZero + decimals - 1 : firstNonZero + decimals;
    const amount = roundUp
      ? Math.ceil(Number(amountTotalFormatted) * Math.pow(10, decimalScale)) /
        Math.pow(10, decimalScale)
      : Math.floor(Number(amountTotalFormatted) * Math.pow(10, decimalScale)) /
        Math.pow(10, decimalScale);

    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: decimals === 0 ? 0 : firstNonZero + 1,
      maximumFractionDigits: decimals === 0 ? 0 : firstNonZero + decimals,
    });
    amountFormattedRounded = formatter.format(amount);
  } else {
    const amount = roundUp
      ? Math.ceil(Number(amountTotalFormatted) * 100) / 100
      : Math.floor(Number(amountTotalFormatted) * 100) / 100;
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: decimals,
    });
    amountFormattedRounded = formatter.format(amount);
  }

  return amountFormattedRounded;
}

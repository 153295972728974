import { ScienceRounded } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { chains } from '../../../utils/chains';

export interface ChainProps {
  chainId: number;
  iconOnly?: boolean;
}

export default function Chain({ chainId, iconOnly = false }: ChainProps): JSX.Element {
  return (
    <Box display="flex" alignItems="center">
      {chains[chainId].icon ? (
        <img
          src={`/chains/${chains[chainId].shortname}.svg`}
          alt={chains[chainId].name}
          style={{ width: '16px', height: '16px' }}
        />
      ) : (
        <Box
          sx={{
            backgroundColor: chains[chainId].color,
            borderRadius: '50%',
            width: '16px',
            height: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ScienceRounded sx={{ color: 'text.primary', fontSize: '0.65rem' }} />
        </Box>
      )}
      {iconOnly ? null : (
        <Typography variant="inherit" sx={{ marginLeft: 0.6 }}>
          {chains[chainId].name}
        </Typography>
      )}
    </Box>
  );
}

/**
 * Chain data
 */

interface Chain {
  name: string;
  chainId: string;
  explorer: string;
  explorerName: string;
  shortname: string;
  type: 'MAINNET' | 'TESTNET';
  icon: boolean;
  color?: string;
}

type Chains = Record<number, Chain>;

export const chains: Chains = {
  11155111: {
    name: 'Sepolia',
    chainId: '11155111',
    explorer: 'https://sepolia.etherscan.io/',
    explorerName: 'Etherscan',
    shortname: 'sep',
    type: 'TESTNET',
    icon: false,
    color: '#414552',
  },
  8453: {
    name: 'Base',
    chainId: '8453',
    explorer: 'https://basescan.org/',
    explorerName: 'BaseScan',
    shortname: 'base',
    type: 'MAINNET',
    icon: true,
    color: '#0052FF',
  },
  10: {
    name: 'Optimism',
    chainId: '10',
    explorer: 'https://optimistic.etherscan.io/',
    explorerName: 'Etherscan',
    shortname: 'op',
    type: 'MAINNET',
    icon: true,
    color: '#FF0420',
  },
  42161: {
    name: 'Arbitrum',
    chainId: '42161',
    explorer: 'https://arbiscan.io/',
    explorerName: 'Arbiscan',
    shortname: 'arb',
    type: 'MAINNET',
    icon: true,
    color: '#12AAFF',
  },
  137: {
    name: 'Polygon',
    chainId: '137',
    explorer: 'https://polygonscan.com/',
    explorerName: 'Polygonscan',
    shortname: 'pol',
    type: 'MAINNET',
    icon: true,
    color: '#7b3fe4',
  },
  100: {
    name: 'Gnosis',
    chainId: '100',
    explorer: 'https://gnosisscan.io/',
    explorerName: 'Gnosisscan',
    shortname: 'gno',
    type: 'MAINNET',
    icon: true,
    color: '#04795B',
  },
  1: {
    name: 'Ethereum',
    chainId: '1',
    explorer: 'https://etherscan.io/',
    explorerName: 'Etherscan',
    shortname: 'eth',
    type: 'MAINNET',
    icon: true,
    color: '#b6b9c6',
  },
};

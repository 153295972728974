import { Box, Button, FormControlLabel, Switch, Typography } from '@mui/material';
import Plausible from 'plausible-tracker';
import { useState } from 'react';
import { useMain } from '../../../../context/MainContext';
import { chains } from '../../../../utils/chains';
import Chain from '../../../atoms/Chains/Chain';
import { gradient, moreColors } from '../../../theme';

export default function SelectChain(): JSX.Element {
  const { chainId, setChainId, chainIds, setOpenDrawer } = useMain();
  const { trackEvent } = Plausible({
    domain: 'app.fluidkey.com, rollup.fluidkey',
    apiHost: 'https://sync.fluidkey.com',
  });
  const [testnets, setTestnets] = useState(chains[chainId].type === 'TESTNET' || chainId === 1);

  return (
    <Box maxWidth="sm" display="flex" flexDirection="column" alignItems="center" width="100%">
      <Box p={2} width="100%">
        <Typography
          variant="body2"
          color="text.secondary"
          mb={1.5}
          textAlign="left"
          maxWidth="sm"
          px="2vh"
          width="100%"
        >
          Chain
        </Typography>
        {Object.keys(chains)
          .filter(key => {
            const filteredChainId = parseInt(key);
            return (
              (testnets ||
                (chains[filteredChainId].type === 'MAINNET' && filteredChainId !== 1) ||
                filteredChainId === chainId) &&
              chainIds?.includes(filteredChainId)
            );
          })
          .sort((a, b) => (chains[parseInt(a)].type === 'MAINNET' ? -1 : 1))
          .map(key => {
            const chain = chains[parseInt(key)];
            return (
              <Button
                key={chain.chainId}
                variant="contained"
                sx={{
                  mb: 1,
                  textTransform: 'none',
                  fontSize: 'body2.fontSize',
                  backgroundColor: moreColors.lightPaperBackground,
                  justifyContent: 'space-between',
                  color: 'text.primary',
                  '&:hover': {
                    color: 'background.paper',
                    backgroundImage: gradient,
                    backgroundColor: moreColors.lightPaperBackground,
                  },
                  '&:disabled': {
                    color: 'text.primary',
                  },
                }}
                fullWidth={true}
                onClick={() => {
                  setChainId(parseInt(key));
                  trackEvent('Switch Chain');
                  setOpenDrawer(false);
                }}
                disabled={chainId === parseInt(key)}
              >
                <Box display="flex" alignItems="left">
                  <Typography component="div" variant="body2" color="inherit" fontWeight={600}>
                    <Box display="flex">
                      <Chain chainId={parseInt(key)} />
                      <Typography ml={0.75} variant="body2" fontWeight={400}>
                        {chains[parseInt(key)].type === 'TESTNET' ? '· Testnet' : ''}
                        {key === '1' ? '· High fees' : ''}
                      </Typography>
                    </Box>
                  </Typography>
                </Box>
                <Typography variant="body2" color="inherit" fontWeight={600}>
                  {chainId === parseInt(key) ? 'Active' : 'Select'}
                </Typography>
              </Button>
            );
          })}
        <Box display="flex" width="100%" justifyContent="left" mt={1}>
          <FormControlLabel
            control={
              <Switch
                size="small"
                onChange={() => {
                  setTestnets(!testnets);
                }}
                checked={testnets}
                sx={{
                  marginLeft: '2.5vh',
                }}
              />
            }
            label={
              <Typography variant="body2" color="text.secondary" fontSize="0.8125rem">
                Advanced
              </Typography>
            }
            sx={{
              marginLeft: '-8px',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

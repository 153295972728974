/**
 * Amount input field for sending funds
 */

import { TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useDebounce } from 'usehooks-ts';
import { parseUnits } from 'viem';
import { useTransfer } from '../../../../../../context/TransferContext';
import { useMain } from '../../../../../../context/MainContext';
import { useGetSupportedTokens } from '@sefu/react-sdk';
import { formatAmount } from '../../../../../../utils/formatAmount';

export default function AmountInput(): JSX.Element {
  const { setSendAmount, sendAmount, setFormattedSendAmount, decimals, token, setError } =
    useTransfer();
  const { chainId } = useMain();
  const { data: tokenData } = useGetSupportedTokens({ chainId });

  const [sendAmountInput, setSendAmountInput] = useState<string>();
  const [usdValue, setUsdValue] = useState<string>('');
  const debouncedAmountInput = useDebounce(sendAmountInput, 500);

  useEffect(() => {
    const parsedAmount = parseUnits(debouncedAmountInput ?? '0', decimals);
    setSendAmount(parsedAmount);
  }, [debouncedAmountInput]);

  useEffect(() => {
    if (sendAmountInput === undefined || sendAmountInput === '') {
      setUsdValue('');
    } else {
      const fullToken = tokenData?.whitelistTokens.find(t => t.address === token?.address);
      const value =
        fullToken?.usdPrice != null
          ? '$' +
            formatAmount({
              amountTotalFormatted: (Number(debouncedAmountInput) * fullToken?.usdPrice).toString(),
            })
          : '';
      setUsdValue(value);
    }
  }, [sendAmount]);

  return (
    <NumericFormat
      customInput={TextField}
      thousandSeparator={true}
      label="amount"
      placeholder={token?.aliasSymbol ?? token?.symbol}
      variant="outlined"
      type="text"
      autoFocus={true}
      margin="dense"
      size="small"
      value={sendAmountInput}
      color="primary"
      autoComplete="off"
      autoCorrect="off"
      allowedDecimalSeparators={['.', ',']}
      onChange={e => {
        setFormattedSendAmount(e.target.value);
        setSendAmountInput(e.target.value.replace(/,/g, ''));
        setError(undefined);
      }}
      inputProps={{
        inputMode: 'decimal',
      }}
      sx={{
        marginTop: 0,
        marginBottom: 0,
        width: '100%',
      }}
      InputProps={{
        endAdornment: (
          <Typography variant="body2" color="text.secondary">
            {usdValue}
          </Typography>
        ),
      }}
    />
  );
}

import { ButtonBase, Typography } from '@mui/material';
import { useTour } from '@reactour/tour';
import { useEffect } from 'react';
import { moreColors } from '../../theme';

export default function ReceiveTooltip({
  displayType,
  setDisplayType,
}: {
  displayType: 'link' | 'ens' | 'hex';
  setDisplayType: (displayType: 'link' | 'ens' | 'hex') => void;
}): JSX.Element {
  const { setCurrentStep, currentStep, isOpen } = useTour();

  useEffect(() => {
    if (isOpen === true && currentStep === 1) {
      setDisplayType('link');
    }
    if (isOpen === true && currentStep === 2) {
      setDisplayType('ens');
    }
    if (isOpen === true && currentStep === 3) {
      setDisplayType('hex');
    }
  }, [currentStep]);

  return (
    <Typography
      variant="body2"
      component="div"
      color="text.secondary"
      sx={{ whiteSpace: 'nowrap' }}
      display="flex"
      alignItems="center"
      mb={1.25}
    >
      <ButtonBase
        onClick={() => {
          setDisplayType('link');
          setCurrentStep(1);
        }}
        sx={{
          backgroundColor:
            displayType === 'link'
              ? moreColors.light2PaperBackground
              : moreColors.lightPaperBackground,
          borderRadius: '5px',
          marginRight: '4px',
          border: '1px solid',
          borderColor: displayType === 'link' ? 'text.secondary' : '#5D6374',
        }}
      >
        <Typography
          variant="body2"
          color={displayType === 'link' ? 'text.secondary' : '#5D6374'}
          fontWeight={displayType === 'link' ? 500 : 400}
          display="flex"
          alignItems="center"
          mx={0.5}
        >
          Link
        </Typography>
      </ButtonBase>
      <ButtonBase
        onClick={() => {
          setDisplayType('ens');
          setCurrentStep(2);
        }}
        sx={{
          backgroundColor:
            displayType === 'ens'
              ? moreColors.light2PaperBackground
              : moreColors.lightPaperBackground,
          borderRadius: '5px',
          marginX: '4px',
          border: '1px solid',
          borderColor: displayType === 'ens' ? 'text.secondary' : '#5D6374',
        }}
      >
        <Typography
          variant="body2"
          color={displayType === 'ens' ? 'text.secondary' : '#5D6374'}
          fontWeight={displayType === 'ens' ? 500 : 400}
          display="flex"
          alignItems="center"
          mx={0.5}
        >
          ENS
        </Typography>
      </ButtonBase>
      <ButtonBase
        onClick={() => {
          setDisplayType('hex');
          setCurrentStep(3);
        }}
        sx={{
          backgroundColor:
            displayType === 'hex'
              ? moreColors.light2PaperBackground
              : moreColors.lightPaperBackground,
          borderRadius: '5px',
          marginX: '4px',
          border: '1px solid',
          borderColor: displayType === 'hex' ? 'text.secondary' : '#5D6374',
        }}
      >
        <Typography
          variant="body2"
          color={displayType === 'hex' ? 'text.secondary' : '#5D6374'}
          fontWeight={displayType === 'hex' ? 500 : 400}
          display="flex"
          alignItems="center"
          mx={0.5}
        >
          Address
        </Typography>
      </ButtonBase>
    </Typography>
  );
}

import { CancelRounded, FileDownloadRounded } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { usePrivy } from '@privy-io/react-auth';
import Plausible from 'plausible-tracker';
import { useNavigate } from 'react-router-dom';
import { useAccount, useDisconnect } from 'wagmi';
import { useMain } from '../../../../context/MainContext';
import Address from '../../../atoms/Address/Address';
import { useEffect, useState } from 'react';

export default function LogoutButton(): JSX.Element {
  const { disconnect } = useDisconnect();
  const { address } = useAccount();
  const { trackEvent } = Plausible({
    domain: 'app.fluidkey.com, rollup.fluidkey',
    apiHost: 'https://sync.fluidkey.com',
  });
  const { setOpenDrawer, privyUsername } = useMain();
  const navigate = useNavigate();
  const { logout, exportWallet, user } = usePrivy();
  const [isEmbeddedWallet, setIsEmbeddedWallet] = useState(false);

  useEffect(() => {
    const embeddedWallet = user?.linkedAccounts.find(
      account =>
        account.type === 'wallet' &&
        account.walletClientType === 'privy' &&
        account.address === address
    );
    if (embeddedWallet != null) {
      setIsEmbeddedWallet(true);
    }
  });

  return address !== undefined ? (
    <>
      <Box maxWidth="sm" display="flex" flexDirection="column" alignItems="center" my={1}>
        <Box p={2} width="100%">
          <Typography
            variant="body2"
            color="text.secondary"
            mb={0.5}
            textAlign="left"
            maxWidth="sm"
            px="2vh"
            width="100%"
          >
            Account
          </Typography>
          <Box mt={1} display="flex" alignItems="center" justifyContent="space-between" px="2vh">
            <Typography
              variant="body2"
              component="div"
              color="text.primary"
              display="flex"
              alignItems="baseline"
              mr={2}
            >
              <Address
                address={privyUsername ?? address}
                sx={{ fontSize: 'body2.fontSize' }}
                characters={8}
              />
            </Typography>
            <Typography variant="body2" component="div" color="text.secondary" textAlign="right">
              <Button
                variant="text"
                size="small"
                sx={{ color: 'inherit', textTransform: 'none' }}
                onClick={() => {
                  disconnect();
                  localStorage.removeItem(`fluidkey.authToken.${address}`);
                  void logout();
                  trackEvent('Disconnect Account');
                  setOpenDrawer(false);
                  navigate('/auth');
                }}
              >
                <CancelRounded sx={{ width: '12px', height: '12px', marginRight: 0.5 }} />
                Disconnect
              </Button>
            </Typography>
          </Box>
          {isEmbeddedWallet ? (
            <Typography
              variant="body2"
              component="div"
              color="text.secondary"
              textAlign="left"
              px="2vh"
              ml={-0.5}
            >
              <Button
                variant="text"
                size="small"
                sx={{ color: 'inherit', textTransform: 'none' }}
                onClick={() => {
                  void exportWallet();
                }}
              >
                <FileDownloadRounded sx={{ width: '12px', height: '12px', marginRight: 0.5 }} />
                Export Privy keys
              </Button>
            </Typography>
          ) : null}
        </Box>
      </Box>
    </>
  ) : (
    <></>
  );
}

import { Box } from '@mui/material';
import { type Token as SdkTokenType } from '@sefu/react-sdk';
import { useState } from 'react';

export default function Token({ token }: { token: SdkTokenType }): JSX.Element {
  const [imgVisible, setImgVisible] = useState(true);
  return (
    <Box display="flex" alignItems="center" key={token.iconUrl}>
      {token.iconUrl != null ? (
        <img
          src={token.iconUrl}
          alt={token.aliasSymbol ?? token.symbol}
          style={{
            width: '0.7em',
            height: '0.7em',
            marginRight: '4px',
            display: imgVisible ? 'block' : 'none',
          }}
          onError={() => {
            setImgVisible(false);
          }}
        />
      ) : null}
      {token.aliasSymbol ?? token.symbol}
    </Box>
  );
}

import React from 'react';
import { Box, Typography } from '@mui/material';
import Logo from '../../atoms/Logo/Logo';

export default function SendFooter(): JSX.Element {
  return (
    <Box
      mb={5}
      sx={{
        position: 'absolute',
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="body2" color="text.secondary">
        Protect your privacy with
      </Typography>
      <Box mt={1}>
        <Logo size="80px" />
      </Box>
    </Box>
  );
}

import { CloseRounded, MoreVertRounded } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  Typography,
  useMediaQuery,
} from '@mui/material';
import MuiLink from '@mui/material/Link';
import { useGetUserInviteCodes } from '@sefu/react-sdk';
import { useMain } from '../../../context/MainContext';
import Address from '../../atoms/Address/Address';
import Chain from '../../atoms/Chains/Chain';
import Logo from '../../atoms/Logo/Logo';
import Account from '../../molecules/Settings/Account/Account';
import SelectChain from '../../molecules/Settings/Chain/SelectChain';
import Install from '../../molecules/Settings/Install/Install';
import Invites from '../../molecules/Settings/Invites/Invites';
import Support from '../../molecules/Settings/Support/Support';
import ChangeUsername from '../../molecules/Settings/Username/ChangeUsername';
import theme, { moreColors } from '../../theme';

interface HeaderProps {
  address?: `0x${string}` | undefined;
}

export default function Header({ address }: HeaderProps): JSX.Element {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(380));
  const { chainId, openDrawer, setOpenDrawer, privyUsername } = useMain();
  const { inviteCodeList } = useGetUserInviteCodes();

  const toggleDrawer = (): void => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        backdropFilter: 'blur(5px)',
        backgroundColor: 'transparent !important',
        backgroundImage: 'none !important',
        boxShadow: 'none',
        zIndex: theme.zIndex.drawer + 1,
        [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
          paddingLeft: '8px',
          paddingRight: '8px',
        },
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Box display="flex" justifyContent="center" maxWidth="90vw" width="100%" margin="auto" mb={0}>
        <Container
          maxWidth="sm"
          disableGutters
          sx={{
            zIndex: theme.zIndex.drawer + 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '7vh',
            maxHeight: '50px',
            minHeight: '40px',
            marginTop: '1.5vh',
            paddingY: '1.5vh',
            paddingLeft: '16px',
            paddingRight: '10px',
            borderRadius: '10px',
            backgroundColor: 'background.paper',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
          }}
          key="header"
        >
          <Box display="flex" alignItems="center">
            <Logo size="80px" />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            className="settings"
            onClick={toggleDrawer}
            px="6px"
            sx={{
              cursor: 'pointer',
              WebkitTapHighlightColor: 'transparent',
              '&:hover': {
                backgroundColor: moreColors.lightPaperBackground,
              },
              borderRadius: '5px',
            }}
            gap={1.5}
          >
            {address != null && !isSmallScreen ? (
              <Box mr={0.5}>
                <Address
                  address={privyUsername ?? address}
                  sx={{ fontSize: 'body2.fontSize' }}
                  characters={4}
                />
              </Box>
            ) : null}
            <Typography component="div" variant="body2">
              <Chain chainId={chainId} />
            </Typography>
            <Button
              size="small"
              variant="text"
              sx={{
                '&:hover': {
                  backgroundColor: moreColors.lightPaperBackground,
                },
                textTransform: 'none',
                color: 'text.primary',
                minWidth: '10px',
                width: '10px',
                height: '25px',
              }}
              onClick={toggleDrawer}
            >
              {openDrawer ? (
                <CloseRounded fontSize="small" />
              ) : (
                <MoreVertRounded fontSize="small" />
              )}
            </Button>
          </Box>
        </Container>
        <Drawer
          anchor="top"
          open={openDrawer}
          onClose={toggleDrawer}
          PaperProps={{
            sx: {
              position: 'absolute',
              top: '9vh',
              width: '90vw',
              maxWidth: 'sm',
              left: '50% !important',
              transform: 'translateX(-50%) !important',
              borderRadius: '10px',
              backgroundColor: 'background.paper !important',
              backgroundImage: 'none !important',
              padding: '12px',
              justifyContent: 'center',
              display: 'flex',
            },
          }}
        >
          <Box
            flexGrow={1}
            maxWidth="400px"
            minWidth="300px"
            width="calc(100vw - 20vw)"
            margin="auto"
            sx={{
              maxHeight: 'calc(100vh - 12vh)',
              overflow: 'auto',
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'divider',
                borderRadius: '8px',
                backgroundClip: 'padding-box',
                paddingY: '60px',
              },
              scrollbarColor: 'divider', // This is for Firefox
            }}
          >
            <SelectChain />
            <Divider variant="middle" />
            <ChangeUsername />
            <Divider variant="middle" />
            <Account />
            <Divider variant="middle" />
            <Install />
            {inviteCodeList.length > 0 ? (
              <>
                <Divider variant="middle" />
                <Invites inviteCodeList={inviteCodeList} />
              </>
            ) : null}
            <Divider variant="middle" />
            <Support />
            <Typography
              variant="body2"
              textAlign="center"
              color="text.secondary"
              maxWidth="90vw"
              mt={4}
              mb={2}
              sx={{ fontSize: '0.7rem !important' }}
            >
              <MuiLink
                underline="none"
                color="text.secondary"
                sx={{
                  margin: '0 10px',
                  cursor: 'pointer',
                  '&:hover': {
                    color: 'primary.main',
                  },
                  fontSize: '0.7rem',
                }}
                href="https://fluidkey.com/terms"
                target="_blank"
              >
                Terms
              </MuiLink>
              |
              <MuiLink
                underline="none"
                color="text.secondary"
                sx={{
                  margin: '0 10px',
                  cursor: 'pointer',
                  '&:hover': {
                    color: 'primary.main',
                  },
                  fontSize: '0.7rem',
                }}
                href="https://fluidkey.com/privacy"
                target="_blank"
              >
                Privacy
              </MuiLink>
            </Typography>
          </Box>
        </Drawer>
      </Box>
    </AppBar>
  );
}

import {
  ArrowDownwardRounded,
  ArrowRightAltRounded,
  ArrowUpwardRounded,
  ErrorOutlineRounded,
  ExpandMore,
  RedoRounded,
  RotateLeftRounded,
  SwapHorizRounded,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Chip,
  Link,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {
  type Token as TokenType,
  useAttachStealthSafeLabel,
  useDeleteStealthSafeLabel,
  useDetachStealthSafeLabel,
  useGetStealthSafeLabelList,
  useGetUserSmartAccounts,
} from '@sefu/react-sdk';
import Plausible from 'plausible-tracker';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { IMaskInput } from 'react-imask';
import { formatUnits } from 'viem';
import { useMain } from '../../../../context/MainContext';
import { chains } from '../../../../utils/chains';
import Address from '../../../atoms/Address/Address';
import Chain from '../../../atoms/Chains/Chain';
import { type Transfer } from '../../../organisms/Dashboard/Activity';
import { moreColors } from '../../../theme';
import Token from '../../../atoms/Token/Token';

function ActivityAccordion({ data }: { data: Transfer }): JSX.Element {
  const { chainId } = useMain();
  const { smartAccountList } = useGetUserSmartAccounts();
  const { data: labels } = useGetStealthSafeLabelList({
    idSmartAccount: smartAccountList?.[0]?.idSmartAccount as string,
  });
  const { attach } = useAttachStealthSafeLabel({
    idSmartAccount: smartAccountList?.[0]?.idSmartAccount as string,
    chainId,
  });
  const { detach } = useDetachStealthSafeLabel({
    idSmartAccount: smartAccountList?.[0]?.idSmartAccount as string,
    chainId,
  });
  const { deleteLabel } = useDeleteStealthSafeLabel({
    idSmartAccount: smartAccountList?.[0]?.idSmartAccount as string,
  });

  const { trackEvent } = Plausible({
    domain: 'app.fluidkey.com, rollup.fluidkey',
    apiHost: 'https://sync.fluidkey.com',
  });
  const { smartAccountTransfer, additionalInfo } = data;
  const {
    selfSend,
    outAmount,
    outDecimals,
    outToken,
    fees,
    serviceTime,
    outLabels,
    outFeeToken,
    outFeeTokenDecimals,
  } = additionalInfo;
  const { stealthSafeTransfer, createdAt, status, txHash, direction, procedure, procedureType } =
    smartAccountTransfer;
  const [labelFocused, setLabelFocused] = useState(false);
  const [inputValue, setInputValue] = useState<any>('');
  const [inputColor, setInputColor] = useState(moreColors.light2PaperBackground);

  const amountFormatted = formatUnits(
    BigInt(
      procedure?.bridge?.amount ??
        procedure?.swap?.amount ??
        procedure?.withdrawal?.amount ??
        stealthSafeTransfer?.amount ??
        '0'
    ),
    procedure?.bridge?.tokenFrom?.decimals ??
      procedure?.swap?.tokenFrom?.decimals ??
      procedure?.withdrawal?.token.decimals ??
      (stealthSafeTransfer?.token?.decimals as number)
  );
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 8,
  });
  const amountFormattedRounded = formatter.format(Number(amountFormatted));

  const outAmountFormatted =
    outAmount != null && outDecimals != null ? formatUnits(BigInt(outAmount), outDecimals) : null;
  const outAmountFormattedRounded =
    outAmountFormatted != null ? formatter.format(Number(outAmountFormatted)) : null;

  const bridgeAmountFormatted =
    (procedure?.bridge?.minAmountReceived ?? (procedure?.swap?.minAmountReceived as string)) != null
      ? formatUnits(
          BigInt(
            procedure?.bridge?.minAmountReceived ?? (procedure?.swap?.minAmountReceived as string)
          ),
          procedure?.bridge?.tokenTo?.decimals ?? (procedure?.swap?.tokenTo?.decimals as number)
        )
      : '';
  const bridgeAmountFormattedRounded =
    procedureType === 'BRIDGE' || (procedureType === 'SWAP' && !selfSend)
      ? formatter.format(Number(bridgeAmountFormatted))
      : '';

  const date = new Date(createdAt * 1000);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };
  const formattedDate = date.toLocaleString('en-UK', options);

  const timeRemaining =
    procedureType === 'BRIDGE' ? (createdAt + (serviceTime as number)) * 1000 - Date.now() : 0;
  const timeRemainingInMinutes = Math.floor(timeRemaining / 60000);

  const iconWrapper = (color: string, icon: JSX.Element): JSX.Element => (
    <Typography color={color} display="flex" alignItems="center">
      {icon}
    </Typography>
  );

  const rowWrapper = (title: string, content: JSX.Element | string): JSX.Element => (
    <TableRow>
      <TableCell>{title}</TableCell>
      <TableCell>{content}</TableCell>
    </TableRow>
  );

  const amountWrapper = (
    amount: string,
    token: string | React.ReactNode,
    outAmount?: string | null,
    outToken?: string | React.ReactNode
  ): JSX.Element => {
    if (outAmount != null && procedureType !== 'WITHDRAWAL') {
      return (
        <Box component="span" display="flex" alignItems="center" flexWrap="wrap">
          <Box component="span" display="flex" alignItems="center">
            {outAmount} {outToken}{' '}
          </Box>
          <Box mx={0.75} component="span" display="flex" alignItems="center">
            <ArrowRightAltRounded />
          </Box>{' '}
          <Box component="span" display="flex" alignItems="center">
            {amount} {token}{' '}
          </Box>
        </Box>
      );
    } else {
      return (
        <Box component="span" display="flex" alignItems="center" sx={{ whiteSpace: 'nowrap' }}>
          {amount} {token}
        </Box>
      );
    }
  };

  const addressWrapper = (address: string, contact?: string): JSX.Element => (
    <Box component="span" display="flex" alignItems="center">
      <Address address={contact ?? address} tooltipContent={address} characters={20} />
    </Box>
  );

  const blockExplorerLink = (
    txHash: string | undefined,
    chainId: number
  ): JSX.Element | undefined => {
    const url =
      procedureType === 'BRIDGE' ? 'https://www.socketscan.io/' : chains[chainId].explorer;
    const name = procedureType === 'BRIDGE' ? 'Socket Explorer' : chains[chainId].explorerName;
    if (txHash != null) {
      return (
        <Box mt={3} mb={1.5} textAlign="center">
          <Link
            variant="body2"
            color="primary.main"
            href={`${url}tx/${txHash}`}
            underline="hover"
            target="_blank"
            onClick={() => {
              trackEvent('Open Activity Block Explorer');
            }}
          >
            View on {name}
          </Link>
        </Box>
      );
    }
  };

  const overview =
    procedureType === 'BRIDGE' || (procedureType === 'SWAP' && !selfSend)
      ? amountWrapper(
          bridgeAmountFormattedRounded,
          <Box ml={0.75}>
            <Token token={procedure?.bridge?.tokenTo ?? (procedure?.swap?.tokenTo as TokenType)} />
          </Box>,
          amountFormattedRounded,
          <Box ml={0.75}>
            <Token
              token={procedure?.bridge?.tokenFrom ?? (procedure?.swap?.tokenFrom as TokenType)}
            />
          </Box>
        )
      : amountWrapper(
          amountFormattedRounded,
          <Box ml={0.75}>
            <Token
              token={procedure?.withdrawal?.token ?? (stealthSafeTransfer?.token as TokenType)}
            />
          </Box>,
          outAmountFormattedRounded,
          outToken != null ? (
            <Box ml={0.75}>
              <Token token={outToken} />
            </Box>
          ) : undefined
        );

  const inputRef = useRef<HTMLInputElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const popperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: Event): void {
      if (
        ref.current != null &&
        !ref.current.contains(event.target as Node) &&
        popperRef.current?.contains(event.target as Node) == null
      ) {
        setInputValue('');
        setInputColor(moreColors.light2PaperBackground);
        setLabelFocused(false);
      }
    }

    // Bind the event listener
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [inputValue, inputColor]);

  const colors = [
    '#AF3029',
    '#BC5215',
    '#AD8301',
    '#66800B',
    '#24837B',
    '#205EA6',
    '#5E409D',
    '#A02F6F',
  ];

  // memoize the label input
  const labelsMemo = useMemo(() => {
    const option = labels != null ? [...labels] : [];
    // Filter through options and remove all that are in smartAccountTransfer.labels
    option.forEach((label, index) => {
      if (smartAccountTransfer.labels.some(l => l.idLabel === label.idLabel)) {
        option.splice(index, 1);
      }
    });
    option.sort((a, b) => {
      // Primary sorting criterion: objCount
      if (b.objCount !== a.objCount) {
        return b.objCount - a.objCount;
      }
      // Secondary sorting criterion: whether the color is in the colors array
      const aColorIndex = colors.indexOf(a.extraInfo.color);
      const bColorIndex = colors.indexOf(b.extraInfo.color);
      if (aColorIndex === -1 && bColorIndex !== -1) {
        return 1;
      }
      if (aColorIndex !== -1 && bColorIndex === -1) {
        return -1;
      }
      return 0;
    });
    return option;
  }, [labels, smartAccountTransfer.labels]);

  interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
  }

  const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
    function TextMaskCustom(props, ref) {
      const { onChange, ...other } = props;
      return (
        <IMaskInput
          {...other}
          mask={/^[a-z0-9-]*$/i}
          maxLength={50}
          inputRef={ref}
          onAccept={(value: any) => {
            onChange({ target: { name: props.name, value } });
          }}
          overwrite
        />
      );
    }
  );

  const addLabel = (
    <Box display="flex" alignItems="center" width="200px">
      <Autocomplete
        openOnFocus
        options={labelsMemo}
        noOptionsText="Create a label"
        getOptionLabel={option => (typeof option === 'string' ? option : option?.name ?? '')}
        freeSolo
        disableClearable
        clearIcon={null}
        fullWidth
        value={inputValue}
        onChange={(event, newValue, reason) => {
          if (reason === 'selectOption') {
            void attach({
              idLabel: newValue?.idLabel,
              transfer: smartAccountTransfer,
            });
            setInputValue('');
            setInputColor(moreColors.light2PaperBackground);
            setLabelFocused(false);
          } else if (reason === 'createOption') {
            const lowerCaseValue = newValue.toLowerCase();
            void attach({
              newLabel: { color: inputColor, name: lowerCaseValue },
              transfer: smartAccountTransfer,
            });
            setInputValue('');
            setInputColor(moreColors.light2PaperBackground);
            setLabelFocused(false);
          }
        }}
        forcePopupIcon={false}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              size="small"
              label={option?.name}
              key={option?.idLabel}
              sx={{
                backgroundColor: option?.extraInfo?.color,
                borderRadius: '6px',
                '&:focus': {
                  backgroundColor: option?.extraInfo?.color,
                  fontWeight: 'bold',
                },
              }}
            />
          ))
        }
        renderOption={(props, option, { selected }) => (
          <li {...props} style={{ paddingLeft: '8px', paddingRight: '8px', minHeight: '0px' }}>
            <Chip
              size="small"
              label={option?.name}
              style={{
                backgroundColor: option?.extraInfo?.color,
                borderRadius: '6px',
              }}
            />
          </li>
        )}
        renderInput={params => (
          <TextField
            {...params}
            variant="standard"
            type="text"
            margin="dense"
            size="small"
            color="primary"
            autoCorrect="off"
            autoCapitalize="none"
            autoFocus
            inputRef={inputRef}
            InputProps={{
              ...params.InputProps,
              inputComponent: TextMaskCustom as any,
            }}
            sx={{
              width: '100%',
              '& .MuiInput-underline:before': {
                borderBottom: 'none',
              },
              '& .MuiInput-underline:after': {
                borderBottom: 'none',
              },
              '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                borderBottom: 'none',
              },
              paddingTop: '4x',
            }}
          />
        )}
        PopperComponent={({ children, ...props }) => (
          <Popper
            {...props}
            style={{ width: '200px' }}
            placement="bottom-start"
            modifiers={[
              {
                name: 'offset',
                options: {
                  offset: [-9, 0],
                },
              },
              {
                name: 'flip',
                enabled: false,
              },
            ]}
            ref={popperRef}
          >
            {children}
          </Popper>
        )}
      />
    </Box>
  );

  const label = (
    <Box
      onClick={event => {
        event.stopPropagation();
      }}
    >
      {direction === 'INCOMING' ? (
        labelFocused ? (
          <Chip
            ref={ref}
            size="small"
            label={
              <Box display="flex" alignItems="center" width="100%">
                {addLabel}
                {colors.map(color => (
                  <Box
                    component="span"
                    borderRadius="50%"
                    height="12px"
                    width="12px"
                    key={color}
                    mx={0.25}
                    onClick={() => {
                      const value = inputRef.current?.value ?? '';
                      const lowerCaseValue = value.toLowerCase();
                      void attach({
                        newLabel: { color, name: lowerCaseValue },
                        transfer: smartAccountTransfer,
                      });
                      setInputValue('');
                      setInputColor(moreColors.light2PaperBackground);
                      setLabelFocused(false);
                    }}
                    sx={{
                      backgroundColor: color,
                      flexShrink: 0,
                    }}
                  />
                ))}
              </Box>
            }
            style={{
              borderRadius: '6px',
              width: '250px',
              marginRight: '8px',
              marginBottom: '3px',
              backgroundColor: inputColor,
            }}
          />
        ) : (
          <Chip
            size="small"
            label="+"
            style={{
              borderRadius: '6px',
              marginRight: '4px',
              marginBottom: '3px',
              backgroundColor: moreColors.light2PaperBackground,
            }}
            onClick={() => {
              setLabelFocused(true);
            }}
          />
        )
      ) : null}
    </Box>
  );

  const unsortedExistingLabels = [...smartAccountTransfer?.labels] ?? [];
  const existingLabels = unsortedExistingLabels
    ?.sort((a, b) => {
      if (b.objCount !== a.objCount) {
        return b.objCount - a.objCount;
      }
      // Secondary sorting criterion: whether the color is in the colors array
      const aColorIndex = colors.indexOf(a.extraInfo.color);
      const bColorIndex = colors.indexOf(b.extraInfo.color);
      if (aColorIndex === -1 && bColorIndex !== -1) {
        return 1;
      }
      if (aColorIndex !== -1 && bColorIndex === -1) {
        return -1;
      }
      return 0;
    })
    .map(label => (
      <Chip
        size="small"
        label={label.name}
        key={label.idLabel}
        style={{
          backgroundColor: label.extraInfo?.color,
          borderRadius: '6px',
          marginRight: '4px',
          marginBottom: '3px',
        }}
        onDelete={
          direction === 'INCOMING'
            ? () => {
                void detach({
                  idLabel: label.idLabel,
                  transfer: smartAccountTransfer,
                }).then(() => {
                  if (label.objCount === 1) {
                    void deleteLabel({ idLabel: label.idLabel });
                  }
                });
              }
            : undefined
        }
      />
    ));
  // check wether the outLabels are already attached to the transfer
  const outLabelsFormatted = outLabels?.map(label => (
    <Chip
      size="small"
      label={label.name}
      key={label.idLabel}
      style={{
        backgroundColor: label.extraInfo?.color,
        borderRadius: '6px',
        marginRight: '4px',
        marginBottom: '3px',
      }}
    />
  ));
  const blockExplorer = blockExplorerLink(txHash, chainId);

  const feesFormatted =
    fees != null
      ? formatter.format(
          Number(
            formatUnits(
              fees,
              outFeeTokenDecimals ??
                procedure?.bridge?.tokenForFee.decimals ??
                procedure?.swap?.tokenForFee.decimals ??
                procedure?.withdrawal?.tokenForFee.decimals ??
                (stealthSafeTransfer?.token.decimals as number)
            )
          )
        )
      : '';

  const feeSymbol =
    outFeeToken ??
    procedure?.bridge?.tokenForFee.symbol ??
    procedure?.swap?.tokenForFee.symbol ??
    procedure?.withdrawal?.tokenForFee.symbol ??
    (stealthSafeTransfer?.token.symbol as string);

  const accordionElements =
    direction === 'INCOMING' && !selfSend
      ? {
          // incoming transfer from third party
          icon: iconWrapper('success.main', <ArrowDownwardRounded fontSize="inherit" />),
          description: 'Incoming transfer',
          from: rowWrapper('From', addressWrapper(stealthSafeTransfer?.from as `0x${string}`)),
          date: rowWrapper('Date', formattedDate),
        }
      : status === 'ERROR'
      ? {
          // outgoing transfer failed
          icon: iconWrapper('error.main', <ErrorOutlineRounded fontSize="inherit" />),
          description: 'Failed transaction',
          date: rowWrapper('Date', formattedDate),
        }
      : procedureType === 'BRIDGE'
      ? timeRemaining < 0
        ? {
            // bridge completed
            icon: iconWrapper('#808dff', <RedoRounded fontSize="inherit" />),
            description: 'Bridge',
            to: !selfSend
              ? rowWrapper(
                  'To',
                  addressWrapper(
                    procedure?.bridge?.toAddress as `0x${string}`,
                    smartAccountTransfer?.contact?.fullName
                  )
                )
              : undefined,
            receivingChain: rowWrapper(
              'Receiving chain',
              <Chain chainId={procedure?.bridge?.chainIdTo as number} />
            ),
            date: rowWrapper('Date', formattedDate),
            fees: rowWrapper('Fees', `${feesFormatted} ${feeSymbol}`),
            usingLabels:
              existingLabels.length > 0
                ? rowWrapper('Using funds from', <>{existingLabels}</>)
                : undefined,
          }
        : {
            // bridge in progress
            icon: iconWrapper('text.secondary', <RedoRounded fontSize="inherit" />),
            description: 'Bridge in progress...',
            to:
              !selfSend && procedure?.bridge?.toAddress != null
                ? rowWrapper(
                    'To',
                    addressWrapper(
                      procedure?.bridge?.toAddress,
                      smartAccountTransfer?.contact?.fullName
                    )
                  )
                : undefined,
            receivingChain: rowWrapper(
              'Receiving chain',
              <Chain chainId={procedure?.bridge?.chainIdTo as number} />
            ),
            date: rowWrapper('Date', formattedDate),
            fees: rowWrapper('Fees', `${feesFormatted} ${feeSymbol}`),
            expectedCompletion: rowWrapper('Time remaining', `${timeRemainingInMinutes} minutes`),
            usingLabels:
              existingLabels.length > 0
                ? rowWrapper('Using funds from', <>{existingLabels}</>)
                : undefined,
          }
      : status === 'COMPLETED'
      ? procedureType === 'SWAP'
        ? {
            // swap completed
            icon: iconWrapper('#808dff', <SwapHorizRounded fontSize="inherit" />),
            description: 'Swap',
            to: !selfSend
              ? rowWrapper(
                  'To',
                  addressWrapper(
                    procedure?.swap?.toAddress as `0x${string}`,
                    smartAccountTransfer?.contact?.fullName
                  )
                )
              : undefined,
            date: rowWrapper('Date', formattedDate),
            fees: rowWrapper('Fees', `${feesFormatted} ${feeSymbol}`),
            usingLabels:
              !selfSend && existingLabels.length > 0
                ? rowWrapper('Using funds from', <>{existingLabels}</>)
                : outLabelsFormatted != null && outLabelsFormatted.length > 0
                ? rowWrapper('Using funds from', <>{outLabelsFormatted}</>)
                : undefined,
          }
        : selfSend
        ? {
            // self send completed
            icon: iconWrapper('#808dff', <RotateLeftRounded fontSize="inherit" />),
            description: 'Self send',
            date: rowWrapper('Date', formattedDate),
            fees: rowWrapper('Fees', `${feesFormatted} ${feeSymbol}`),
            usingLabels:
              outLabels != null && outLabels.length > 0 ? (
                rowWrapper('Using funds from', <>{outLabelsFormatted}</>)
              ) : (
                <></>
              ),
          }
        : {
            // outgoing transfer completed
            icon: iconWrapper('#808dff', <ArrowUpwardRounded fontSize="inherit" />),
            description: 'Outgoing transfer',
            to: rowWrapper(
              'To',
              addressWrapper(
                procedure.withdrawal?.toAddress as `0x${string}`,
                smartAccountTransfer?.contact?.fullName
              )
            ),
            date: rowWrapper('Date', formattedDate),
            fees: rowWrapper('Fees', `${feesFormatted} ${feeSymbol}`),
            usingLabels:
              existingLabels.length > 0
                ? rowWrapper('Using funds from', <>{existingLabels}</>)
                : undefined,
          }
      : procedureType === 'SWAP'
      ? {
          // swap in progress
          icon: iconWrapper('text.secondary', <SwapHorizRounded fontSize="inherit" />),
          description: 'Swap in progress...',
          to:
            !selfSend && procedure?.swap?.toAddress != null
              ? rowWrapper(
                  'To',
                  addressWrapper(
                    procedure?.swap?.toAddress,
                    smartAccountTransfer?.contact?.fullName
                  )
                )
              : undefined,
          date: rowWrapper('Date', formattedDate),
          fees: rowWrapper('Fees', `${feesFormatted} ${feeSymbol}`),
          usingLabels:
            !selfSend && existingLabels.length > 0
              ? rowWrapper('Using funds from', <>{existingLabels}</>)
              : outLabelsFormatted != null && outLabelsFormatted.length > 0
              ? rowWrapper('Using funds from', <>{outLabelsFormatted}</>)
              : undefined,
        }
      : selfSend
      ? {
          // self send in progress
          icon: iconWrapper('text.secondary', <RotateLeftRounded fontSize="inherit" />),
          description: 'Self send in progress...',
          date: rowWrapper('Date', formattedDate),
          fees: rowWrapper('Fees', `${feesFormatted} ${feeSymbol}`),
          usingLabels:
            outLabels != null && outLabels.length > 0
              ? rowWrapper('Using funds from', <>{outLabelsFormatted}</>)
              : undefined,
        }
      : {
          // outgoing transfer in progress
          icon: iconWrapper('text.secondary', <ArrowUpwardRounded fontSize="inherit" />),
          description: 'Outgoing transfer in progress...',
          to:
            procedure.withdrawal?.toAddress != null
              ? rowWrapper(
                  'To',
                  addressWrapper(
                    procedure.withdrawal?.toAddress,
                    smartAccountTransfer?.contact?.fullName
                  )
                )
              : undefined,
          date: rowWrapper('Date', formattedDate),
          fees: rowWrapper('Fees', `${feesFormatted} ${feeSymbol}`),
          usingLabels:
            existingLabels.length > 0
              ? rowWrapper('Using funds from', <>{existingLabels}</>)
              : undefined,
        };

  return (
    <Accordion
      sx={{
        '& ul': {
          listStyleType: 'disc',
          marginLeft: '0px',
          paddingLeft: '16px',
        },
        '&.MuiAccordion-root': {
          '&::before': {
            display: 'none',
          },
          marginY: '0',
        },
        '.MuiAccordionSummary-root.Mui-focusVisible': {
          backgroundColor: 'transparent',
        },
        backgroundImage: 'none !important',
        backgroundColor: 'background.paper',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        maxWidth: 'sm',
        paddingX: 0.1,
      }}
      onChange={(event: React.SyntheticEvent, expanded: boolean) => {
        if (expanded) {
          trackEvent('Open Activity Details');
        }
      }}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMore
            sx={{
              color: 'text.secondary',
            }}
          />
        }
      >
        <Box mb={0}>
          <Box display="inline-flex" alignItems="flex-start" gap={1} flexWrap="wrap">
            <Box display="flex" alignItems="center" gap={1}>
              {accordionElements?.icon}
              {overview}
            </Box>
            <Box display="flex" alignItems="center" flexWrap="wrap" rowGap="4px">
              {label}
              {direction === 'INCOMING' ? existingLabels : undefined}
            </Box>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body2" color="text.secondary" textAlign="center" mb={1}>
          {accordionElements?.description}
        </Typography>
        <TableContainer>
          <Table>
            <TableBody>
              {accordionElements?.expectedCompletion}
              {accordionElements?.from}
              {accordionElements?.to}
              {accordionElements?.receivingChain}
              {accordionElements?.date}
              {accordionElements?.fees}
              {accordionElements?.usingLabels}
            </TableBody>
          </Table>
        </TableContainer>
        {blockExplorer}
      </AccordionDetails>
    </Accordion>
  );
}

export default React.memo(ActivityAccordion);

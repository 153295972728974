import { type ReactElement } from 'react';

interface FarcasterIconProps {
  color: string;
}

const FarcasterIcon = ({ color }: FarcasterIconProps): ReactElement => (
  <svg
    viewBox="0 0 225 225"
    height="0.95em"
    width="0.95em"
    preserveAspectRatio="xMinYMin meet"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M112.5 225C174.632 225 225 174.632 225 112.5C225 50.368 174.632 0 112.5 0C50.368 0 0 50.368 0 112.5C0 174.632 50.368 225 112.5 225ZM68.1657 50H155.237V67.574H180L174.808 85.1479H170.414V156.243C172.62 156.243 174.408 158.031 174.408 160.237V165.03H175.207C177.413 165.03 179.201 166.818 179.201 169.024V173.817H155.237H142.456H134.467V169.024C134.467 166.818 136.256 165.03 138.462 165.03H139.26V160.237C139.26 158.304 140.633 156.693 142.456 156.322V117.101H142.33C140.918 101.426 127.744 89.142 111.701 89.142C95.6584 89.142 82.4847 101.426 81.0721 117.101H80.9467V156.243H81.7456C83.9514 156.243 85.7396 158.031 85.7396 160.237V165.03H86.5385C88.7443 165.03 90.5325 166.818 90.5325 169.024V173.817H80.9467H68.1657H45.7988V169.024C45.7988 166.818 47.587 165.03 49.7929 165.03H50.5917V160.237C50.5917 158.031 52.3799 156.243 54.5858 156.243V85.1479H50.1923L45 67.574H68.1657V50Z"
      fill="currentColor"
    />
  </svg>
);

export default FarcasterIcon;
